import { ThunkResult } from "../../types";
import { Dispatch } from "redux";
import { RootAction } from "../index";
import {cancelCampaign, gotCampaign, gotCampaigns, gotCampaignStats, gotGeneralStats} from "../campaigns";
import { GetParams, setAlert } from "@kopjra/uikit";
import {
    getCampaigns,
    transformToCampaignParams,
    getCampaign,
    deleteCampaigns,
    changeCampaignStatus, changeExpirationDate
} from "../../api/campaigns";
import { I18n } from "react-redux-i18n";
import {getCampaignStats, getStats} from "../../api/stats";


export const doGetCampaign = (campaignId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const campaign = await getCampaign(campaignId);
    if (campaign) {
        dispatch(gotCampaign(campaign));
    }
};

export const doGetGeneralStats = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const stats = await getStats();
    if (stats) {
        dispatch(gotGeneralStats(stats));
    }
};

export const doGetCampaignStats = (campaignId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const stats = await getCampaignStats(campaignId);
    if (stats) {
        dispatch(gotCampaignStats(stats));
    }
};

export const doGetCampaigns = (query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotCampaigns(await getCampaigns(transformToCampaignParams(query))));
};

export const doDeleteCampaigns = (campaignIds: string[], deleteAll: boolean, queryParams?: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const deleted = await deleteCampaigns(campaignIds, deleteAll, queryParams);
    if (deleted) {
        dispatch(setAlert(I18n.t("alerts.infos.deleteCampaigns"), "info"));
        for (const id of campaignIds) {
            dispatch(cancelCampaign(id));
        }
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.deleteCampaigns"), "error"));
    }
}

export const doPlayPauseCampaign = (campaignId: string, newStatus: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const changed = await changeCampaignStatus(campaignId, newStatus);
    if (changed) {
        const campaign = await getCampaign(campaignId);
        if (campaign) {
            dispatch(gotCampaign(campaign));
        }
    }
}

export const doUpdateExpirationDate = (campaignId: string, date: Date | undefined): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const changed = await changeExpirationDate(campaignId, date);
    if (changed) {
        dispatch(setAlert(I18n.t("alerts.infos.changeExpirationDate"), "info"));
        const campaign = await getCampaign(campaignId);
        if (campaign) {
            dispatch(gotCampaign(campaign));
        }
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.changeExpirationDate"), "error"));
    }
}
