import {ThunkResult, UserProfileData} from "../../types";
import {resendEmail, resetPassword, updateUserProfile} from "../../api/user";

export const doResendEmail = (): ThunkResult<Promise<void>> => async () => {
    await resendEmail();
}

export const doResetPassword = (): ThunkResult<Promise<void>> => async () => {
    await resetPassword();
}

export const doUpdateUserProfile = (up: UserProfileData): ThunkResult<Promise<void>> => async () => {
    await updateUserProfile(up);
}
