import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {
    alertBox,
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCard,
    KContainer,
    KFileUpload,
    KForm,
    KInput,
    KLabel,
    KSpace,
    KSpinner,
    KSpinnerSize
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {NewTemplate, Template} from "../types/templates";
import {useParams} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import {PageMatrix, TemplateField} from "../types/pdfdoped";
import PDFDopedViewer from "../containers/PDFDopedViewer";
import {scrollTop} from "../utils/commons";


export interface StateProps {
    template?: Template;
    templateFile?: File;
    fields?: TemplateField[];
    pageMatrices?: PageMatrix[];
    file?: File;
    isReset?: boolean;
}

export interface DispatchProps {
    onGetTemplateWithFile: (templateId: string) => Promise<void>;
    onSaveTemplate: (newTemplate: NewTemplate) => Promise<void>;
    onInitialize: (file: File, fields?: TemplateField[]) => void;
    onReset: () => void;
    onVerifyPDF: (file: File) => Promise<boolean>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const TemplateArea: React.FC<Props> = ({
                                                  onReset,
                                                  pageMatrices,
                                                  fields,
                                                  file,
                                                  onInitialize,
                                                  template,
                                                  templateFile,
                                                  onGetTemplateWithFile,
                                                  onSaveTemplate,
                                                  onVerifyPDF,
                                                  isReset,
                                              }) => {
    const {templateId} = useParams<{ templateId: string }>();
    const [verifyingPDF, setVerifyingPDF] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [showingProperties, setShowingProperties] = useState<boolean>(false);

    const handleShowProperties = useCallback((show: boolean) => {
        setShowingProperties(show);
    }, [setShowingProperties]);

    useEffect(() => {
        if (templateId) {
            onGetTemplateWithFile(templateId).catch((e: any) => console.warn("Get template error", e));
        }
    }, [templateId, onGetTemplateWithFile]);

    useEffect(() => {
        const unloadListener = (ev: BeforeUnloadEvent) => {
            ev.preventDefault();
            return ev.returnValue = "";
        };
        window.addEventListener("beforeunload", unloadListener);
        return () => {
            window.removeEventListener("beforeunload", unloadListener);
        }
    }, []);

    useEffect(() => {
        onReset();
        if (template && templateFile) {
            onInitialize(templateFile, template.fields);
        }
    }, [template, templateFile, onInitialize, onReset]);

    return (
        <KContainer screenMinWidth={900}>
            <KCard className={showingProperties ? "withProps" : ""}
                   header={templateId ? <Translate value={"templates.edit"}/> : <Translate value={"templates.new"}/>}>
                {!templateId || (template && templateFile) ? (
                    <KForm onSubmit={async values => {
                        if (!hasError) {
                            setSubmitting(true);
                            const newTemplate: NewTemplate = {
                                id: template?.id,
                                name: (values.templateName as string).trim(),
                                file: file as File,
                                filename: (template?.pdfFilename || file?.name) as string,
                                fields: (fields || []).map(f => ({
                                    ...f,
                                    description: f.description?.trim(),
                                    name: f.name?.trim()
                                })),
                                pageMatrices: (pageMatrices || template?.pageMatrices) as PageMatrix[],
                                isNew: !templateId
                            };
                            await onSaveTemplate(newTemplate);
                        } else {
                            setShowError(true);
                            scrollTop();
                        }
                    }}>
                        <Row><Col md={12}><KInput label={<Translate value={"templates.table.labels.name"}/>}
                                                  id={"templateName"} defaultValue={template?.name}
                                                  required/></Col></Row>
                        <KSpace spaces={2}/>
                        <Row>
                            <Col md={2}><KLabel text={<Translate value={"templates.choose"}/>}/></Col>
                            <Col className={"text-start"} md={10}>
                                {!verifyingPDF ? <KFileUpload showUploadedFilename={true} id={"document"}
                                             required={!templateFile}
                                             label={<Translate value="templates.upload"/>}
                                             maxSizeInMb={10}
                                             onChange={async f => {
                                                 f = f as File;
                                                 if (f.type === "application/pdf") {
                                                     setVerifyingPDF(true);
                                                     const verified: boolean = await onVerifyPDF(f);
                                                     onReset();
                                                     if (verified) {
                                                         onInitialize(f);
                                                     } else {
                                                         await alertBox({message: I18n.t("templates.pdfVerifyError")});
                                                     }
                                                     setVerifyingPDF(false);
                                                 }
                                             }}
                                             accept={"application/pdf"}
                                             valueFilename={file?.name || template?.pdfFilename}
                                             requiredErrorMessage={<Translate value={"templates.pdfErrorRequired"}/>}
                                             maxSizeErrorMessage={<Translate value={"templates.pdfErrorSize"}/>}
                                /> : <KSpinner size={KSpinnerSize.nm}/>}
                            </Col>
                        </Row>
                        <div hidden={!template && isReset}>
                            <PDFDopedViewer onShowProperties={handleShowProperties} ignoreSigners={true} onErrors={(error) => setHasError(error)} showValidation={showError}/>
                            <KSpace spaces={4}/>
                            <Row>
                                <Col xl={3} lg={4} md={5} className={"text-start"}><KButton variant={KBUTTON_VARIANT.secondary}
                                                                              fill={true} disabled={submitting}
                                                                              text={<><i
                                                                                  className="fal fa-times"/>&nbsp;
                                                                                  <Translate
                                                                                      value={`campaignCreation.close`}/></>}
                                                                              onClick={() => window.history.back()}/>
                                </Col>
                                <Col xl={6} lg={4} md={2}/>
                                <Col xl={3} lg={4} md={5} className={"text-end"}><KButton type={KBUTTON_TYPE.submit} fill={true}
                                                                            disabled={submitting}
                                                                            text={!submitting ? <><i
                                                                                    className="fal fa-save"/>&nbsp;
                                                                                    <Translate
                                                                                        value={`campaignCreation.save`}/></> :
                                                                                <KSpinner/>}/></Col>
                            </Row>
                        </div>
                    </KForm>
                ) : (
                    <KSpinner size={KSpinnerSize.xxl}/>
                )}
            </KCard>
        </KContainer>
    );
};
