import {ThunkResult} from "../../types";
import {Dispatch} from "redux";
import {RootAction} from "../index";
import {GetParams, setAlert} from "@kopjra/uikit";
import {cancelSigner, gotSigns} from "../signatures";
import {
    changeSignatureStatus,
    deleteSigners, exportCampaignSignaturePDFs,
    exportCampaignSignatures, exportCampaignSignatureXMLs,
    getSignPdf,
    getSigns,
    getSignXml,
    saveSigners,
    transformToSignParams
} from "../../api/signatures";
import {Signer} from "../../types/campaigns";
import {I18n} from "react-redux-i18n";
import {createLinkToDownload} from "../../utils/commons";
import {SignStatus} from "../../types/signatures";
import {getCampaignStats} from "../../api/stats";
import {gotCampaignStats} from "../campaigns";


export const doDeleteSigners = (campaignId: string, signerIds: string[], deleteAll: boolean, queryParams?: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const deleted = await deleteSigners(campaignId, signerIds, deleteAll, queryParams);
    if (deleted) {
        dispatch(setAlert(I18n.t("alerts.infos.deleteSigners"), "info"));
        for (const id of signerIds) {
            dispatch(cancelSigner(id));
        }
        const stats = await getCampaignStats(campaignId);
        if (stats) {
            dispatch(gotCampaignStats(stats));
        }
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.deleteSigners"), "error"));
    }
}

export const doGetSigns = (campaignId: string, query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotSigns(await getSigns(campaignId, transformToSignParams(query))));
};

export const doAddSigners = (campaignId: string, signers: Signer[], query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const chunks = chunkArray(signers, 100);
    for (const signerChunk of chunks) {
        await saveSigners(campaignId, signerChunk);
    }
    const stats = await getCampaignStats(campaignId);
    if (stats) {
        dispatch(gotCampaignStats(stats));
    }
    dispatch(gotSigns(await getSigns(campaignId, transformToSignParams(query))));
}

export const doDownloadPdf = (campaignId: string, signatureId: string): ThunkResult<Promise<void>> => async (dispatch) => {
    const url = await getSignPdf(campaignId, signatureId);
    if (url) {
        createLinkToDownload(`SignedDocument_${signatureId}.pdf`, url);
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.pdfDownload"), "error"));
    }
}

export const doDownloadXml = (campaignId: string, signatureId: string): ThunkResult<Promise<void>> => async (dispatch) => {
    const url = await getSignXml(campaignId, signatureId);
    if (url) {
        createLinkToDownload(`SignatureProof_${signatureId}.xml`, url);
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.xmlDownload"), "error"));
    }
}

export const doResend = (campaignId: string, signatureId: string): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
        await changeSignatureStatus(campaignId, signatureId, SignStatus.NEW);
        dispatch(setAlert(I18n.t("alerts.infos.emailReSent"), "info"));
    } catch {
        dispatch(setAlert(I18n.t("alerts.errors.emailReSend"), "error"));
    }
}

export function chunkArray<T>(arr: Array<T>, chunkSize: number): Array<Array<T>> {
    const chunks: Array<Array<T>> = [];
    while (arr.length) {
        chunks.push(arr.splice(0, chunkSize));
    }
    return chunks;
}

export const doExportSignatures = (campaignId: string, campaignName: string, signerIds: string[], all: boolean, queryParams?: GetParams): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
        await exportCampaignSignatures(campaignId, campaignName, signerIds, all, queryParams);
    } catch (err) {
        dispatch(setAlert(I18n.t("alerts.errors.genericError"), "error"));
        console.log(err);
    }
}

export const doExportSignaturePDFs = (campaignId: string, campaignName: string, signerIds: string[], all: boolean, queryParams?: GetParams): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
        await exportCampaignSignaturePDFs(campaignId, campaignName, signerIds, all, queryParams);
    } catch {
        dispatch(setAlert(I18n.t("alerts.errors.genericError"), "error"));
    }
}

export const doExportSignatureXMLs = (campaignId: string, campaignName: string, signerIds: string[], all: boolean, queryParams?: GetParams): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
        await exportCampaignSignatureXMLs(campaignId, campaignName, signerIds, all, queryParams);
    } catch {
        dispatch(setAlert(I18n.t("alerts.errors.genericError"), "error"));
    }
}
