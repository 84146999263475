import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {CampaignSign} from "../types/campaignSign";
import {createdSign, gotSign, gotPublicCampaign} from "../actions/campaignSign";
import {Sign} from "../types/signatures";


const initialState: CampaignSign = {
    campaign: null,
    sign: null
};

export const campaignSignReducer = createReducer(initialState)
    .handleAction(gotPublicCampaign, (state, action) => update(state, {
        campaign: {$set: action.payload},
    }))
    .handleAction(gotSign, (state, action) => update(state, {
        sign: {$set: action.payload},
    }))
    .handleAction(createdSign, (state, action) => {
        const signClone = {...state.sign};
        if (signClone) {
            signClone.fUrl = action.payload.fUrl
        }
        return update(state, {
            sign: {$set: signClone as Sign},
        })
    });
