import * as React from "react";
import {useEffect, useState} from "react";
import {
    ActionType,
    Column,
    DataType,
    GetParams,
    KButton,
    KBUTTON_SIZE,
    KBUTTON_VARIANT,
    KCard,
    KContainer,
    KInput,
    KInputHintVariant, KLabel,
    KSpace,
    Table
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {Col, Form, Row} from "react-bootstrap";
import {Invite} from "../types/collaborators";
import {tableGetParams} from "../utils/commons";
import {User} from "../types";
import {useAuth0} from "@auth0/auth0-react";

export interface StateProps {
    owner?: User;
    collaborators?: Invite[];
    totalCollaborators: number;
}

export interface DispatchProps {
    onGetCollaborators: (q: GetParams) => Promise<void>;
    onInviteCollaborators: (emails: string[]) => Promise<boolean>;
    onDeleteInvite: (id: string) => Promise<void>;
}

export interface InnerProps {

}

export type Props = StateProps & DispatchProps & InnerProps;

export const Collaborators: React.FC<Props> = ({
                                                   owner,
                                                   onDeleteInvite,
                                                   onInviteCollaborators,
                                                   onGetCollaborators,
                                                   collaborators,
                                                   totalCollaborators
                                               }) => {
    const tableName = "collaborators";

    useEffect(() => {
        onGetCollaborators({top: 5, skip: 0}).catch(console.log);
    }, [onGetCollaborators]);

    const [newEmails, setNewEmails] = useState<string | undefined>(undefined);
    const [submitting, setSubmitting] = useState(false);
    const [validated, setValidated] = useState(false);
    const {user} = useAuth0();

    const actions: ActionType<Invite>[] = [
        {
            name: <><i className="fal fa-trash"/> <Translate value={"collaborators.table.delete"}/></>,
            handler: async (datum) => {
                await onDeleteInvite(datum.id);
                onGetCollaborators(tableGetParams(tableName)).catch(console.log);
            },
            confirmation: true
        }
    ]

    return (
        <KContainer>
            <KCard header={<Translate value="collaborators.title"/>}>
                {!owner || owner.auth0Id === user?.sub ? (
                    <Row>
                        <Col md={10}>
                            <Form validated={validated}>
                                <KInput
                                    id={"newEmails"} hint={<Translate value={"collaborators.inviteTip"}/>}
                                    value={newEmails || ""}
                                    hintVariant={KInputHintVariant.DEFAULT}
                                    required={true}
                                    disabled={submitting}
                                    placeholder={I18n.t("collaborators.invitePlaceholder")}
                                    label={<Translate value={"collaborators.inviteLabel"}/>}
                                    errorMessage={new Map().set("required", <Translate
                                        value={"collaborators.requiredEmails"}/>)}
                                    onChange={event => setNewEmails(event.target.value)}
                                />
                            </Form>
                        </Col>
                        <Col md={2}>
                            <KButton text={<><i className={"fal fa-angle-double-right"}/> <Translate
                                value={"collaborators.inviteButton"}/></>}
                                     onClick={async () => {
                                         setSubmitting(true);
                                         setValidated(true);
                                         if (newEmails) {
                                             if (await onInviteCollaborators(newEmails.split(",").map(s => s.trim()))) {
                                                 setNewEmails(undefined);
                                                 setValidated(false);
                                             }
                                             onGetCollaborators(tableGetParams(tableName)).catch(console.log);
                                         }
                                         setSubmitting(false);
                                     }}
                                     disabled={submitting}
                                     size={KBUTTON_SIZE.nm}
                                     variant={KBUTTON_VARIANT.primary}
                                     style={{marginTop: 29, height: 40, paddingTop: 7, paddingBottom: 10}}
                            />
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
                <KSpace spaces={2}/>
                {totalCollaborators > 0 && collaborators && owner ? (
                    <>
                        <Row>
                            <Col md={2}><KLabel text={<Translate value="collaborators.owner" />} /></Col>
                            <Col md={10}><span style={{ fontSize: 12 }}>{owner.email}</span></Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Table id={tableName} checkboxes={false} hideColumnSelector={true}
                                       loaderFunc={(q) => onGetCollaborators(q)}
                                       loadInterval={10000}
                                       data={collaborators} total_count={totalCollaborators}>
                                    <Column colid={"email"} name={"collaborators.table.email"} type={DataType.GENERIC}
                                            sort={"email"} colspan={6}/>
                                    <Column colid={"status"} name={"collaborators.table.status"} type={DataType.GENERIC}
                                            sort={"status"} colspan={4}/>
                                    <Column colid={"actions"} type={DataType.ACTIONS} colspan={2} actions={actions}/>
                                </Table>
                            </Col>
                        </Row>
                    </>
                ) : null}
            </KCard>
        </KContainer>
    )
};
