import {CampaignStats, Stats} from "../types/stats";
import { apiCall } from "./index";

function transformToStats(obj: any): Stats {
    const stats: Stats = {
        activeCampaigns: obj.totalActiveCampaigns,
        available: obj.availableQty,
        extra: obj.consumedQtyExtra,
        totalSigned: obj.totalSigned,
        totalSigners: obj.totalSigners,
        subscribed: obj.subscribedQty,
        isAllowed: obj.isAllowed
    };
    return stats;
}

export async function getStats(): Promise<Stats> {
    const res = await apiCall({ urlComponent: `/stats` });
    const json = await res.json();
    return transformToStats(json);
}

export async function getCampaignStats(campaignId: string): Promise<CampaignStats> {
    const res = await apiCall({ urlComponent: `/campaigns/${campaignId}/stats` });
    const stats: CampaignStats = await res.json();
    return stats;
}
