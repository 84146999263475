import * as React from "react";
import {useState} from "react";
import {Col, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import {
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCard,
    KCheck,
    KContainer,
    KDate,
    KFileUpload,
    KForm,
    KInput,
    KLabel,
    KSpace,
    KStepProgress
} from "@kopjra/uikit";
import {CampaignBaseInfo, CampaignType, CustomLogo} from "../types/campaigns";
import {emailRegex} from "../utils/commons";


export type CampaignBaseInfoProps = {
    campaignInfo?: CampaignBaseInfo;
    onSubmit: (campaignInfo: CampaignBaseInfo) => void;
    onBack: () => void;
};

export const CampaignInfo: React.FC<CampaignBaseInfoProps> = ({campaignInfo, onSubmit, onBack}) => {
    const states = [
        <Translate value={"campaignCreation.campaignInfo"}/>,
        <Translate value={"campaignCreation.audience"}/>,
        <Translate value={"campaignCreation.document"}/>,
    ];

    const [curCustomLogo, setCurCustomLogo] = useState<CustomLogo | undefined>(campaignInfo?.customLogo);

    return (
        <>
            <Row><Col lg={10} md={12}><KStepProgress activeIndex={0} states={states}/></Col></Row>
            <KContainer>
                <Row><Col lg={7} md={12}>
                    <KCard header={<Translate value={"campaignCreation.campaignInfo"}/>}>
                        <KForm onSubmit={values => {
                            const campaignInfo: CampaignBaseInfo = {
                                name: values.name as string,
                                fromName: values.fromName as string,
                                fromEmail: values.fromEmail as string,
                                type: values.type as string,
                                expirationDate: values.expirationDate as string,
                                customLogo: curCustomLogo,
                                requireOTP: values.requireOTP ? true : false
                            };
                            onSubmit(campaignInfo);
                        }}>
                            <Row>
                                <Col><KInput label={<Translate value="campaignCreation.info.name"/>} required={true}
                                             id={"name"}
                                             errorMessage={<Translate value="campaignCreation.info.errors.name"/>}
                                             placeholder={I18n.t("campaignCreation.info.placeholders.name")}
                                             defaultValue={campaignInfo?.name}/></Col>
                            </Row>
                            <KSpace/>
                            <Row>
                                <Col><KInput label={<Translate value="campaignCreation.info.fromName"/>} required={true}
                                             id={"fromName"}
                                             errorMessage={<Translate value="campaignCreation.info.errors.fromName"/>}
                                             placeholder={I18n.t("campaignCreation.info.placeholders.fromName")}
                                             defaultValue={campaignInfo?.fromName}/></Col>
                            </Row>
                            <KSpace/>
                            <Row>
                                <Col><KInput label={<Translate value="campaignCreation.info.fromEmail"/>}
                                             required={true} id={"fromEmail"}
                                             errorMessage={<Translate value="campaignCreation.info.errors.fromEmail"/>}
                                             placeholder={I18n.t("campaignCreation.info.placeholders.fromEmail")}
                                             defaultValue={campaignInfo?.fromEmail} pattern={emailRegex}/></Col>
                            </Row>
                            <KSpace/>
                            <Row>
                                <Col className={"text-start"}>
                                    <KLabel text={<Translate value="campaignCreation.info.type"/>}/>
                                    <KSpace v={true} spaces={2}/>
                                    <KCheck id={CampaignType.OPEN} inline
                                            label={<Translate value="campaignCreation.info.open"/>} name="type" required
                                            type="radio"
                                            defaultChecked={campaignInfo ? campaignInfo.type === CampaignType.OPEN : true}/>
                                    <KCheck id={CampaignType.CLOSED} inline
                                            label={<Translate value="campaignCreation.info.closed"/>} name="type"
                                            required type="radio"
                                            defaultChecked={campaignInfo ? campaignInfo.type === CampaignType.CLOSED : false}/>
                                    <KCheck id={CampaignType.MULTI} inline
                                            label={<Translate value="campaignCreation.info.multi"/>} name="type"
                                            required type="radio"
                                            defaultChecked={campaignInfo ? campaignInfo.type === CampaignType.MULTI : false}/>
                                </Col>
                            </Row>
                            <KSpace/>
                            <Row>
                                <Col className={"text-start"}>
                                    <KCheck id="requireOTP"
                                            label={<Translate value="campaignCreation.info.requireOTP"/>}
                                            name="requireOTP" defaultChecked={campaignInfo?.requireOTP}/>
                                </Col>
                            </Row>
                            <KSpace/>
                            <Row className={"text-start"}>
                                <Col md={4}>
                                    <KLabel text={<Translate value="campaignCreation.info.expirationDate"/>}/>
                                </Col>
                                <Col>
                                    <KDate
                                        id="expirationDate"
                                        minDate={new Date()}
                                        isClearable
                                        date={campaignInfo && campaignInfo.expirationDate ? new Date(campaignInfo.expirationDate) : undefined}
                                        useTime={true}
                                    />
                                </Col>
                            </Row>
                            <KSpace/>
                            <Row>
                                <Col md={4}><KLabel
                                    text={<Translate value={"campaignCreation.info.customLogo"}/>}/></Col>
                                <Col className={"text-start"}>
                                    <KFileUpload showUploadedFilename={true} id={"customLogo"}
                                                 label={<Translate value="campaignCreation.info.customLogoUpload"/>}
                                                 maxSizeInMb={1}
                                                 defaultPreloadedImage={curCustomLogo ? {
                                                     mime: curCustomLogo?.mime,
                                                     content: curCustomLogo?.base64
                                                 } : undefined}
                                                 accept={["image/png", "image/jpg"]}
                                                 preloadImage
                                                 onChange={(f, mime) => setCurCustomLogo({
                                                     base64: f as string,
                                                     mime: mime as string
                                                 })}
                                                 hint={<Translate value="campaignCreation.info.customLogoHint"/>}
                                                 maxSizeErrorMessage={<Translate
                                                     value={"campaignCreation.info.errors.customLogo"}/>}
                                    />
                                </Col>
                            </Row>
                            <KSpace/>
                            <Row>
                                <Col lg={4} md={5} className={"text-start"}><KButton variant={KBUTTON_VARIANT.secondary}
                                                                                     fill={true}
                                                                                     text={<><i
                                                                                         className="fal fa-times"/>&nbsp;
                                                                                         <Translate
                                                                                             value={`campaignCreation.close`}/></>}
                                                                                     onClick={() => {
                                                                                         onBack()
                                                                                     }}/>
                                </Col>
                                <Col lg={4} md={2}/>
                                <Col lg={4} md={5} className={"text-end"}><KButton type={KBUTTON_TYPE.submit}
                                                                                   fill={true}
                                                                                   text={<><i
                                                                                       className="fal fa-chevron-double-right"/>&nbsp;
                                                                                       <Translate
                                                                                           value={`campaignCreation.next`}/></>}/></Col>
                            </Row>
                        </KForm>
                    </KCard>
                </Col></Row>
            </KContainer>
        </>
    );
}
