import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {
    gotCurFile,
    gotTemplate,
    resetCurTemplate
} from "../actions/templates";
import { TemplateArea } from "../types/templateArea";

const initialState: TemplateArea = {
};

export const templateAreaReducer = createReducer(initialState)
    .handleAction(gotTemplate, (state, action) => update(state, {
        template: {$set: action.payload},
    }))
    .handleAction(gotCurFile, (state, action) => update(state, {
       curFile : { $set: action.payload }
    }))
    .handleAction(resetCurTemplate, (state) => update(state, {
        curFile : { $set: undefined },
        template: { $set: undefined }
     }));
