import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import { Signatures } from "../types/signatures";
import { cancelSigner, gotSigns, doResetSigners } from "../actions/signatures";


const initialState: Signatures = {
    total: 0
};

export const signsReducer = createReducer(initialState)
    .handleAction(gotSigns, (state, action) => update(state, {
        list: {$set: action.payload.list},
        total: {$set: action.payload.total}
    }))
    .handleAction(doResetSigners, (state) => update(state, {
        list: {$set: undefined},
        total: {$set: 0}
    }))
    .handleAction(cancelSigner, (state, action) => {
        if (state.list) {
            const signerId = action.payload;
            const newState = state.list.filter( signer => signer.id !== signerId);
            return update(state, {
                list: {$set: newState},
                total: {$set: (state.total-=1)}
            })
        } else {
            return state;
        }
    });
