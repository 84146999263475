import {GetParams} from "@kopjra/uikit";
import {connect} from "react-redux";
import {
    doAddSigners,
    doDeleteSigners,
    doDownloadPdf,
    doDownloadXml,
    doExportSignaturePDFs,
    doExportSignatures,
    doExportSignatureXMLs,
    doGetSigns,
    doResend
} from "../actions/thunks/signatures";
import {DispatchProps, InnerProps, Signatures, StateProps} from "../components/Signatures";
import {RootState, SortingConversion, ThunkDispatch} from "../types";
import {Signer} from "../types/campaigns";
import {doSortColumn} from "@kopjra/uikit/dist/src/lib/kptable/actions/thunks";
import {sortColumn} from "@kopjra/uikit/dist/src/lib/kptable/actions/actions";


function mapStateToProps({ signs, i18n }: RootState): StateProps {
    return {
        signs: signs.list,
        total: signs.total,
        locale: i18n.locale,
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetSigns: (campaignId: string, query: GetParams) => dispatch(doGetSigns(campaignId, query)),
        onAddSigners: (campaignId: string, signers: Signer[], query: GetParams) => dispatch(doAddSigners(campaignId, signers, query)),
        onDeleteSigners: (campaignId: string, signerIds: string[], deleteAll: boolean, queryParams?: GetParams) => dispatch(doDeleteSigners(campaignId, signerIds, deleteAll, queryParams)),
        onDownloadXml: (campaignId, signatureId) => dispatch(doDownloadXml(campaignId, signatureId)),
        onDownloadPdf: (campaignId, signatureId) => dispatch(doDownloadPdf(campaignId, signatureId)),
        onResend: (campaignId, signatureId) => dispatch(doResend(campaignId, signatureId)),
        onExportSignatures: (campaignId: string, campaignName: string, signerIds: string[], all: boolean, queryParams?: GetParams) => dispatch(doExportSignatures(campaignId, campaignName, signerIds, all, queryParams)),
        onExportSignaturePDFs: (campaignId: string, campaignName: string, signerIds: string[], all: boolean, queryParams?: GetParams) => dispatch(doExportSignaturePDFs(campaignId, campaignName, signerIds, all, queryParams)),
        onExportSignatureXMLs: (campaignId: string, campaignName: string, signerIds: string[], all: boolean, queryParams?: GetParams) => dispatch(doExportSignatureXMLs(campaignId, campaignName, signerIds, all, queryParams)),
        onInitializeSort: async (loader: (q: GetParams) => Promise<void>) => {
            dispatch(sortColumn("signs/list", "signerIndex", SortingConversion.ASC));
            await dispatch(doSortColumn("signs/list", "signerIndex", loader))
        },
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Signatures);
