import {GetParams, ParamFilter, SortingDirection} from "@kopjra/uikit";
import {NewTemplate, Template} from "../types/templates";
import {List} from "../utils/commons";
import {apiCall, HttpMethod} from "./index";


export function transformToTemplateParams(query: GetParams): GetTemplateParams {
    const result: GetTemplateParams = {
        top: query.top,
        skip: query.skip,
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const namePf = query.filter.find((pf: ParamFilter) => (pf.name === "name"));
        result.name = namePf ? namePf.value as string : undefined;
        const pdfPf = query.filter.find((pf: ParamFilter) => (pf.name === "pdf"));
        result.pdfFilename = pdfPf ? pdfPf.value as string : undefined;
    }
    return result;
}

function transformToTemplate(obj: any): Template {
    const template: Template = {
        id: obj.id,
        name: obj.name,
        fields: obj.fields,
        pageMatrices: obj.pageMatrices,
        pdfFilename: obj.pdfFilename,
        createdAt: new Date(obj.createdAt),
    };
    return template;
}

export interface GetTemplateParams extends GetParams {
    name?: string;
    pdfFilename?: string;
}

export async function getTemplates(query: GetParams): Promise<List<Template>> {
    const list: List<Template> = {list: [], total: 0};
    const res = await apiCall({urlComponent: "/templates", query});
    if (res.status < 300) {
        const json = await res.json();
        const templates = await json.items.map((o: any) => transformToTemplate(o));
        list.list = templates;
        list.total = json.total;
    }
    return list;
}

export async function getTemplate(id: string): Promise<Template | undefined> {
    const res = await apiCall({urlComponent: `/templates/${id}`});
    if (res.status < 300) {
        const json = await res.json();
        return transformToTemplate(json);
    }
    return undefined;
}

export async function getTemplateFile(id: string): Promise<File | undefined> {
    const res = await apiCall({urlComponent: `/templates/${id}/content`});
    if (res.status < 300) {
        const content = await res.blob();
        const filename = "";
        const f = new File([content], filename, {type: content.type});
        return f;
    }
    return undefined;
}

export async function saveTemplate(newTemplate: NewTemplate): Promise<Template | undefined> {
    const body = {
        name: newTemplate.name,
        fields: newTemplate.fields,
        pdfFilename: newTemplate.filename,
        pageMatrices: newTemplate.pageMatrices
    };
    const res = await apiCall({urlComponent: "/templates", method: HttpMethod.POST, body});
    if (res.status < 300) {
        const json = await res.json();
        return transformToTemplate(json);
    }
    return undefined;
}

export async function saveTemplateFile(templateId: string, file: File): Promise<void> {
    await apiCall({
        urlComponent: `/templates/${templateId}/content`,
        method: HttpMethod.PUT,
        body: file,
        contentType: "application/pdf",
        stringifyBody: false
    });
}

export async function updateTemplate(newTemplate: NewTemplate): Promise<Template | undefined> {
    const body = {
        name: newTemplate.name,
        fields: newTemplate.fields,
        pdfFilename: newTemplate.filename,
        pageMatrices: newTemplate.pageMatrices
    };
    const res = await apiCall({urlComponent: `/templates/${newTemplate.id}`, method: HttpMethod.PUT, body});
    if (res.status < 300) {
        const json = await res.json();
        return transformToTemplate(json);
    }
    return undefined;
}

export async function saveDocument(campaignId: string, newTemplate: NewTemplate): Promise<Template | undefined> {
    const body = {
        name: newTemplate.name,
        fields: newTemplate.fields,
        pdfFilename: newTemplate.filename,
        pageMatrices: newTemplate.pageMatrices
    };
    const res = await apiCall({urlComponent: `/campaigns/${campaignId}/documents`, method: HttpMethod.POST, body});
    if (res.status < 300) {
        const json = await res.json();
        return transformToTemplate(json);
    }
    return undefined;
}

export async function saveDocumentFile(campaignId: string, documentId: string, file: File): Promise<void> {
    await apiCall({
        urlComponent: `/campaigns/${campaignId}/documents/${documentId}/content`,
        method: HttpMethod.PUT,
        body: file,
        contentType: "application/pdf",
        stringifyBody: false
    });
}

export async function deleteTemplates(templateIds: string[], deleteAll: boolean, queryParams?: GetParams): Promise<boolean> {
    const convertedParams = queryParams?.filter ? transformToTemplateParams(queryParams) : undefined;
    if (convertedParams) {
        delete convertedParams.top;
        delete convertedParams.skip;
    }
    const body = {ids: templateIds, deleteAll, filter: convertedParams};
    const res = await apiCall({urlComponent: `/templates`, method: HttpMethod.DELETE, body});
    return res.status < 300;
}

export async function verifyPDF(file: File): Promise<boolean> {
    const res = await apiCall({
        urlComponent: "/templates/content/verify",
        method: HttpMethod.PUT,
        body: file,
        contentType: "application/pdf",
        stringifyBody: false
    });
    const parsedRes = await res.json();
    return parsedRes.ok;
}
