import {Collaborators, CollaboratorStatus, Invite} from "../types/collaborators";
import {GetParams, ParamFilter, SortingDirection} from "@kopjra/uikit";
import {apiCall, HttpMethod} from "./index";

export interface GetCollaboratorsParams extends GetParams {
    email?: string;
    status?: CollaboratorStatus;
}

export function transformToGetCollaboratorParams(query: GetParams): GetCollaboratorsParams {
    const result: GetCollaboratorsParams = {
        top: query.top,
        skip: query.skip,
    }
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const emailPf = query.filter.find((pf: ParamFilter) => (pf.name === "email"));
        result.email = emailPf ? emailPf.value as string : undefined;
        const statusPf = query.filter.find((pf: ParamFilter) => (pf.name === "status"));
        result.status = statusPf ? statusPf.value as CollaboratorStatus : undefined;
    }
    return result;
}

export function transformToCollaborator(obj: any): Invite {
    return {
        id: obj.id,
        userId: obj.userId,
        email: obj.email,
        status: obj.status,
    }
}

export async function getCollaborators(query: GetCollaboratorsParams): Promise<Collaborators> {
    const collabList: Collaborators = {owner: null, results: null};
    const res = await apiCall({
        urlComponent: `/users/current/invites`,
        query
    });
    if (res.status < 300) {
        const json = await res.json();
        collabList.owner = json.owner;
        collabList.results = {list: json.results.items.map(transformToCollaborator), total: json.results.total};
    }
    return collabList;
}

export async function addCollaborators(emails: string[]): Promise<void> {
    await apiCall({
        urlComponent: `/users/current/invites`,
        method: HttpMethod.POST,
        body: {emails},
    });
}

export async function removeCollaborator(id: string): Promise<void> {
    await apiCall({
        urlComponent: `/users/current/invites/${id}`,
        method: HttpMethod.DELETE,
    });
}

export async function acceptInvite(inviteId: string, code: string): Promise<void> {
    await apiCall({
        urlComponent: `/users/current/invites/${inviteId}?code=${code}`,
        method: HttpMethod.PATCH,
        body: [{op: "replace", path: "/status", value: "ACCEPTED"}],
    });
}
