import * as React from "react";
import {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {
    ActionType, Column, confirmBox, DataType, FreeTextFilter, GetParams,
    GlobalActionType, KCard, KSpinner, KSpinnerSize, KTableLoader, Table,
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {tableGetParams} from "../utils/commons";
import {Audience} from "../types/audiences";


export interface StateProps {
    audiences?: Audience[];
    total: number;
    locale: string,
}

export interface DispatchProps {
    onGetAudiences: (query: GetParams) => Promise<void>;
    onOpenAudience: (audienceId: string) => void;
    onCreateAudience: () => void;
    onDeleteAudiences: (audienceIds: string[], deleteAll: boolean, queryParams?: GetParams) => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Audiences: React.FC<Props> = ({audiences, total, onGetAudiences, onOpenAudience, onCreateAudience, onDeleteAudiences}) => {
    const audiencesRetriever = async (innerQuery: GetParams, fixedQuery?: GetParams) => {
        await onGetAudiences(fixedQuery ? fixedQuery : innerQuery).catch((e) => console.warn("Get audiences error", e));
    };

    useEffect(() => {
        audiencesRetriever(tableGetParams("audiences/list"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rowAction = async (datum: Audience) => {
        onOpenAudience(datum.id);
    };

    const globalActions: GlobalActionType[] = [
        {
            name: <><div className="fal fa-trash" />{" "}<Translate value="audiences.table.delete" /></>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("audiences.table.deleteMessage")
                };
                const deleteAudiences = await confirmBox(confirmBoxConf);
                if (deleteAudiences) {
                    const audienceIds = (data as Audience[]).map(t => t.id);
                    await onDeleteAudiences(audienceIds, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        },
        {
            name: <><div className="fal fa-plus" />{" "}<Translate value="audiences.table.add" /></>,
            handler: async () => onCreateAudience()
        }
    ];

    const actions: ActionType<Audience>[] = [
        {
            name: <><i className="fal fa-folder-open fa-2x" /><Translate value="audiences.table.open" /></>,
            handler: rowAction
        },
    ];

    return (
        <KCard header={<Translate value={`audiences.title`} />}>
            {audiences ? (
                <Row>
                    <Col>
                        <Table
                            checkboxes={true}
                            filterDefinition={[
                                new FreeTextFilter("name", I18n.t("audiences.table.labels.name"))
                            ]}
                            globalActions={globalActions}
                            globalWaiter={<KSpinner size={KSpinnerSize.xxl} />}
                            waiter={<KSpinner />}
                            id={`audiences/list`}
                            total_count={total}
                            loaderFunc={(q: GetParams) => audiencesRetriever(q)}
                            data={audiences}
                            hideColumnSelector={true}
                            hideFilters={false}
                            keyField={"id"}
                            hidePager={false}
                            rowAction={rowAction}
                        >
                            <Column colid="name" classes="text-start" name="audiences.table.labels.name" type={DataType.GENERIC} sort={"name"} colspan={5}/>
                            <Column colid="nSigners" classes="text-end" name="audiences.table.labels.nSigners" type={DataType.GENERIC} colspan={3}/>
                            <Column colid="createdAt" classes="text-start" name="audiences.table.labels.created" type={DataType.DATE} sort={"createdAt"} colspan={2} />
                            <Column colid="actions" classes="text-end" name="" type={DataType.ACTIONS} actions={actions} colspan={2}/>
                        </Table>
                    </Col>
                </Row>
            ) : (
                <KTableLoader/>
            )}
        </KCard>
    );
};
