import {createReducer} from "typesafe-actions";
import {deleteFields, initialize, reset, setLastSigner, setPageMatrices, updateFields} from "../actions/pdfdoped";
import {PdfDopedState} from "../types/pdfdoped";
import {produce} from "immer";

const initialState: PdfDopedState = {
    isReset: true,
};

export const pdfdopedReducer = createReducer(initialState)
    .handleAction(setLastSigner, (state, action) => produce(state, draft => {
        draft.lastSigner = action.payload;
    }))
    .handleAction(reset, (state, action) => produce(state, (draft) => {
        draft.fields = undefined;
        // draft.file = undefined;
        draft.isReset = true;
        draft.lastSigner = undefined;
        draft.pageMatrices = undefined;
    }))
    .handleAction(initialize, (state, action) => produce(state, draft => {
        draft.file = action.payload.file;
        draft.fields = action.payload.fields && action.payload.fields.map(f => ({
            type: f.type,
            id: f.id,
            name: f.name || undefined,
            page: f.page,
            rect: f.rect,
            description: f.description || undefined,
            required: f.required || undefined,
            signerEmail: f.signerEmail || undefined,
        }));
        draft.isReset = false;
    }))
    .handleAction(setPageMatrices, (state, action) => produce(state, draft => {
        draft.pageMatrices = action.payload;
    }))
    .handleAction(updateFields, (state, action) => produce(state, draft => {
        if (!draft.fields) {
            draft.fields = action.payload;
        } else {
            for (const field of action.payload) {
                const index = draft.fields.findIndex(f => f.id === field.id);
                if (index === -1) {
                    draft.fields.push(field);
                } else {
                    draft.fields[index] = field;
                }
            }
        }
    }))
    .handleAction(deleteFields, (state, action) => produce(state, draft => {
        if (draft.fields) {
            draft.fields = draft.fields.filter(f => !action.payload.includes(f.id));
        }
    }));
;