import * as React from "react";
import {useState} from "react";
import {confirmBox, KContainer} from "@kopjra/uikit";
import {AudienceBaseInfo} from "../types/audiences";
import {AudienceInfo} from "./AudienceInfo";
import {I18n} from "react-redux-i18n";


export interface StateProps {
    audienceInfo?: AudienceBaseInfo;
}

export interface DispatchProps {
    onSetAudienceInfo: (audienceInfo: AudienceBaseInfo) => void;
    onCreateAudience: () => Promise<void>;
    onBack: () => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

enum STEPS {"audienceInfo"}

export const AudienceCreation: React.FC<Props> = (
    {
        audienceInfo, onSetAudienceInfo, onCreateAudience, onBack
    }) => {
    const [step] = useState(STEPS.audienceInfo);

    function renderComponent(step: STEPS): JSX.Element {
        let component;
        switch (step) {
            case STEPS.audienceInfo:
                component = <AudienceInfo
                    audienceInfo={audienceInfo}
                    onSubmit={(audienceInfo) => {
                        onSetAudienceInfo(audienceInfo);
                        onCreateAudience().catch(console.log);
                    }}
                    onBack={async () => {
                        if (await confirmBox({
                            message: I18n.t("campaigns.creationBack"),
                            yesText: I18n.t("generic.yes"),
                            noText: I18n.t("generic.no"),
                        })) {
                            onBack();
                        }
                    }}
                />;
                break;
            default:
                component = <></>;
                break;
        }
        return component;
    }

    return (
        <KContainer>
            {renderComponent(step)}
        </KContainer>
    );
}
