import {Collaborators} from "../types/collaborators";
import {createReducer} from "typesafe-actions";
import {gotCollaborators} from "../actions/collaborators";
import update from "immutability-helper";

const initialState: Collaborators = {
    owner: null,
    results: null
}

export const collaboratorsReducer = createReducer(initialState)
    .handleAction(gotCollaborators, (state, action) => update(state,  {
        owner: {$set: action.payload.owner},
        results: {$set: action.payload.results}
    }));
