import * as React from "react";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {
    Column,
    confirmBox,
    DataType,
    FreeTextFilter,
    GetParams,
    GlobalActionType,
    KButton,
    KBUTTON_VARIANT,
    KModal,
    KModalSize,
    KModalVariant,
    KSpace,
    KSpinner,
    KSpinnerSize,
    KTableLoader,
    Table,
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {tableGetParams} from "../utils/commons";
import {AudienceTableHook, useAudienceTable} from "./AudienceTable";
import {useParams} from "react-router-dom";
import {Signer} from "../types/signers";


export interface StateProps {
    signers?: Signer[];
    total: number;
    locale: string;
}

export interface DispatchProps {
    onGetSigners: (audienceId: string, query: GetParams) => Promise<void>;
    onAddSigners: (audienceId: string, signers: Signer[], query: GetParams) => Promise<void>;
    onDeleteSigners: (audienceId: string, signerIds: string[], deleteAll: boolean, queryParams?: GetParams) => void;
}

export interface InnerProps {
    externalAudienceId?: string;
    blockActions?: boolean;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Signers: React.FC<Props> = ({
                                             signers, total, externalAudienceId, blockActions,
                                             onGetSigners, onAddSigners, onDeleteSigners
                                         }) => {
    // @ts-ignore
    const {audienceId} = useParams();
    const curAudienceId = externalAudienceId || audienceId;
    const [opened, setOpened] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState(false);

    const audienceTableManager: AudienceTableHook = useAudienceTable({phoneRequired: false});

    const signersRetriever = async (innerQuery: GetParams, fixedQuery?: GetParams) => {
        await onGetSigners(curAudienceId, fixedQuery ? fixedQuery : innerQuery).catch((e) => console.warn("Get signers error", e));
    };

    useEffect(() => {
        signersRetriever(tableGetParams("signers/list")).catch(console.log);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curAudienceId]);

    const globalActions: GlobalActionType[] = [
        {
            name: <>
                <div className="fal fa-trash fa-lg"/>
                {" "}<Translate value="audiences.signers.table.delete"/></>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("audiences.signers.table.deleteMessage")
                };
                const deleteSigners = await confirmBox(confirmBoxConf);
                if (deleteSigners) {
                    const signerIds = (data as Signer[]).map(s => s.id || "");
                    await onDeleteSigners(curAudienceId, signerIds, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        },
        {
            name: <>
                <div className="fal fa-plus"/>
                {" "}<Translate value="audiences.signers.table.add"/></>,
            handler: async () => {
                audienceTableManager.reset();
                setSubmitting(false);
                setOpened(true);
            }
        }
    ];

    return (
        <>
            {signers ? (
                <Row>
                    <Col>
                        <Table
                            checkboxes={!blockActions}
                            filterDefinition={[
                                new FreeTextFilter("fullName", I18n.t("audiences.signers.table.labels.fullName")),
                                new FreeTextFilter("email", I18n.t("audiences.signers.table.labels.email")),
                                new FreeTextFilter("phoneNumber", I18n.t("audiences.signers.table.labels.phoneNumber")),
                            ]}
                            globalActions={!blockActions ? globalActions : []}
                            globalWaiter={<KSpinner size={KSpinnerSize.xxl}/>}
                            waiter={<KSpinner/>}
                            id={`signers/list`}
                            total_count={total}
                            loaderFunc={(q: GetParams) => signersRetriever(q)}
                            data={signers}
                            hideColumnSelector={true}
                            hideFilters={false}
                            keyField={"id"}
                            hidePager={false}
                        >
                            <Column colid="fullName" classes="text-start" name="audiences.signers.table.labels.fullName"
                                    type={DataType.GENERIC} sort={"fullName"} colspan={3}/>
                            <Column colid="email" classes="text-end" name="audiences.signers.table.labels.email"
                                    type={DataType.GENERIC} sort={"email"} colspan={3}/>
                            <Column colid="phoneNumber" classes="text-start"
                                    name="audiences.signers.table.labels.phoneNumber"
                                    type={DataType.GENERIC} sort={"phoneNumber"} colspan={3}/>
                            <Column colid="createdAt" classes="text-end" name="audiences.signers.table.labels.createdAt"
                                    type={DataType.DATE} sort={"createdAt"} colspan={3}/>
                        </Table>
                    </Col>
                </Row>
            ) : (
                <KTableLoader/>
            )}
            <KModal variant={KModalVariant.secondary} size={KModalSize.xl} show={opened} onHide={() => setOpened(false)}
                    header={I18n.t("audiences.signers.table.add")} footer={<></>}>
                {audienceTableManager.audienceTable}
                <KSpace spaces={2}/>
                <Row>
                    <Col lg={3} md={4} className={"text-start"}>
                        <KButton variant={KBUTTON_VARIANT.secondary}
                                 fill={true} disabled={submitting}
                                 text={<><i className="fal fa-times"/>&nbsp;<Translate
                                     value={`campaignCreation.close`}/></>}
                                 onClick={() => setOpened(false)}
                        />
                    </Col>
                    <Col lg={6} md={4}/>
                    <Col lg={3} md={4} className={"text-end"}>
                        <KButton fill={true} waiting={submitting}
                                 text={<><i className="fal fa-save"/>&nbsp;<Translate
                                     value={`campaignCreation.signers.save`}/></>}
                                 onClick={async () => {
                                     const data = audienceTableManager.getData();
                                     if (data) {
                                         setSubmitting(true);
                                         await onAddSigners(curAudienceId, data, tableGetParams("signers/list"));
                                         setOpened(false);
                                     }
                                 }}
                        />
                    </Col>
                </Row>
            </KModal>
        </>
    );
};
