import {ThunkAction, ThunkDispatch as OriginalThunkDispatch} from "redux-thunk";
import {rootReducer} from "../reducers";
import {StateType} from "typesafe-actions";
import {ReactNode} from "react";
import {RootAction} from "../actions";
import {SortingDirection} from "@kopjra/uikit";

export type RootState = StateType<typeof rootReducer>;

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, RootAction>;
export type ThunkDispatch = OriginalThunkDispatch<RootState, undefined, RootAction>;

export type JustChildren = { children: ReactNode };

export interface LocationState {
    from?: string;
}

export interface UserProfileData {
    name?: string;
}

export type User = {
    id: string
    name: string | null
    email: string
    auth0Id: string
    createdAt: Date
    mainUserId: string | null
}

export const SortingConversion = {
    ASC: SortingDirection.DOWN,
    DESC: SortingDirection.NONE,
    NONE: SortingDirection.UP,
};
