import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import {KLabel, KSpace} from "@kopjra/uikit";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import {Signer} from "../types/campaigns";
import {emailRegex} from "../utils/commons";


interface GridElement extends ReactDataSheet.Cell<GridElement, string> {
    value: string;
    placeholder?: string;
    hasError: boolean;
}

export type AudienceProps = {
    signers?: Signer[];
    phoneRequired: boolean;
};

type AudiencePropsExtension = {
    tableSigners: GridElement[][];
    firstSubmitTry: boolean;
    dataValidated: boolean;
    setDataValidated: React.Dispatch<React.SetStateAction<boolean>>;
}

type AudiencePropsExtended = AudienceProps & AudiencePropsExtension;

export interface AudienceTableHook {
    audienceTable: JSX.Element;
    getData: () => Signer[] | undefined;
    reset: () => void;
}

export function useAudienceTable(props: AudienceProps): AudienceTableHook {
    const firstRow = [{
        readOnly: true,
        colSpan: 4,
        value: I18n.t("campaignCreation.signers.fullname") + "*",
        hasError: false
    }, {
        readOnly: true,
        colSpan: 4,
        value: I18n.t("campaignCreation.signers.email") + "*",
        hasError: false
    }, {
        readOnly: true,
        colSpan: 4,
        value: I18n.t("campaignCreation.signers.phone") + (props.phoneRequired ? "*" : ""),
        hasError: false
    }];
    const lastRow = [{
        value: "",
        placeholder: I18n.t("campaignCreation.signers.newSigner"),
        hasError: false
    }, {value: "", hasError: false}, {value: "", hasError: false}];
    const adaptedSigners = props.signers ? [firstRow, ...props.signers.map(s => [{
        value: s.fullName,
        hasError: false
    }, {value: s.email, hasError: false}, {
        value: s.phoneNumber || "",
        hasError: false
    }]), lastRow] : [firstRow, lastRow];
    const [tableSigners, setTableSigners] = useState<GridElement[][]>(adaptedSigners);
    const [firstSubmitTry, setFirstSubmitTry] = useState<boolean>(false);
    const [dataValidated, setDataValidated] = useState<boolean>(false);
    const extendedProps: AudiencePropsExtension = {
        tableSigners: tableSigners,
        firstSubmitTry,
        dataValidated,
        setDataValidated
    };
    return {
        audienceTable: <AudienceTable {...props} {...extendedProps}/>,
        getData: () => {
            let data: Signer[] | undefined = undefined;
            if (!firstSubmitTry) {
                setFirstSubmitTry(true);
            }
            if (dataValidated) {
                const signers: Signer[] = [];
                tableSigners.forEach((r, index) => {
                    const values = r.map(c => c.value);
                    signers.push({fullName: values[0], email: values[1], phoneNumber: values[2], internalId: index + 1});
                })
                signers.shift();
                signers.pop();
                data = signers;
            }
            return data;
        },
        reset: () => {
            setTableSigners([firstRow, lastRow]);
            setFirstSubmitTry(false);
            setDataValidated(false);
        }
    }
}

const AudienceTable: React.FC<AudiencePropsExtended> = ({
                                                            tableSigners,
                                                            dataValidated,
                                                            phoneRequired,
                                                            firstSubmitTry,
                                                            setDataValidated
                                                        }) => {
    const [oneInvalidFullname, setOneInvalidFullname] = useState<boolean>(false);
    const [oneInvalidEmail, setOneInvalidEmail] = useState<boolean>(false);
    const [oneInvalidPhone, setOneInvalidPhone] = useState<boolean>(false);
    const [oneDuplicateEmail, setOneDuplicateEmail] = useState<boolean>(false);

    const validateCellValue = useCallback((value: string, col: number, discard: boolean): boolean => {
        let valid = false;
        if (discard) {
            return true;
        }
        switch (col) {
            case 0:
                valid = value.length > 0;
                break;
            case 1:
                valid = value.length > 0 && new RegExp(emailRegex).test(value);
                break;
            case 2:
                const phoneRegex = /^(?:00|\+)(?:[0-9]?){6,14}[0-9]$/;
                valid = phoneRequired ? value.length > 0 && phoneRegex.test(value) : value.length === 0 || phoneRegex.test(value);
                break;
            default:
                break;
        }
        return valid;
    }, [phoneRequired]);

    const findErrors = useCallback(() => {
        const emailSet = new Set<string>();
        let fullnameError = false;
        let emailError = false;
        let phoneError = false;
        let emailDuplicate = false;
        tableSigners.forEach((row, i) => row.forEach((col, j) => {
            col.hasError = !validateCellValue(col.value, j, i === 0 || i === tableSigners.length - 1);
            if (col.hasError && j === 0 && !fullnameError) {
                fullnameError = true;
            }
            if (col.hasError && j === 1 && !emailError) {
                emailError = true;
            }
            if (col.hasError && j === 2 && !phoneError) {
                phoneError = true;
            }
            if (!col.hasError && j === 1) {
                if (!emailSet.has(col.value)) {
                    emailSet.add(col.value);
                } else {
                    tableSigners[i][j].hasError = true;
                    emailDuplicate = true;
                }
            }
        }));
        setOneInvalidFullname(fullnameError);
        setOneInvalidEmail(emailError);
        setOneInvalidPhone(phoneError);
        setOneDuplicateEmail(emailDuplicate);
        setDataValidated((!fullnameError && !emailError && !phoneError && !emailDuplicate) && (tableSigners.length - 2) > 0);
    }, [setDataValidated, tableSigners, validateCellValue]);

    useEffect(() => {
        findErrors();
    }, [findErrors]);

    function isRowEmpty(row: GridElement[]): boolean {
        for (const element of row) {
            if (!!element.value) return false
        }
        return true
    }

    function deleteEmptyRows(): void {
        let i = tableSigners.length - 1;
        while (i > 0) {
            if (isRowEmpty(tableSigners[i])) {
                tableSigners.splice(i, 1);
            }
            i--;
        }
    }

    function onCellsChanged(changes: ReactDataSheet.CellsChangedArgs<GridElement>, additions?: ReactDataSheet.CellAdditionsArgs<string>): void {
        additions?.forEach(({row, col, value}) => {
            if (col === 0) {
                tableSigners.push([{value: "", hasError: false}, {value: "", hasError: false}, {
                    value: "",
                    hasError: false
                }]);
            }
            tableSigners[row][col].value = value ? (col === 1 ? value.trim().toLowerCase() : value) : "";
        });
        changes.forEach(({row, col, value}) => {
            tableSigners[row][col].value = value ? (col === 1 ? value.trim().toLowerCase() : value) : "";
        });
        deleteEmptyRows();
        if (!isRowEmpty(tableSigners[tableSigners.length - 1])) {
            tableSigners.push([{
                value: "",
                placeholder: I18n.t("campaignCreation.signers.newSigner"),
                hasError: false
            }, {value: "", hasError: false}, {value: "", hasError: false}]);
        }
        findErrors();
    }

    const cellRenderer: ReactDataSheet.CellRenderer<GridElement, string> = (props) => {
        const styles = props.cell.hasError && firstSubmitTry ? {
            border: "2px solid #ef615e",
            height: "100%"
        } : {border: "2px solid transparent", height: "100%"};
        let classes = "cell";
        if (props.row === 0) {
            classes += ` read-only`
        }
        if (props.row > 0 && props.selected) {
            classes += ` selected`
        }
        return (
            <td onMouseDown={props.onMouseDown} onMouseOver={props.onMouseOver} onDoubleClick={props.onDoubleClick}
                className={classes}>
                <div style={styles}>{props.children}</div>
            </td>
        );
    }

    return (
        <>
            <Row><Col className="text-start"><KLabel text={<Translate value="campaignCreation.signers.label"/>}/></Col></Row>
            <Row><Col className="text-start"><span style={{fontSize: 12}}><Translate
                value="campaignCreation.signers.labelDesc"/></span></Col></Row>
            <KSpace spaces={2}/>
            {!dataValidated && !oneInvalidFullname && !oneInvalidEmail && !oneDuplicateEmail && !oneInvalidPhone && firstSubmitTry ? <><Row><Col className="text-start"><span
                style={{fontSize: 12, color: "#ef615e"}}><Translate
                value="campaignCreation.signers.errors.atLeastOneSigner"/></span></Col></Row></> : <></>}
            {oneInvalidFullname && firstSubmitTry ? <><Row><Col className="text-start"><span
                style={{fontSize: 12, color: "#ef615e"}}><Translate
                value="campaignCreation.signers.errors.fullnameNotValid"/></span></Col></Row></> : <></>}
            {oneInvalidEmail && firstSubmitTry ? <><Row><Col className="text-start"><span
                style={{fontSize: 12, color: "#ef615e"}}><Translate
                value="campaignCreation.signers.errors.emailNotValid"/></span></Col></Row></> : <></>}
            {oneDuplicateEmail && firstSubmitTry ? <><Row><Col className="text-start"><span
                style={{fontSize: 12, color: "#ef615e"}}><Translate
                value="campaignCreation.signers.errors.emailDuplicate"/></span></Col></Row></> : <></>}
            {oneInvalidPhone && firstSubmitTry ? <><Row><Col className="text-start"><span
                style={{fontSize: 12, color: "#ef615e"}}><Translate
                value="campaignCreation.signers.errors.phoneNotValid"/></span></Col></Row></> : <></>}
            <Row>
                <ReactDataSheet
                    data={tableSigners}
                    valueRenderer={cell => cell.value || cell.placeholder}
                    dataRenderer={cell => cell.value}
                    onContextMenu={(e, cell) => (cell.readOnly ? e.preventDefault() : null)}
                    onCellsChanged={onCellsChanged}
                    cellRenderer={cellRenderer}
                />
            </Row>
        </>
    );
}
