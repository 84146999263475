import * as React from "react";
import {useState} from "react";
import {Col, Row} from "react-bootstrap";
import {Translate} from "react-redux-i18n";
import {KAccordion, KButton, KBUTTON_VARIANT, KCard, KLabel, KSelect, KSpace, KStepProgress} from "@kopjra/uikit";
import {CampaignType} from "../types/campaigns";
import {AudienceData} from "../types/campaignCreation";
import {Audience as AudienceType} from "../types/audiences";
import Signers from "../containers/Signers";
import {AudienceTableHook, useAudienceTable} from "./AudienceTable";
import {getAllSigners} from "../api/signers";


type AudienceEntry = { title: JSX.Element, body: JSX.Element };


export type AudienceProps = {
    audienceData?: AudienceData;
    campaignType: string;
    requireOTP: boolean;
    onSubmit: (audienceData: AudienceData) => void;
    onBack: (audienceData: AudienceData) => void;
};

export const Audience: React.FC<AudienceProps> = ({audienceData, campaignType, requireOTP, onSubmit, onBack}) => {
    const states = [
        <Translate value={"campaignCreation.campaignInfo"}/>,
        <Translate value={"campaignCreation.audience"}/>,
        <Translate value={"campaignCreation.document"}/>
    ];

    const [curSelectedAudience, setCurSelectedAudience] = useState<AudienceType | undefined>(undefined);
    const [curNewChoose, setCurNewChoose] = useState<boolean>(audienceData?.newChoose === true ? true : !!(audienceData?.availableAudiences && audienceData.availableAudiences.length < 1));
    const [selectError, setSelectError] = useState<boolean>(false);

    const audienceTableManager: AudienceTableHook = useAudienceTable({signers: audienceData?.signers, phoneRequired: requireOTP});
    const [submitting, setSubmitting] = useState<boolean>(false);

    function getSelectGroups() {
        const selectableAudiences = (audienceData?.availableAudiences || [])
            .filter(a => !requireOTP || a.allSignersHavePhone)
            .map(a => ({value: a, label: a.name}));
        const notSelectableAudiences = (audienceData?.availableAudiences || [])
            .filter(a => requireOTP && !a.allSignersHavePhone)
            .map(a => ({value: a, label: a.name, isDisabled: true}));
        return [...selectableAudiences, {label: <Translate value={"campaignCreation.signers.chooseSelectGroup"}/>, options: notSelectableAudiences}];
    }

    const existentAudiencesSlot: AudienceEntry = {
        title: <Translate value={"campaignCreation.signers.existentAudience"}/>,
        body: <>
            <Row className={selectError ? "was-validated" : ""}>
                <Col md={2}><KLabel text={<Translate value={"campaignCreation.signers.choose"}/>}/></Col>
                <Col md={8}>
                    <KSelect id={`chooseAudience`} key={`chooseAudience`} required={true}
                             options={getSelectGroups() as any}
                             onChange={val => {
                                 setCurSelectedAudience(val as AudienceType);
                                 setSelectError(false);
                             }}
                             value={curSelectedAudience}/>
                </Col>
                <Col md={2}></Col>
            </Row>
            {curSelectedAudience ? (
                <Signers externalAudienceId={curSelectedAudience.id} blockActions={true}/>
            ) : (
                <></>
            )}
        </>
    };

    const newSignersSlot: AudienceEntry = {
        title: <Translate value={"campaignCreation.signers.newSigners"}/>,
        body: <>
            {audienceTableManager.audienceTable}
        </>
    };

    return (
        <>
            <Row><Col lg={10} md={12}><KStepProgress activeIndex={1} states={states}/></Col></Row>
            <KSpace spaces={4}/>
            <Row><Col>
                <KCard header={<Translate value={"campaignCreation.audience"}/>}>
                    {campaignType === CampaignType.CLOSED || campaignType === CampaignType.MULTI ? (
                            <KAccordion elements={[existentAudiencesSlot, newSignersSlot]} exclusive
                                        onChangeOpened={(indexes: number[]) => {
                                            const isNewChoose = !(indexes[0] === 0);
                                            setCurNewChoose(isNewChoose);
                                            setCurSelectedAudience(undefined);
                                        }}
                                        actives={curNewChoose ? [1] : [0]}
                            />
                    ) : (
                        <Row><Col className="text-start"><span style={{fontSize: 18}}><Translate
                            value="campaignCreation.signers.openCampaignDesc"/></span></Col></Row>
                    )}
                    <KSpace spaces={2}/>
                    <Row>
                        <Col lg={3} md={4} className={"text-start"}>
                            <KButton id={"backAudience"} variant={KBUTTON_VARIANT.secondary} fill={true}
                                     text={<><i className="fal fa-chevron-left"/>&nbsp;<Translate value={`campaignCreation.back`}/></>}
                                     onClick={() => onBack({
                                         availableAudiences: audienceData?.availableAudiences || [],
                                         signers: [], newChoose: curNewChoose
                                     })}/>
                        </Col>
                        <Col lg={6} md={4}/>
                        <Col lg={3} md={4} className={"text-end"}>
                            <KButton id={"publishAudience"} variant={KBUTTON_VARIANT.primary} disabled={submitting} fill={true}
                                     text={submitting ? <i className={"fal fa-spinner-third fa-spin"}/> : <><i className="fal fa-chevron-double-right" />&nbsp;<Translate value={`campaignCreation.next`} /></>}
                                     onClick={async () => {
                                         setSubmitting(true);
                                         try {
                                             if (campaignType === CampaignType.OPEN) {
                                                 onSubmit({signers: [], availableAudiences: [], newChoose: false});
                                             } else if (campaignType === CampaignType.CLOSED || campaignType === CampaignType.MULTI) {
                                                 let data = audienceTableManager.getData();
                                                 if (curNewChoose && !data) {
                                                     return;
                                                 }
                                                 if (!curNewChoose && !curSelectedAudience) {
                                                     setSelectError(true);
                                                     return;
                                                 }
                                                 if (curSelectedAudience && campaignType === CampaignType.MULTI) {
                                                     data = await getAllSigners(curSelectedAudience.id) as any;
                                                 }
                                                 onSubmit({
                                                     availableAudiences: audienceData?.availableAudiences || [],
                                                     signers: data || [], newChoose: curNewChoose,
                                                     selectedAudience: curSelectedAudience
                                                 });
                                             }
                                         } finally {
                                            setSubmitting(false);
                                        }
                                     }}
                            />
                        </Col>
                    </Row>
                </KCard>
            </Col></Row>
        </>
    );
}
