import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import { Signers } from "../types/signers";
import {cancelSigner, doResetSigners, gotSigners} from "../actions/signers";


const initialState: Signers = {
    total: 0
};

export const signersReducer = createReducer(initialState)
    .handleAction(gotSigners, (state, action) => update(state, {
        list: {$set: action.payload.list},
        total: {$set: action.payload.total}
    }))
    .handleAction(doResetSigners, (state) => update(state, {
        list: {$set: undefined},
        total: {$set: 0}
    }))
    .handleAction(cancelSigner, (state, action) => {
        if (state.list) {
            const signerId = action.payload;
            const newState = state.list.filter( signer => signer.id !== signerId);
            return update(state, {
                list: {$set: newState},
                total: {$set: (state.total-=1)}
            })
        } else {
            return state;
        }
    });
