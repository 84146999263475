import {ThunkResult} from "../../types";
import {Dispatch} from "redux";
import {RootAction} from "../index";
import {GetParams, setAlert} from "@kopjra/uikit";
import {cancelSigner, gotSigners} from "../signers";
import {deleteSigners, getSigners, saveSigners, transformToSignerParams} from "../../api/signers";
import {I18n} from "react-redux-i18n";
import {Signer} from "../../types/signers";
import {getAudience} from "../../api/audiences";
import {gotAudience} from "../audiences";


export const doDeleteSigners = (audienceId: string, signerIds: string[], deleteAll: boolean, queryParams?: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const deleted = await deleteSigners(audienceId, signerIds, deleteAll, queryParams);
    if (deleted) {
        dispatch(setAlert(I18n.t("alerts.infos.deleteSigners"), "info"));
        for (const id of signerIds) {
            dispatch(cancelSigner(id));
        }
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.deleteSigners"), "error"));
    }
}

export const doGetSigners = (audienceId: string, query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotSigners(await getSigners(audienceId, transformToSignerParams(query))));
};

export const doAddSigners = (audienceId: string, signers: Signer[], query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const chunks = chunkArray(signers, 100);
    for (const signerChunk of chunks) {
        await saveSigners(audienceId, signerChunk);
    }
    dispatch(gotSigners(await getSigners(audienceId, transformToSignerParams(query))));
    const audience = await getAudience(audienceId);
    if (audience) {
        dispatch(gotAudience(audience));
    }
}

function chunkArray<T>(arr: Array<T>, chunkSize: number): Array<Array<T>> {
    const chunks: Array<Array<T>> = [];
    while (arr.length) {
        chunks.push(arr.splice(0, chunkSize));
    }
    return chunks;
}
