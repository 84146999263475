import {connect} from "react-redux";
import {setAudienceData, setCampaignInfo, setDocumentData} from "../actions/campaignCreation";
import {doCreateCampaign, doGetAvailableAudiences, doGetAvailableTemplates} from "../actions/thunks/campaignCreation";
import {CampaignCreation, DispatchProps, InnerProps, StateProps} from "../components/CampaignCreation";
import {RootState, ThunkDispatch} from "../types";
import {AudienceData, DocumentData} from "../types/campaignCreation";
import {CampaignBaseInfo} from "../types/campaigns";
import {routerTools} from "../utils/router";


function mapStateToProps({campaignCreation}: RootState): StateProps {
    return {
        campaignInfo: campaignCreation.campaignInfo,
        documentData: campaignCreation.documentData,
        audienceData: campaignCreation.audienceData,
        campaignCreated: campaignCreation.campaignCreated
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetAvailableTemplates: () => dispatch(doGetAvailableTemplates()),
        onGetAvailableAudiences: () => dispatch(doGetAvailableAudiences()),
        onSetCampaignInfo: (campaignInfo: CampaignBaseInfo) => dispatch(setCampaignInfo(campaignInfo)),
        onSetDocumentData: (documentData: DocumentData) => dispatch(setDocumentData(documentData)),
        onSetAudienceData: (audienceData: AudienceData) => dispatch(setAudienceData(audienceData)),
        onCreateCampaign: () => dispatch(doCreateCampaign()),
        onReturnHome: () => routerTools.push(`/`),
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(CampaignCreation);
