import {TemplateFieldType} from "../types/pdfdoped";

const en = {
    locale: {
        en: "EN",
        it: "IT",
    },
    input: {
        copy: "Copy",
        copied: "Copied"
    },
    toolbar: {
        campaigns: "Campaigns",
        templates: "Templates",
        audiences: "Audiences"
    },
    user: {
        profile: "User Profile",
        account: "Account",
        logout: "Logout",
        login: "Login",
        support: "Support",
        name: "Name",
        email: "Email",
        verified: "Email verified",
        modify: "Modify",
        save: "Save changes",
        resetPasswordMessage: "An email was sent to your email address with instructions to change your password",
        resendEmail: "Resend",
        resendEmailMessage: "An email was sent to your email address with instructions to verify your account. After verifying it, reload this page",
    },
    alerts: {
        infos: {
            newTemplate: "Template saved successfully",
            deleteTemplates: "Templates deleted successfully",
            deleteAudiences: "Audiences deleted successfully",
            newCampaign: "Campaign saved successfully",
            deleteCampaigns: "Campaigns deleted successfully",
            deleteSigners: "Signers deleted successfully",
            emailReSent: "Email re-sent successfully",
            addedCollaborator: "Collaborator invited successfully",
            removedCollaborator: "Collaborator deleted successfully",
            changeExpirationDate: "Expiration date changed successfully"
        },
        errors: {
            newTemplateMissing: "Select a pdf template with at least a signature field",
            newTemplate: "Error during template creation",
            deleteTemplates: "Error deleting templates",
            deleteAudiences: "Error deleting audiences",
            newCampaign: "Error during campaign creation",
            deleteCampaigns: "Error deleting campaigns",
            deleteSigners: "Error deleting signers",
            pdfDownload: "Error downloading the PDF",
            xmlDownload: "Error downloading the XML evidence file",
            emailReSend: "Error re-sending the email",
            addingCollaborator: "Error inviting collaborator",
            removingCollaborator: "Error deleting collaborator",
            noCredits: "Error! Signature not allowed, payment required by campaign owner",
            campaignNotFound: "Error! Campaign not found",
            expiredCampaign: "Error! Campaign expired",
            noSignatureClosedCampaign: "Error! Cannot retrieve signature of closed campaign",
            genericError: "Server error, try again later",
            changeExpirationDate: "Error changing expiration date",
            signatureSameEmail: "Error! A signature has already been made in this campaign with the specified email. Use a different email or contact the campaign owner."
        },
    },
    generic: {
        yes: "Yes",
        no: "No",
        tooSmall: {
            title: "Your browser window is too small",
            description: "Please resize your browser to be at least 900px wide in order to perform this action",
        },
    },
    error: {
        page: "Error",
        forbidden: "Access Denied",
        notFound: "Page not found",
        notAuthenticated: "Not authenticated"
    },
    kptable: {
        filters: "Filters",
        apply: "Apply",
        reset: "Reset",
        show_columns: "Show columns",
        pager: {
            first: "First",
            last: "Last",
        },
        actions: "Actions",
        date: {
            short: "MM/DD/YYYY",
            long: "MM/DD/YYYY hh:mm a",
        },
        confirm: {
            title: "Confirmation",
            yes: "Yes",
            no: "No",
        },
        show: "Show",
        globalSelectionWarn1: "All elements on this page have been selected. ",
        globalSelectionWarn2: "All elements have been selected. ",
        globalSelectionWarnAll: "Select all elements",
        globalSelectionWarnNotAll: "Cancel selection",
        globalSelectionFilterWarn1: "(Any active filters will be considered)",
        globalSelectionFilterWarn2: "(Any active filters are considered)",
        genericFilterError: "You need to add a filter or modify the existing one",
    },
    credits: {
        overview: "Overview",
        remaining: "Remaining signs",
        extra: "Extra signs consumed",
        buy: "Buy credits",
    },
    stats: {
        active: "Active campaigns",
        rate: "Global signature rate"
    },
    collaborators: {
        title: "Collaborators",
        inviteLabel: "Invite collaborators",
        inviteButton: "Invite",
        inviteTip: "Use comma ',' to add multiple email addresses",
        invitePlaceholder: "Example vittoria@kopjra.com,lucio@legaltechitaly.com...",
        requiredEmails: "Insert at least one email address",
        accepting: "Accepting invitation to collaborate...",
        accepted: "Invitation to collaborate accepted. You will be redirected in 3 seconds...",
        errorAccepting: "Error accepting the invitation. Contact the campaign owner.",
        owner: "Main account",
        table: {
            email: "Email",
            status: "Status",
            delete: "Delete",
            resend: "Resend",
        },
    },
    campaigns: {
        title: "Campaigns",
        campaign: "Campaign",
        creating: "Creating campaign...",
        createError: "Error creating campaign",
        addCampaign: "New campaign",
        rate: "Signature rate",
        table: {
            noCampaigns: "No campaigns created!",
            open: "Open",
            deleteMessage: "Do you really want to delete the selected campaigns?",
            delete: "Delete",
            labels: {
                name: "Name",
                type: "Type",
                expired: "Expired",
                expiredNo: "No",
                status: "Status",
                created: "Creation date",
                signed: "Signed",
                required2fa: "2fa Required",
                publicLink: "Public Link",
                customLogo: "Custom logo"
            },
        },
        status: {
            NEW: "New",
            ACTIVE: "Active",
            PAUSED: "Paused",
            FINISHED: "Finished",
            EXPIRED: "Expired"
        },
        type: {
            OPEN: "Open",
            CLOSED: "Closed",
            MULTI: "Sequential",
        },
        creationBack: "Do you really want to go back to the previous window? All changes will be lost",
        atLeastOneSignField: "Warning! Insert at least one sign field into the template/document"
    },
    templates: {
        title: "Templates",
        new: "New template",
        edit: "Edit Template",
        addTemplate: "Add template",
        choose: "Choose template",
        upload: "Upload pdf",
        pdfErrorRequired: "Upload a pdf file",
        pdfErrorSize: "Upload a pdf file up to 10MB",
        pdfVerifyError: "The uploaded PDF file appears to be corrupted, please replace it with an intact file.",
        savedTemplate: "From saved template",
        newDocument: "New document",
        saveNewTemplate: "Save as new template",
        filename: "Filename",
        editOnTheFly: "Edit",
        addField: "Field",
        table: {
            open: "Open",
            delete: "Delete",
            deleteMessage: "Do you really want to delete the selected templates?",
            add: "New template",
            labels: {
                name: "Name",
                pdf: "Pdf",
                created: "Creation date",
            },
        },
        fields: {
            field: "Field",
            type: "Type",
            name: "Name",
            description: "Text",
            required: "Required",
            nameError: "Special characters allowed only space, hyphen and round brackets (minimum length 3 characters)",
            descriptionError: "Minimum length 3 characters",
            select: "Select",
            unselect: "Unselect",
            delete: "Delete",
            signatureType: "Signature",
            conditionalSignatureType: "Conditional signature",
            customType: "Custom",
            emailType: "Email",
            fullnameType: "Full name",
            phoneType: "Phone number with international prefix",
            dateType: "Date",
        },
    },
    audiences: {
        audience: "Audience",
        title: "Audiences",
        table: {
            open: "Open",
            delete: "Delete",
            deleteMessage: "Do you really want to delete the selected audiences?",
            add: "New audience",
            labels: {
                name: "Name",
                nSigners: "number of signers",
                createdAt: "Creation date"
            }
        },
        signers: {
            table: {
                add: "Add signers",
                delete: "Delete",
                labels: {
                    fullName: "Full name",
                    email: "Email",
                    phoneNumber: "Phone number",
                    createdAt: "Insertion date"
                },
                insertMessage: "Signers added successfully to the audience!",
                deleteMessage: "Do you really want to delete the selected signers from the audience?",
                insertErrorMessage: "Error during insertion of one or more signers, duplicate emails are not allowed",
                confirmMessage: "The provided signers will be added to the audience. Do you want to proceed?"
            },
        }
    },
    signs: {
        close: "Close",
        resume: "Resume",
        pause: "Pause",
        table: {
            add: "Add signers",
            delete: "Delete",
            resend: "Resend",
            pdf: "PDF",
            xml: "XML",
            export: "Data",
            exportPDFs: "PDF",
            exportXMLs: "XML",
            labels: {
                fullname: "Full Name",
                email: "Email",
                phone: "Phone Number",
                status: "Status",
                emailLastOpenedAt: "Last read",
            },
            insertMessage: "Signers added successfully! Emails will be sent directly if the campaign is active or when it will return active",
            deleteMessage: "Do you really want to delete the selected signers?",
            exportMessage: "Do you really want to download all data related to selected signatures in an excel file? (Active filters will be considered)",
            exportMessagePDFs: "Do you really want to download all PDFs related to selected signatures in a zip file? (Active filters will be considered)",
            exportMessageXMLs: "Do you really want to download all XMLs related to selected signatures in a zip file? (Active filters will be considered)"
        },
        errors: {
            invalidCode: "Not valid campaign code",
            invalidCampaignId: "Not valid campaign id",
            campaignFinished: "Campaign finished",
            campaignPaused: "Campaign paused",
            invalidSign: "Not valid signature code",
            alreadySigned: "You have already signed this campaign",
            noSignEnv: "Cannot access to sign environment",
            insertErrorMessage: "Error during insertion of one or more signers, duplicate emails are not allowed",
        },
        messages: {
            dataOk: "Signer data added successfully!",
            checkEmail: "An email with the instructions to sign the document has been sent to the address ",
            checkSpam: "If you don't find the email, check the spam folder or trash folder.",
            emailhint: "Check the provided email address! The sign link will not be sent if the email is not valid",
        },
        proceed: "Next",
        labels: {
            title: "Sign campaign",
            fullname: "Full name",
            email: "Email",
            phone: "Phone number with international prefix",
        },
        placeholders: {
            fullname: "Type your full name",
            email: "Type your email address",
            phone: "Type your phone number with international prefix",
        },
        status: {
            NEW: "New",
            SENT: "Sent",
            NOT_SENT: "Send error",
            SIGNED: "Signed",
        },
    },
    campaignCreation: {
        campaignInfo: "New Campaign",
        document: "Document",
        legalTerms: "Legal Terms",
        audience: "Audience",
        close: "Close",
        back: "Back",
        next: "Next",
        save: "Save",
        publish: "Publish",
        leave: "Are you sure you want to leave the campaign creation?",
        info: {
            name: "Campaign name",
            fromName: "Sender",
            fromEmail: "Sender email",
            type: "Type",
            open: "Open",
            closed: "Closed",
            multi: "Sequential",
            expirationDate: "Expiration Date",
            customLogo: "Custom logo",
            customLogoUpload: "Upload logo",
            customLogoHint: "Minimuum recommended size: 200 x 200 px; Max file weight: 2Mb",
            requireOTP: "Require OTP (One Time Password) via SMS",
            placeholders: {
                name: "Insert campaign name to be displayed for signers",
                fromName: "Insert name or your company name",
                fromEmail: "Insert contact email to be displayed for signers",
            },
            errors: {
                name: "Insert campaign name to be displayed for signers",
                fromName: "Insert name or your company name",
                fromEmail: "Insert contact email to be displayed for signers",
                customLogo: "Upload a jpg or png image max 2Mb",
            },
        },
        terms: {
            name: "Name",
            description: "Description",
            delete: "Delete",
            add: "Add legal term",
            defaultSingleName: "Legal term",
            defaultName: "Default legal term",
            defaultDescription: "I declare I have read, understood and accepted the aformentioned documentation",
            errorDescription: "Insert the description of the legal term"
        },
        signers: {
            existentAudience: "From existing audience",
            newSigners: "New signers",
            choose: "Choose audience",
            chooseSelectGroup: "Audiences with missing phone numbers",
            save: "Save",
            label: "Signers",
            labelDesc: "Add signers or copy/paste directly from an excel spreadsheet",
            fullname: "Full name",
            email: "Email",
            phone: "Phone number with international prefix",
            newSigner: "Insert a new signer",
            openCampaignDesc: "By publishing an open campaign, a unique URL will be generated, and everyone with this URL will be able to view and sign the document.",
            errors: {
                fullnameNotValid: "There is at least one invalid full name field",
                emailNotValid: "There is at least one invalid email field",
                emailDuplicate: "There is at least one duplicate email field",
                phoneNotValid: "There is at least one invalid phone number field",
                atLeastOneSigner: "Insert at least one signer",
            },
        },
        published: {
            proceed: "By publishing your campaign all the contacts in your audience will receive an email with the instructions to access the document. \n Are you ready to proceed?",
            title: "Campaign published",
            message1: "Your campaign",
            message2: "is successfully published",
            track: "You can track updates and pause the campaign on the campaign page",
            publicURL: "Here is your public URL related to the campaign to share with your signers",
            returnToCampaigns: "Return to campaigns"
        }
    },
    campaignSign: {
        signStyle: "Signature style",
        graphicOption: "Graphic stroke",
        fontOption: "Generated signature",
        imageOption: "Upload image",
        signImageError: "Upload a png image of your signature, max 2Mb"
    },
    pdfToolbar: {
        zoomIn: "Zoom in",
        zoomOut: "Zoom out",
        undo: "Undo",
        redo: "Redo",
        createField: "Create field",
        openSidebar: "Properties",
        createFieldTip: "Creates a signature field in the top left corner of the current page",
        openSidebarTip: "Opens the properties sidebar where you can edit the selected field and reorder the signers",
        currentPage: "Current page number",
        snap: "Snap to guides for alignment",
        clone: "Duplicate selected fields",
    },
    pdfSidebar: {
        mainTitle: "Model properties",
        fieldsTitle: "Fields",
        signersTitle: "Signer Order",
        deleteFieldTip: "Delete field",
        secondaryTitle: "Field properties",
        fieldName: "Field name",
        requiredError: "Required field",
        fieldNameValidateError: "Special characters allowed only space, hyphen and round brackets (minimum length 3 characters)",
        fieldDescription: "Condition's text",
        fieldDescriptionValidateError: "Minimum length 3 characters",
        fieldType: "Type",
        fieldTypeInfo: "The Phone number type will be disabled if the signer does not have a phone number set",
        fieldRequired: "Required field",
        signer: "Assign signer",
        noSigner: "No signer assigned",
        signerInfo: "A signer will be disabled if the field type is Phone number and the signer does not have a phone number set",
        signerFieldsInfo: "A signer will be disabled if one of the selected fields is of type Phone number and the signer does not have a phone number set",
        deleteFieldConfirmation: "Do you really want to delete the selected field?",
        deleteFieldsConfirmation: "Do you really want to delete the selected fields?",
    },
    pdfViewer: {
        defaultCreateText: "creating signature field...",
        [TemplateFieldType.SIGNATURE]: "Signature",
        [TemplateFieldType.CONDITIONAL_SIGNATURE]: "Conditional Signature",
        [TemplateFieldType.CUSTOM]: "Custom",
        [TemplateFieldType.EMAIL]: "Email",
        [TemplateFieldType.FULLNAME]: "Full name",
        [TemplateFieldType.PHONE]: "Phone number",
        [TemplateFieldType.DATE]: "Date",
        multipleSelection: "Multiple selection",
        error: {
            errorsFound: "There are errors in the template:",
            noFields: "There must be at least one field of type signature",
            fieldsWithNoSigner: "All fields must have a signer assigned",
            signerWithNoFields: "Signer with no signature fields assigned (%{signers})",
        }
    }
};
export default en;
