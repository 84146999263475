import * as React from "react";
import { Col, Row } from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import {KDate, KInput, KLabel, KSpace} from "@kopjra/uikit";
import {Campaign, CampaignStatus, CampaignType} from "../types/campaigns";
import {KCredit} from "./KCredit";
import {CampaignStats} from "../types/stats";


export type CampaignDetailsProps = {
    campaign: Campaign;
    campaignStats: CampaignStats;
    onUpdateExpirationDate(campaignId: string, date: Date | undefined): Promise<void>;
};

export const CampaignDetails: React.FC<CampaignDetailsProps> = ({ campaign, campaignStats, onUpdateExpirationDate}) => {

    const campaignTypes = new Map<string, string>(Object.values(CampaignType).map(type => {
        return [ type, I18n.t(`campaigns.type.${type}`)];})
    );

    const campaignStatuses = new Map<string, string>(Object.values(CampaignStatus).map(status => {
        return [ status, I18n.t(`campaigns.status.${status}`)];})
    );

    return <>
        <Row>
            <Col className="text-center">
                <span style={{ fontSize: 22, fontWeight: "bold" }}>{`"${campaign.name}"`}</span>
            </Col>
        </Row>
        <KSpace spaces={2} />
        <Row>
            <Col className="text-start" md={4}>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="campaigns.table.labels.type" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{campaignTypes.get(campaign.type)}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="campaigns.table.labels.expired" />} /></Col>
                    <Col><KDate id={"expirationDate"} isClearable noDateText={<Translate value="campaigns.table.labels.expiredNo" />}
                                minDate={new Date()} date={campaign.expirationDate} readonly={campaign.status === CampaignStatus.FINISHED}
                                onChange={(d: Date | undefined) => onUpdateExpirationDate(campaign.id, d)}
                                useTime={true}
                    /></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="campaigns.table.labels.required2fa" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{campaign.require2fa ? <Translate value="generic.yes" /> : <Translate value="generic.no" />}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="templates.table.labels.pdf" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{(campaign.documents?.map(d => d.name) || []).join(", ")}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="campaigns.table.labels.status" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{campaignStatuses.get(campaign.status)}</span></Col>
                </Row>
                <Row>
                    <Col md={4}><KLabel text={<Translate value="campaigns.table.labels.customLogo" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{campaign.customLogoFileLocation ? <Translate value="generic.yes" /> : <Translate value="generic.no" />}</span></Col>
                </Row>
            </Col>
            <Col className="text-center" md={4}>
                <Row>
                    <Col><KCredit remaining={campaignStats.totalSigned} total={campaign.type === CampaignType.CLOSED || campaign.type === CampaignType.MULTI ? campaignStats.totalSigners : undefined} infinite={false} labelText={<Translate value="campaigns.table.labels.signed" />} /></Col>
                </Row>
            </Col>
            <Col className="text-center" md={4}>
                {campaign.type === CampaignType.CLOSED ? <KCredit remaining={campaignStats.totalSigned > 0 ? (Math.trunc((campaignStats.totalSigned / campaignStats.totalSigners) * 100)) : 0} infinite={false} colored percent labelText={<Translate value="campaigns.rate" />} /> : <></> }
            </Col>
        </Row>
        {campaign.type === CampaignType.OPEN ? (
            <>
                <KSpace spaces={2} />
                <Row>
                    <Col md={2}><KLabel text={<Translate value="campaigns.table.labels.publicLink" />} /></Col>
                    <Col><KInput readOnly={true} id={"publicLink"} defaultValue={campaign.publicUrl} copyButton onClick={() => navigator.clipboard.writeText(campaign.publicUrl)}/></Col>
                </Row>
            </>
        ) : (
            <></>
        )}
    </>
};
