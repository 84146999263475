import {store} from "../index";
import {parse} from "qs";
import {setAlert} from "@kopjra/uikit";
import {I18n} from "react-redux-i18n";
import {routerTools} from "../utils/router";
import {BE_ENDPOINT} from "../utils/constants";
import {createURLSearchParams} from "../utils/commons";

export let getToken: (options?: any) => Promise<string>;
export function initGetTokenFunction(func: (options?: any) => Promise<string>) {
    getToken = func;
}

export enum HttpMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
    HEAD = "HEAD",
}

export interface ApiCallParams {
    urlComponent: string;
    method?: HttpMethod;
    contentType?: string;
    query?: any;
    body?: any;
    ignoreAuthResponse?: boolean;
    stringifyBody?: boolean;
}

export class FetchError extends Error {
    readonly response: Response;

    constructor(message: string, response: Response) {
        super(message);
        this.name = "FetchError";
        this.response = response;
    }
}

export async function apiCall({urlComponent, method = HttpMethod.GET, contentType = "application/json", query, body, ignoreAuthResponse = false, stringifyBody = true}: ApiCallParams): Promise<Response> {
    const location = routerTools.location;

    const locationQP = parse(location.search, {ignoreQueryPrefix: true});

    if (locationQP && locationQP.ticket) {
        query = query || {};
        query.ticket = locationQP.ticket;
    }

    if (!urlComponent.startsWith("/") && !(urlComponent.startsWith("http://") || urlComponent.startsWith("https://"))) {
        urlComponent = "/" + urlComponent;
    }

    let url = (urlComponent.startsWith("http://") || urlComponent.startsWith("https://")) ? urlComponent : `${BE_ENDPOINT}${urlComponent}`;

    if (query) {
        url = url + `?${createURLSearchParams(query).toString()}`;
    }

    if (method === HttpMethod.PATCH) {
        contentType = "application/json-patch+json";
    }

    const headers: {[key: string]: string} = {
        "Content-Type": contentType,
    };

    try {
        const token = await getToken();
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }
    } catch (err) {
        console.log(err);
    }

    const fetchOps: RequestInit = {
        method,
        headers,
        credentials: "include",
    };

    if (body) {
        fetchOps.body = stringifyBody ? JSON.stringify(body) : body;
    }

    const res = await fetch(url, fetchOps);

    if (!res.ok) {
        if (res.status === 401 && !ignoreAuthResponse) {
            store.dispatch(setAlert(I18n.t("error.notAuthenticated"), "error"));
        }
        throw new FetchError("There was an error while fetching", res);
    } else {
        return res;
    }
}

export function getApiUrl({urlComponent, query}: Pick<ApiCallParams, "urlComponent" | "query">): string {
    if (!urlComponent.startsWith("/")) {
        urlComponent = "/" + urlComponent;
    }

    let url = `${BE_ENDPOINT}${urlComponent}`;

    if (query) {
        url = url + `?${createURLSearchParams(query).toString()}`;
    }

    return url;
}
