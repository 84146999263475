import {connect} from "react-redux";
import {DispatchProps, InnerProps, StateProps, Toolbar} from "../components/Toolbar";
import {RootState, ThunkDispatch} from "../types";
import {saveLocale} from "@kopjra/uikit";
import {setLocale} from "react-redux-i18n";
import {routerTools, Routes} from "../utils/router";

function mapStateToProps({i18n}: RootState): StateProps {
    return {
        locale: i18n.locale,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onChangeLocale: (locale) => {saveLocale(locale); dispatch(setLocale(locale))},
        onGoHome: () => {routerTools.push(Routes.HOME)},
        onGoTemplates: () => {routerTools.push(Routes.TEMPLATES)},
        onGoAudiences: () => {routerTools.push(Routes.AUDIENCES)},
        onGoAccount: () => {routerTools.push(Routes.USER_ACCOUNT)},
        onGoCollaborators: () => {routerTools.push(Routes.COLLABORATORS)},
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Toolbar);
