import React, {FC} from "react";
import {Translate} from "react-redux-i18n";
import {tooltipate} from "@kopjra/uikit";

export interface PDFDopedToolbarProps {
    currentPage: string;
    totalPages: number;
    snap: boolean;
    onCurrentPageChange: (page: string) => void;
    onZoomOut: () => void;
    onZoomIn: () => void;
    onResetZoom: () => void;
    onUndo: () => void;
    onRedo: () => void;
    onCreateField: () => void;
    onOpenSidebar: () => void;
    onSetSelectTool: () => void;
    onSetCreateTool: () => void;
    onSetSnap: (snap: boolean) => void;
    onCloneSelected: () => void;
}

export const PDFDopedToolbar: FC<PDFDopedToolbarProps> = ({onResetZoom, onCloneSelected, onSetSnap, snap, currentPage, onCurrentPageChange, onCreateField, onOpenSidebar, onRedo, onZoomOut, onZoomIn, onUndo, totalPages, onSetCreateTool, onSetSelectTool}) => {
    return (
        <div className={"toolbar"}>
            <div className="leftButtonSet">
                {tooltipate(<button
                    type="button"
                    onClick={() => onZoomOut()}
                    className="tbButton fal fa-minus"
                    id="zoomOut"
                ></button>, <Translate value={"pdfToolbar.zoomOut"}/>)}
                {tooltipate(<button
                    type="button"
                    onClick={() => onZoomIn()}
                    className="tbButton fal fa-plus"
                    id="zoomIn"
                ></button>, <Translate value={"pdfToolbar.zoomIn"}/>)}
                {/*{tooltipate(<button*/}
                {/*    type="button"*/}
                {/*    onClick={() => onResetZoom()}*/}
                {/*    className="tbButton fal fa-square"*/}
                {/*    id="resetZoom"*/}
                {/*></button>, <Translate value={"pdfToolbar.resetZoom"}/>)}*/}
                <div className="separator"/>
                {/*{tooltipate(<button*/}
                {/*    type="button"*/}
                {/*    onClick={() => onSetSnap(!snap)}*/}
                {/*    className={`tbButton nohover ${snap ? "active" : ""} fal fa-grid-dividers`}*/}
                {/*    id="snap"*/}
                {/*></button>, <Translate value={"pdfToolbar.snap"}/>)}*/}
                {tooltipate(<button
                    type="button"
                    onClick={() => onCloneSelected()}
                    className={`tbButton fal fa-clone`}
                    id="snap"
                ></button>, <Translate value={"pdfToolbar.clone"}/>)}
            </div>
            <div className="centerButtonSet">
                <label className="tbInputLabel" style={{marginBottom: "0px", marginTop: "0.15rem"}}>
                    {tooltipate(<input
                        onKeyUp={(e) => {
                            if (e.key === "Enter") e.preventDefault()
                        }}
                        onFocus={(e) => e.target.select()}
                        onClick={(e) => (e.target as any).select()}
                        onChange={(e) => onCurrentPageChange(e.target.value)}
                        value={currentPage}
                        type="text"
                        className="tbInput"
                        id="pageNumber"
                        min="1"
                    />, <Translate value={"pdfToolbar.currentPage"}/>)}{" "}
                    / <span id="numberPages">{totalPages}</span>
                </label>
            </div>
            <div className="rightButtonSet">
                {tooltipate(<button
                    type="button"
                    onClick={() => onUndo()}
                    className="tbButton fal fa-arrow-turn-left"
                    id="undo"
                ></button>, <Translate value={"pdfToolbar.undo"}/>)}
                {tooltipate(<button
                    type="button"
                    onClick={() => onRedo()}
                    className="tbButton fal fa-arrow-turn-right"
                    id="redo"
                ></button>, <Translate value={"pdfToolbar.redo"}/>)}
                {tooltipate(<button type="button" onClick={() => onOpenSidebar()} className="tbButton">
                    <div className="flex items-center text text-primary">
                        <i className="fal fa-sliders"/>&nbsp;
                        <Translate value="pdfToolbar.openSidebar" className="ml-2 font-lato"/>
                    </div>
                </button>, <Translate value={"pdfToolbar.openSidebarTip"}/>)}
                {tooltipate(<button type="button" onClick={() => onCreateField()} className="tbButton">
                    <div className="flex items-center text text-primary">
                        <i className="fal fa-plus-square"/>&nbsp;
                        <Translate value="pdfToolbar.createField" className="ml-2 font-lato"/>
                    </div>
                </button>, <Translate value={"pdfToolbar.createFieldTip"}/>)}
            </div>
        </div>
    );
}