import { combineReducers, Reducer } from "redux";
import { kAlertReducer, kptableReducer } from "@kopjra/uikit";
import { i18nReducer, I18nState } from "react-redux-i18n";
import { mainReducer } from "./main";
import { statsReducer } from "./stats";
import { campaignsReducer } from "./campaigns";
import { templatesReducer } from "./templates";
import { campaignPublishedAreaReducer } from "./campaignPublishedArea";
import { campaignCreationReducer } from "./campaignCreation";
import { signsReducer } from "./signatures";
import { templateAreaReducer } from "./templateArea";
import { campaignSignReducer } from "./campaignSign";
import { collaboratorsReducer } from "./collaborators";
import { audiencesReducer } from "./audiences";
import { audienceAreaReducer } from "./audienceArea";
import { signersReducer } from "./signers";
import { audienceCreationReducer } from "./audienceCreation";
import {pdfdopedReducer} from "./pdfdoped";

export const rootReducer = combineReducers({
    i18n: i18nReducer as unknown as Reducer<I18nState>,
    alert: kAlertReducer,
    main: mainReducer,
    kptable: kptableReducer,
    stats: statsReducer,
    campaigns: campaignsReducer,
    templates: templatesReducer,
    campaign: campaignPublishedAreaReducer,
    campaignCreation: campaignCreationReducer,
    signs: signsReducer,
    template: templateAreaReducer,
    campaignSign: campaignSignReducer,
    collaborators: collaboratorsReducer,
    audiences: audiencesReducer,
    audience: audienceAreaReducer,
    signers: signersReducer,
    audienceCreation: audienceCreationReducer,
    pdfdoped: pdfdopedReducer,
});
