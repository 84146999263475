import * as React from "react";
import {KCard, KContainer, KSpace, KSpinner, KSpinnerSize} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";
import {useEffect} from "react";
import {Audience} from "../types/audiences";
import {useParams} from "react-router-dom";
import {AudienceDetails} from "./AudienceDetails";
import Signers from "../containers/Signers";


export interface StateProps {
    audience?: Audience;
}

export interface DispatchProps {
    onGetAudience: (audienceId: string) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const AudienceArea: React.FC<Props> = ({audience, onGetAudience}) => {
    // @ts-ignore
    const {audienceId} = useParams();

    useEffect(() => {
        if (audienceId) {
            onGetAudience(audienceId).catch((e) => console.warn("Get audience error", e));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <KContainer>
            <KCard header={<Translate value={"audiences.audience"}/>}>
                {audience ? (
                    <>
                        <AudienceDetails audience={audience} />
                        <KSpace spaces={2}/>
                        <Signers/>
                    </>
                ) : (
                    <KSpinner size={KSpinnerSize.xxl}/>
                )}
            </KCard>
        </KContainer>
    );
};
