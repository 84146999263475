import * as React from "react";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {
    b64ToUint8Array,
    KButton,
    KBUTTON_TYPE,
    KCard,
    KCheck,
    KContainer,
    KFileUpload,
    KForm,
    KInput,
    KInputHintVariant,
    KLabel, KNavBrandFixed,
    KSelect,
    KSELECT_SIZE,
    KSpace,
    KSpinner,
    KSpinnerSize,
} from "@kopjra/uikit";
import {useQuery} from "../utils/router";
import {useParams} from "react-router-dom";
import {Campaign, CampaignStatus, Signer} from "../types/campaigns";
import {fromBase64} from "../utils/base64";
import {I18n, Translate} from "react-redux-i18n";
import {Sign, SignCode, SignStatus} from "../types/signatures";
import {emailRegex} from "../utils/commons";
import {pngValidator} from "png-validator";
import {TemplateFieldType} from "../types/pdfdoped";


export const SignType = {
    GRAPHIC: "GRAPHIC",
    FONT: "FONT",
    IMAGE: "IMAGE"
}

const Fonts = ["Caveat", "Shadows Into Light", "Allura", "Stalemate"];

export interface StateProps {
    campaign?: Campaign | null;
    sign?: Sign | null;
}

export interface DispatchProps {
    onGetCampaign(campaignId: string, campaignCode: string): Promise<void>;
    onGetSign(campaignId: string, campaignCode: string, signId: string, signCode: string): Promise<void>;
    onPatchSign(campaignId: string, campaignCode: string, signId: string, signCode: string, signer: Signer): Promise<void>;
    onCreateSign(campaignId: string, campaignCode: string, signer: Signer): Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const CampaignSign: React.FC<Props> = ({campaign, sign, onGetCampaign, onGetSign, onCreateSign, onPatchSign}) => {
    // @ts-ignore
    const {campaignId} = useParams();
    const queryParams = useQuery();
    const [errorLabel, setErrorLabel] = useState<string | undefined>(undefined);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [signCreatedEmail, setSignCreatedEmailEmail] = useState<string | undefined>(undefined);
    const [decoded, setDecoded] = useState<SignCode | undefined>(undefined);
    const [signType, setSignType] = useState<string>(SignType.GRAPHIC);
    const [customSignImage, setCustomSignImage] = useState<string | undefined>();


    useEffect(() => {
        const dec: SignCode | undefined = fromBase64(queryParams.get("code") ?? "");
        setDecoded(dec);
        if (!dec) {
            setErrorLabel("invalidCode");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (decoded) {
            // uso null quando non è stato mai richiesto l'oggetto dal db
            if (campaign === null) {
                onGetCampaign(campaignId, decoded.code).catch(console.log);
            } else if (!campaign) {
                setErrorLabel("invalidCampaignId");
            } else {
                if (campaign.status === CampaignStatus.FINISHED || campaign.status === CampaignStatus.EXPIRED) {
                    setErrorLabel("campaignFinished");
                } else if (campaign.status === CampaignStatus.PAUSED || campaign.status === CampaignStatus.NEW) {
                    setErrorLabel("campaignPaused");
                } else if (decoded.signatureId && decoded.signatureCode && sign === null) {
                    onGetSign(campaignId, decoded.code, decoded.signatureId, decoded.signatureCode).catch(console.log);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decoded, campaign]);

    useEffect(() => {
        if (sign !== null) {
            if (sign === undefined) {
                setErrorLabel("invalidSign");
            } else {
                if (sign.status === SignStatus.SIGNED) {
                    setErrorLabel("alreadySigned");
                } else if (submitting && sign.fUrl) {
                    window.location.replace(sign.fUrl);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sign]);

    const checkEmailComponent = <>
        <Row><Col className={"text-center"}><i className="fal fa-check-circle fa-3x text-success"/></Col></Row>
        <Row><Col className={"text-center"}><h1><Translate value={`signs.messages.dataOk`}/></h1></Col></Row>
        <KSpace/>
        <Row><Col><h4><Translate value={`signs.messages.checkEmail`}/><b>{signCreatedEmail}</b>.</h4></Col></Row>
        <Row><Col><h4><Translate value={`signs.messages.checkSpam`}/></h4></Col></Row>
        <KSpace spaces={2}/>
        <Row><Col className={"text-center"}><KNavBrandFixed iconPartial={"web-sign"} appName={"Web Sign"}/></Col></Row>
    </>;

    const errorComponent = <>
        <Row><Col className={"text-center"}><span style={{color: "#ef615e"}}><i className="fal fa-times-circle fa-2x"/></span></Col></Row>
        <Row><Col className={"text-center"}><KLabel
            text={<Translate value={`signs.errors.${errorLabel}`}/>}/></Col></Row>
        <KSpace spaces={2}/>
        <Row><Col className={"text-center"}><KNavBrandFixed iconPartial={"web-sign"} appName={"Web Sign"}/></Col></Row>
    </>;

    const signTypeForm = signType === SignType.GRAPHIC ? <></> :
        signType === SignType.FONT ? <Row><Col md={3}><KSelect id={`font`} required defaultValue={"Caveat"} size={KSELECT_SIZE.lg}
                                              options={Fonts.map(f => {
                                                  const v = f.replaceAll(" ", "");
                                                  return { value: v, label: <span style={{fontSize: 20}} className={`${v.toLowerCase()}`}>{f}</span> };
                                              })}
            /></Col><Col md={9}/></Row>
            : signType === SignType.IMAGE ? <Row><Col md={6}>
                <KFileUpload required showUploadedFilename preloadImage accept={"image/png"} id={"signImage"}
                             label={<Translate value="campaignSign.imageOption" />} maxSizeInMb={1}
                             onChange={f => {
                                 try {
                                     f = f as string;
                                     pngValidator(b64ToUint8Array(f));
                                     setCustomSignImage(f);
                                 } catch {
                                     throw new Error("Corrupted png image");
                                 }
                             }}
                             hint={<Translate value="campaignCreation.info.customLogoHint" />}
                             maxSizeErrorMessage={<Translate value={"campaignSign.signImageError"} />}
                             requiredErrorMessage={<Translate value={"campaignSign.signImageError"} />}
                /></Col><Col md={6}/></Row>
                : <></>;

    const getCampaignForm = (campaign: Campaign) => <Row><Col md={8}>
        <>
            <KNavBrandFixed iconPartial={"web-sign"} appName={"Web Sign"}/>
            <KSpace spaces={2}/>
            <KCard header={<><Translate value={`signs.labels.title`} />{` ${campaign.name}`}</>}>
                <KForm onSubmit={async values => {
                    const signer: Signer = {
                        fullName: (values.fullname as string).trim(),
                        email: (values.email as string).trim().toLowerCase(),
                        phoneNumber: values.phone ? values.phone as string : undefined,
                        signType: signType,
                        signFont: values.font ? values.font as string : undefined,
                        signImageBase64: customSignImage,
                        internalId: 1,
                    };
                    setSubmitting(true);
                    if (decoded?.signatureId && decoded?.signatureCode) {
                        await onPatchSign(campaignId, decoded?.code || "", decoded?.signatureId || "", decoded?.signatureCode || "", signer).catch(console.log);
                    } else {
                        await onCreateSign(campaignId, decoded?.code || "", signer).catch(console.log);
                        setSignCreatedEmailEmail(signer.email);
                    }
                }}>
                    <Row><Col><KInput label={<Translate value="signs.labels.fullname"/>}
                                      required={true} id={"fullname"}
                                      placeholder={I18n.t("signs.placeholders.fullname")}
                                      readOnly={!!sign?.fullName}
                                      value={sign?.fullName}
                                      pattern={"^[a-zA-Z]{2,}[a-zA-Z\\ ]+$"}
                    /></Col></Row>
                    <Row><Col><KInput label={<Translate value="signs.labels.email"/>}
                                      required={true} id={"email"} placeholder={I18n.t("signs.placeholders.email")}
                                      pattern={emailRegex}
                                      readOnly={!!sign?.email}
                                      value={sign?.email}
                                      hintVariant={!decoded?.signatureCode ? KInputHintVariant.WARNING : undefined}
                                      hint={!decoded?.signatureCode ? <Translate value={"signs.messages.emailHint"}/> : undefined}
                    /></Col></Row>
                    {campaign.require2fa || campaign.documents![0].fields.some(f => f.type === TemplateFieldType.PHONE) ?
                        <Row><Col><KInput label={<Translate value="signs.labels.phone"/>}
                                          required={true} id={"phone"}
                                          placeholder={I18n.t("signs.placeholders.phone")}
                                          pattern={"^(?:00|\\+)(?:[0-9]?){6,14}[0-9]$"}
                                          readOnly={!!sign?.phoneNumber}
                                          value={sign?.phoneNumber}
                        /></Col></Row>
                        : <></>
                    }
                    {decoded?.signatureId && decoded?.signatureCode ? (
                        <>
                            <KSpace />
                            <Row><Col className={"text-start"}>
                                <KLabel text={<Translate value={"campaignSign.signStyle"} />} />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <KCheck id={"graphicOption"} name={"signOption"} label={<Translate value="campaignSign.graphicOption"/>} type={"radio"} inline required defaultChecked={true} onChange={(e: any) => e.currentTarget.checked ? setSignType(SignType.GRAPHIC) : {}} />
                                <KCheck id={"fontOption"} name={"signOption"} label={<Translate value="campaignSign.fontOption"/>} type={"radio"} inline required onChange={(e: any) => e.currentTarget.checked ? setSignType(SignType.FONT) : {}} />
                                <KCheck id={"imageOption"} name={"signOption"} label={<Translate value="campaignSign.imageOption"/>} type={"radio"} inline required onChange={(e: any) => e.currentTarget.checked ? setSignType(SignType.IMAGE) : {}} />
                            </Col></Row>
                            {signTypeForm}
                        </>
                    ) : (
                        <></>
                    )}
                    <KSpace spaces={2}/>
                    <Row><Col md={4}><KButton disabled={submitting} type={KBUTTON_TYPE.submit} fill={true}
                                              text={<>{submitting ? <KSpinner/> : <i className="fal fa-chevron-double-right"/>}&nbsp;
                                                  <Translate value={`signs.proceed`}/></>}/></Col></Row>
                </KForm>
            </KCard>
        </>
    </Col></Row>;

    const component = errorLabel ? errorComponent
        : signCreatedEmail ? checkEmailComponent
            : (campaign?.status === CampaignStatus.ACTIVE) ? getCampaignForm(campaign)
            : <Row><Col className={"text-center"}><KSpinner size={KSpinnerSize.xxl}/></Col></Row>;

    return (
        <KContainer>{component}</KContainer>
    );
};
