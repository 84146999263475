import * as React from "react";
import {KContainer, KSpinner} from "@kopjra/uikit";
import {useEffect, useState} from "react";
import {Translate} from "react-redux-i18n";
import {useParams} from "react-router-dom";
import {routerTools, useQuery} from "../utils/router";

export interface StateProps {

}

export interface DispatchProps {
    onAcceptInvite: (inviteId: string, code: string) => Promise<void>;
}

export interface InnerProps {

}

export type Props = StateProps & DispatchProps & InnerProps;

export const AcceptCollaborator: React.FC<Props> = ({onAcceptInvite}) => {

    const [errorAccepting, setErrorAccepting] = useState<boolean | undefined>();
    const params = useParams<{inviteId: string}>();
    const query = useQuery();

    useEffect(() => {
        (async () => {
            try {
                const code = query.get("code");
                if (code) {
                    await onAcceptInvite(params.inviteId, code);
                    setErrorAccepting(false);
                    setTimeout(() => routerTools.replace("/"), 3000);
                } else {
                    setErrorAccepting(true);
                }
            } catch {
                setErrorAccepting(true);
            }
        })().catch(console.log);
        // eslint-disable-next-line
    }, [setErrorAccepting, onAcceptInvite]);

    return (
        <KContainer>
            {errorAccepting === undefined ? (
                <>
                    <h1><Translate value={"collaborators.accepting"}/> <KSpinner/></h1>
                </>
            ) : !errorAccepting ? (
                <>
                    <h1><i className="fal fa-check-circle text-success"/> <Translate value={"collaborators.accepted"}/></h1>
                </>
            ) : (
                <>
                    <h1><i className="fal fa-times-circle text-danger"/> <Translate value={"collaborators.errorAccepting"}/></h1>
                </>
            )}
        </KContainer>
    );
}