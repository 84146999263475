import {GetParams, ParamFilter, SortingDirection} from "@kopjra/uikit";
import {Signer} from "../types/campaigns";
import {Sign, SignStatus} from "../types/signatures";
import {ApiResponse, createLinkToDownload, List} from "../utils/commons";
import {BE_ENDPOINT} from "../utils/constants";
import {apiCall, HttpMethod} from "./index";
import * as XLSX from "xlsx";

export function transformToSignParams(query: GetParams): GetSignParams {
    const result: GetSignParams = {
        top: query.top,
        skip: query.skip,
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const fullnamePf = query.filter.find((pf: ParamFilter) => (pf.name === "fullname"));
        result.fullName = fullnamePf ? fullnamePf.value as string : undefined;
        const emailPf = query.filter.find((pf: ParamFilter) => (pf.name === "email"));
        result.email = emailPf ? emailPf.value as string : undefined;
        const phonePf = query.filter.find((pf: ParamFilter) => (pf.name === "phone"));
        result.phoneNumber = phonePf ? phonePf.value as string : undefined;
    }
    return result;
}

function transformToSign(obj: any): Sign {
    const sign: Sign = {
        id: obj.id,
        status: obj.status,
        campaignId: obj.campaignId,
        code: obj.code,
        created: obj.created,
        updated: obj.updated,
        email: obj.email,
        fullName: obj.fullName,
        phoneNumber: obj.phoneNumber,
        fUrl: obj.fUrl,
        emailLastOpenedAt: obj.emailLastOpenedAt,
        signerIndex: obj.signerIndex,
    };
    return sign;
}

export interface GetSignParams extends GetParams {
    fullName?: string;
    phoneNumber?: string;
    email?: string;
}

export async function getSigns(campaignId: string, query: GetParams): Promise<List<Sign>> {
    const list: List<Sign> = {list: [], total: 0};
    const res = await apiCall({urlComponent: `/campaigns/${campaignId}/signatures`, query});
    if (res.status < 300) {
        const json = await res.json();
        const signs = await json.items.map((o: any) => transformToSign(o));
        list.list = signs;
        list.total = json.total;
    }
    return list;
}

export async function getPublicSign(campaignId: string, campaignCode: string, signId: string, signCode: string): Promise<Sign | undefined> {
    let urlString: string = `${BE_ENDPOINT}/public/campaigns/${campaignId}/signatures/${signId}`;
    const url = new URL(urlString);
    url.searchParams.set("code", campaignCode);
    url.searchParams.set("signatureCode", signCode);
    const res = await fetch(url.toString());
    if (res.status < 300) {
        const json = await res.json();
        return transformToSign(json);
    }
    return undefined;
}

export async function getSign(campaignId: string, signCode: string): Promise<Sign | undefined> {
    const res = await apiCall({urlComponent: `/campaigns/${campaignId}/signatures/${signCode}`});
    if (res.status < 300) {
        const json = await res.json();
        return transformToSign(json);
    }
    return undefined;
}

export async function getSignPdf(campaignId: string, signatureId: string): Promise<string | undefined> {
    const res = await apiCall({urlComponent: `/campaigns/${campaignId}/signatures/${signatureId}/pdfs`});
    if (res.status < 300) {
        const json = await res.json();
        return json[0].url; // TODO Manage the multi document case
    }
    return undefined;
}

export async function getSignXml(campaignId: string, signatureId: string): Promise<string | undefined> {
    const res = await apiCall({urlComponent: `/campaigns/${campaignId}/signatures/${signatureId}/xml`});
    if (res.status < 300) {
        const json = await res.json();
        return json.url;
    }
    return undefined;
}

export async function saveSigners(campaignId: string, signers: Signer[]): Promise<boolean> {
    const fixedSigners = signers.map(s => {
        return {fullName: s.fullName, email: s.email, phoneNumber: s.phoneNumber || undefined, signerIndex: s.signerIndex};
    })
    const res = await apiCall({
        urlComponent: `/campaigns/${campaignId}/signatures`,
        method: HttpMethod.POST,
        body: fixedSigners
    });
    return res.status < 300;
}

export async function deleteSigners(campaignId: string, signerIds: string[], deleteAll: boolean, queryParams?: GetParams): Promise<boolean> {
    const convertedParams = queryParams?.filter ? transformToSignParams(queryParams) : undefined;
    if (convertedParams) {
        delete convertedParams.top;
        delete convertedParams.skip;
    }
    const body = {ids: signerIds, deleteAll, filter: convertedParams};
    const res = await apiCall({urlComponent: `/campaigns/${campaignId}/signatures`, method: HttpMethod.DELETE, body});
    return res.status < 300;
}

export async function createPublicSign(campaignId: string, campaignCode: string, signer: Signer): Promise<ApiResponse<Sign>> {
    const apiRes: ApiResponse<Sign> = {object: undefined, statusCode: 0};
    const urlString: string = `${BE_ENDPOINT}/public/campaigns/${campaignId}/signatures`;
    const url = new URL(urlString);
    url.searchParams.set("code", campaignCode);
    const fetchOps: RequestInit = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            fullName: signer.fullName, email: signer.email, phoneNumber: signer.phoneNumber
        })
    };
    const res = await fetch(url.toString(), fetchOps);
    apiRes.statusCode = res.status;
    if (res.status === 200) {
        const json = await res.json();
        apiRes.object = transformToSign(json);
    }
    return apiRes;
}

export async function patchPublicSign(campaignId: string, campaignCode: string, signId: string, signCode: string, signer: Signer): Promise<ApiResponse<Sign>> {
    const apiRes: ApiResponse<Sign> = {object: undefined, statusCode: 0}
    const urlString: string = `${BE_ENDPOINT}/public/campaigns/${campaignId}/signatures/${signId}`;
    const url = new URL(urlString);
    url.searchParams.set("code", campaignCode);
    url.searchParams.set("signatureCode", signCode);
    const fetchOps: RequestInit = {
        method: "PATCH",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            signType: signer.signType, signFont: signer.signFont, signImageBase64: signer.signImageBase64, phoneNumber: signer.phoneNumber
        }),
    };
    const res = await fetch(url.toString(), fetchOps);
    apiRes.statusCode = res.status;
    if (res.status === 200) {
        const json = await res.json();
        apiRes.object = transformToSign(json);
    }
    return apiRes;
}

export async function changeSignatureStatus(campaignId: string, signatureId: string, newStatus: SignStatus): Promise<void> {
    const body = [{op: "replace", path: "/status", value: newStatus}];
    await apiCall({
        urlComponent: `/campaigns/${campaignId}/signatures/${signatureId}`,
        method: HttpMethod.PATCH,
        body,
    });
}

export async function exportCampaignSignatures(campaignId: string, campaignName: string, signerIds: string[], all: boolean, queryParams?: GetParams): Promise<void> {
    const convertedParams = queryParams?.filter ? transformToSignParams(queryParams) : undefined;
    if (convertedParams) {
        delete convertedParams.top;
        delete convertedParams.skip;
    }
    const body = {ids: signerIds, all, filter: convertedParams};
    const res = await apiCall({
        method: HttpMethod.POST,
        urlComponent: `/campaigns/${campaignId}/signatures/export`,
        body
    });
    if (res.ok && res.body) {
        const csvString = await res.text();
        const data: string[][] = [];
        const {parse} = await import("csv-parse/browser/esm");
        await new Promise<void>( (res, rej) => {
            parse(csvString).on("readable", function () {
                let record;
                // @ts-ignore
                while((record = this.read()) !== null) {
                    data.push(record);
                }
            }).on("end", () => {
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.aoa_to_sheet(data);
                XLSX.utils.book_append_sheet(wb, ws, "signatures");
                XLSX.writeFile(wb, `export-data-${campaignName}.xlsx`);
                res();
            }).on("error", rej);
        })
    }
}

export async function exportCampaignSignaturePDFs(campaignId: string, campaignName: string, signerIds: string[], all: boolean, queryParams?: GetParams): Promise<void> {
    const convertedParams = queryParams?.filter ? transformToSignParams(queryParams) : undefined;
    if (convertedParams) {
        delete convertedParams.top;
        delete convertedParams.skip;
    }
    const body = {ids: signerIds, all, filter: convertedParams};
    const res = await apiCall({
        method: HttpMethod.POST,
        urlComponent: `/campaigns/${campaignId}/signatures/pdf-zip`,
        body
    });
    if (res.ok && res.body) {
        const blob = await res.blob();
        const fileurl = window.URL.createObjectURL(new Blob([blob]));
        createLinkToDownload(`export-pdfs-${campaignName}.zip`, fileurl);
    }
}

export async function exportCampaignSignatureXMLs(campaignId: string, campaignName: string, signerIds: string[], all: boolean, queryParams?: GetParams): Promise<void> {
    const convertedParams = queryParams?.filter ? transformToSignParams(queryParams) : undefined;
    if (convertedParams) {
        delete convertedParams.top;
        delete convertedParams.skip;
    }
    const body = {ids: signerIds, all, filter: convertedParams};
    const res = await apiCall({
        method: HttpMethod.POST,
        urlComponent: `/campaigns/${campaignId}/signatures/xml-zip`,
        body
    });
    if (res.ok && res.body) {
        const blob = await res.blob();
        const fileurl = window.URL.createObjectURL(new Blob([blob]));
        createLinkToDownload(`export-xmls-${campaignName}.zip`, fileurl);
    }
}
