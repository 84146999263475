import {BrowserRouter, RouteChildrenProps, Route, useLocation, RouteProps} from "react-router-dom";
import {createContext, FC, useContext} from "react";
import {History} from "history";
import {JustChildren} from "../types";
import React from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";

const RouterContext = createContext<RouteChildrenProps>({} as RouteChildrenProps);

export let routerTools: History;

export const ProtectedRoute = ({ component, ...args }: RouteProps) => (
    <Route component={withAuthenticationRequired(component as React.ComponentType)} {...args} />
);

export const HookedBrowserRouter: FC<JustChildren> = ({ children }: JustChildren) => (
    <BrowserRouter>
        <Route>
            {(routeProps: RouteChildrenProps) => {
                routerTools = routeProps.history;
                return <RouterContext.Provider value={routeProps}>
                    {children}
                </RouterContext.Provider>;
            }}
        </Route>
    </BrowserRouter>
);

/**
 * This hook can be used in any component inside the HookedBrowserRouter
 * to retrieve route properties like: location
 */
export function useRouter() {
    return useContext(RouterContext);
}

/**
 * This hook can be used in any component inside the HookedBrowserRouter
 * to get the query parameters' Map
 */
export function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
}

export enum Routes {
    ALL = "*",
    HOME = "/",
    TEMPLATES = "/templates",
    AUDIENCES = "/audiences",
    TEMPLATE = "/templates/:templateId/edit",
    AUDIENCE = "/audiences/:audienceId",
    NEW_TEMPLATE = "/templates/new",
    CAMPAIGN = "/campaigns/:campaignId",
    CREATE_CAMPAIGN = "/createCampaign",
    CREATE_AUDIENCE = "/createAudience",
    SIGN = "/sign/:campaignId",
    USER_ACCOUNT = "/user/account",
    COLLABORATORS = "/user/collaborators",
    ACCEPT_COLLABORATOR = "/user/collaborators/accept/:inviteId",
}
