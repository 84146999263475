import {b64ToUtf8} from "@kopjra/uikit";

/**
 * Tries to parse the given base64 string to a javascript object.
 * Returns null if parsing failed.
 */
export function fromBase64<T = any>(base64: string): T | undefined {
  const stringified = b64ToUtf8(base64);
  try {
    return JSON.parse(stringified);
  } catch (_) {
    return undefined;
  }
}
