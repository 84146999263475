import {RootState, ThunkDispatch} from "../types";
import {connect} from "react-redux";
import {AcceptCollaborator, DispatchProps, InnerProps, StateProps} from "../components/AcceptCollaborator";
import {doAcceptInvite} from "../actions/thunks/collaborators";

function mapStateToProps(): StateProps {
    return {};
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onAcceptInvite: (inviteId, code) => dispatch(doAcceptInvite(inviteId, code)),
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(AcceptCollaborator);
