import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {
    doResetCampaign,
    gotCampaign, gotCampaignStats, gotGeneralStats,
} from "../actions/campaigns";
import { CampaignPublishedArea } from "../types/campaignPublishedArea";

const initialState: CampaignPublishedArea = {
};

export const campaignPublishedAreaReducer = createReducer(initialState)
    .handleAction(gotCampaign, (state, action) => update(state, {
        campaign: {$set: action.payload},
    }))
    .handleAction(gotCampaignStats, (state, action) => update(state, {
        campaignStats: {$set: action.payload},
    }))
    .handleAction(gotGeneralStats, (state, action) => update(state, {
        generalStats: {$set: action.payload},
    }))
    .handleAction(doResetCampaign, (state) => update(state, {
        campaign: {$set: undefined},
    }));
