import React, {useEffect, useRef} from "react";
import {TemplateField} from "../types/pdfdoped";
import {WebViewerInstance} from "@pdftron/pdfjs-express";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
    fields?: TemplateField[];
    selectedField?: TemplateField;
    instance?: WebViewerInstance;
    snap: boolean;
    width: number;
    sidebar: boolean;
}

export type Props = StateProps & DispatchProps & InnerProps;

interface Rectangle {
    x: number;
    y: number;
    width: number;
    height: number;
    id: string;
    page: number;
}

interface Point {
    x: number;
    y: number;
    id: string;
}

interface Side {
    p1: Point;
    p2: Point;
    type: "horizontal" | "vertical";
}

const SNAP_DISTANCE = 2.5;
const SNAP_DISTANCE_SMALL = 2.5;

function rectangleSides(rect: Rectangle): Side[] {
    return [
        {p1: {x: rect.x, y: rect.y, id: rect.id}, p2: {x: rect.x + rect.width, y: rect.y, id: rect.id}, type: "horizontal"},
        {p1: {x: rect.x, y: rect.y + rect.height, id: rect.id}, p2: {x: rect.x + rect.width, y: rect.y + rect.height, id: rect.id}, type: "horizontal"},
        {p1: {x: rect.x, y: rect.y, id: rect.id}, p2: {x: rect.x, y: rect.y + rect.height, id: rect.id}, type: "vertical"},
        {p1: {x: rect.x + rect.width, y: rect.y, id: rect.id}, p2: {x: rect.x + rect.width, y: rect.y + rect.height, id: rect.id}, type: "vertical"},
    ];
}

function allRectanglesSides(rectangles: Rectangle[]): Side[] {
    return rectangles.flatMap(rectangleSides);
}

function adjustPoint(point: Point, page: number, instance: WebViewerInstance): Point {
    if (!instance) return point;
    const viewerElement = instance.Core.documentViewer.getScrollViewElement();
    const top = viewerElement.scrollTop;
    const left = viewerElement.scrollLeft;
    const {documentViewer} = instance.Core;

    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
    const pagePoint = {
        x: 0,
        y: 0
    };
    const windowPoint = displayMode.pageToWindow(pagePoint, page);
    const zoom = instance.Core.documentViewer.getPageZoom(page);
    return {
        x: -left + windowPoint.x + point.x * zoom as number,
        y: -top + windowPoint.y + point.y * zoom as number,
        id: point.id,
    };
}

function extractGuide(movingSide: Side, otherSide: Side): Point[] | undefined {
    if (movingSide.type === "horizontal" && otherSide.type === "horizontal") {
        if (Math.abs(movingSide.p1.y - otherSide.p1.y) < SNAP_DISTANCE) {
            return [movingSide.p1, movingSide.p2, otherSide.p1, otherSide.p2].sort((a, b) => a.x - b.x);
        }
    } else if (movingSide.type === "vertical" && otherSide.type === "vertical") {
        if (Math.abs(movingSide.p1.x - otherSide.p1.x) < SNAP_DISTANCE) {
            return [movingSide.p1, movingSide.p2, otherSide.p1, otherSide.p2].sort((a, b) => a.y - b.y);
        }
    }
}

function guideSize(guide: Point[]) {
    return guide[guide.length - 1].x - guide[0].x + guide[guide.length - 1].y - guide[0].y;
}

function guideDirection(guide: Point[]) {
    return guide[0].x === guide[1].x ? "vertical" : "horizontal";
}

function guideKey(guide: Point[]) {
    const direction = guideDirection(guide);
    return `${direction}_${direction === "vertical" ? guide[0].x : guide[0].y}`;
}

function getNotMovingFixedCoordinateElement(guide: Point[], movingId: string) {
    const direction = guideDirection(guide);
    const point = guide.find((point) => point.id !== movingId);
    return direction === "vertical" ? point?.x : point?.y;
}

function getMovingFixedCoordinateElement(guide: Point[], movingId: string) {
    const direction = guideDirection(guide);
    const point = guide.find((point) => point.id === movingId);
    return direction === "vertical" ? point?.x : point?.y;
}

function guideMovingKey(guide: Point[], movingId: string) {
    const direction = guideDirection(guide);
    return `${direction}_${getMovingFixedCoordinateElement(guide, movingId)}`;
}

function cleanGuides(guides: Point[][], movingId: string) {
    const guideMap = new Map<string, Point[]>();
    for (const guide of guides) {
        const key = guideKey(guide);
        if (!guideMap.has(key)) {
            guideMap.set(key, guide);
        } else {
            const existingGuide = guideMap.get(key);
            if (guideSize(guide) < guideSize(existingGuide!)) {
                guideMap.set(key, guide);
            }
        }
    }
    const guideSideMap = new Map<string, Point[]>();
    for (const guide of Array.from(guideMap.values())) {
        const key = guideMovingKey(guide, movingId)
        if (!guideSideMap.has(key)) {
            guideSideMap.set(key, guide);
        } else {
            const existingGuide = guideSideMap.get(key);
            if (guideSize(guide) < guideSize(existingGuide!)) {
                guideSideMap.set(key, guide);
            }
        }
    }
    return Array.from(guideSideMap.values());
}

function rectangleIsResizing(rect: Rectangle, fields: TemplateField[]): boolean {
    const field = fields.find((field) => field.id === rect.id);
    return !!field && (field.rect.width !== rect.width || field.rect.height !== rect.height);
}

function snapMovingAnnotation(movingRectangle: Rectangle, cleanedGuides: Point[][], fields: TemplateField[], instance: WebViewerInstance) {
    const {Core: {annotationManager}} = instance;
    const x1 = movingRectangle.x;
    const y1 = movingRectangle.y;
    const x2 = movingRectangle.x + movingRectangle.width;
    const y2 = movingRectangle.y + movingRectangle.height;
    const coordinateMap = new Map<number, number>();
    for (const guide of cleanedGuides) {
        const direction = guideDirection(guide);
        if (direction === "vertical") {
            const x = getNotMovingFixedCoordinateElement(guide, movingRectangle.id) || 0;
            const xM = getMovingFixedCoordinateElement(guide, movingRectangle.id) || 0;
            if (!coordinateMap.get(xM)) {
                coordinateMap.set(xM, x);
            } else {
                const existingX = coordinateMap.get(xM) || 0;
                if (Math.abs(x - xM) < Math.abs(existingX - xM)) {
                    coordinateMap.set(xM || 0, x || 0);
                }
            }
        } else {
            const y = getNotMovingFixedCoordinateElement(guide, movingRectangle.id) || 0;
            const yM = getMovingFixedCoordinateElement(guide, movingRectangle.id) || 0;
            if (!coordinateMap.get(yM)) {
                coordinateMap.set(yM, y);
            } else {
                const existingY = coordinateMap.get(yM) || 0;
                if (Math.abs(y - yM) < Math.abs(existingY - yM)) {
                    coordinateMap.set(yM || 0, y || 0);
                }
            }
        }
    }

    if (coordinateMap.size === 0) return;

    if (rectangleIsResizing(movingRectangle, fields)) {
        if (coordinateMap.has(x1) && x1 !== coordinateMap.get(x1)) {
            movingRectangle.x = coordinateMap.get(x1) || x1;
            movingRectangle.width = x2 - movingRectangle.x;
        } else if (coordinateMap.has(x2) && x2 !== coordinateMap.get(x2)) {
            movingRectangle.width = (coordinateMap.get(x2) || x2) - movingRectangle.x;
        }
        if (coordinateMap.has(y1) && y1 !== coordinateMap.get(y1)) {
            movingRectangle.y = coordinateMap.get(y1) || y1;
            movingRectangle.height = y2 - movingRectangle.y;
        } else if (coordinateMap.has(y2) && y2 !== coordinateMap.get(y2)) {
            movingRectangle.height = (coordinateMap.get(y2) || y2) - movingRectangle.y;
        }
    } else {
        if (coordinateMap.has(x1) && x1 !== coordinateMap.get(x1)) {
            movingRectangle.x = coordinateMap.get(x1) || x1;
        } else if (coordinateMap.has(x2) && x2 !== coordinateMap.get(x2)) {
            movingRectangle.x = (coordinateMap.get(x2) || x2) - movingRectangle.width;
        }
        if (coordinateMap.has(y1) && y1 !== coordinateMap.get(y1)) {
            movingRectangle.y = coordinateMap.get(y1) || y1;
        } else if (coordinateMap.has(y2) && y2 !== coordinateMap.get(y2)) {
            movingRectangle.y = (coordinateMap.get(y2) || y2) - movingRectangle.height;
        }
    }

    const annotationById = annotationManager.getAnnotationById(movingRectangle.id);
    annotationById.X = movingRectangle.x;
    annotationById.Y = movingRectangle.y;
    annotationById.Width = movingRectangle.width;
    annotationById.Height = movingRectangle.height;

    annotationManager.updateAnnotation(annotationById);
}

function isStraightGuide(guide: Point[]) {
    return (Math.abs(guide[0].x - guide[2].x) < SNAP_DISTANCE_SMALL) ||
        (Math.abs(guide[0].y - guide[2].y) < SNAP_DISTANCE_SMALL);
}

function drawHelpers(canvas: HTMLCanvasElement | null, selectedField: TemplateField | undefined, fields: TemplateField[] | undefined, snap: boolean, instance: WebViewerInstance | undefined) {
    if (!selectedField || !instance || !canvas || !fields) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    ctx.strokeStyle = "#ef615e";
    ctx.lineWidth = 1;

    const rectangles = getRectanglesFromAnnotations(instance);
    const movingRectangle = rectangles.find((rect) => rect.id === selectedField.id);
    const otherRectangles = rectangles.filter((rect) => rect.id !== selectedField.id && rect.page === movingRectangle?.page);

    if (movingRectangle) {
        const sides = allRectanglesSides(otherRectangles);
        const movingSides = rectangleSides(movingRectangle);

        let guides: Point[][] = [];
        for (const side of movingSides) {
            for (const otherSide of sides) {
                const guide = extractGuide(side, otherSide);
                if (guide) {
                    guides.push(guide);
                }
            }
        }
        const cleanedGuides = cleanGuides(guides, movingRectangle.id);
        for (const guide of cleanedGuides) {
            if (isStraightGuide(guide)) {
                const direction = guideDirection(guide);
                const adjustedGuide = guide.map((point) => adjustPoint(point, movingRectangle.page, instance));
                const fixedCoordinate = getNotMovingFixedCoordinateElement(adjustedGuide, movingRectangle.id);
                ctx.beginPath();
                ctx.moveTo((direction === "vertical" && fixedCoordinate) ? fixedCoordinate : adjustedGuide[0].x, (direction === "horizontal" && fixedCoordinate) ? fixedCoordinate : adjustedGuide[0].y);
                ctx.lineTo((direction === "vertical" && fixedCoordinate) ? fixedCoordinate : adjustedGuide[1].x, (direction === "horizontal" && fixedCoordinate) ? fixedCoordinate : adjustedGuide[1].y);
                ctx.lineTo((direction === "vertical" && fixedCoordinate) ? fixedCoordinate : adjustedGuide[2].x, (direction === "horizontal" && fixedCoordinate) ? fixedCoordinate : adjustedGuide[2].y);
                ctx.lineTo((direction === "vertical" && fixedCoordinate) ? fixedCoordinate : adjustedGuide[3].x, (direction === "horizontal" && fixedCoordinate) ? fixedCoordinate : adjustedGuide[3].y);
                ctx.stroke();
                ctx.closePath();
            }
        }

        if (snap) {
            snapMovingAnnotation(movingRectangle, cleanedGuides, fields, instance);
        }
    }
}

function clearCanvas(canvas: HTMLCanvasElement | null) {
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
}

function getRectanglesFromAnnotations(instance: WebViewerInstance): Rectangle[] {
    const {Core: {annotationManager}} = instance;
    const rectangles: Rectangle[] = [];
    const annotations = annotationManager.getAnnotationsList();
    for (const annotation of annotations) {
        rectangles.push({
            x: annotation.X,
            y: annotation.Y,
            width: annotation.Width,
            height: annotation.Height,
            page: annotation.PageNumber,
            id: annotation.Id,
        });
    }
    return rectangles;
}

export const PDFDopedSnapper: React.FC<Props> = ({fields, selectedField, instance, snap, width, sidebar}: Props) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const selectedFieldRef = useRef<TemplateField | undefined>();
    const fieldsRef = useRef<TemplateField[] | undefined>();
    const snapRef = useRef<boolean>(snap);

    function refreshCanvasSize() {
        setTimeout(() => {
            if (canvasRef.current) {
                const elementById = document.getElementById("pdfViewer");
                canvasRef.current.height = elementById?.clientHeight || window.innerHeight;
                canvasRef.current.width = elementById?.clientWidth || window.innerWidth;
            }
        }, 100);
    }

    useEffect(() => {
        refreshCanvasSize();
    }, [width, sidebar]);

    useEffect(() => {
        if (instance) {
            const {Core: {DocumentViewer: {Events}, documentViewer, annotationManager}} = instance;
            documentViewer.addEventListener(Events.MOUSE_MOVE, (e) => {
                if (e.buttons > 0) {
                    drawHelpers(canvasRef.current, selectedFieldRef.current, fieldsRef.current, snapRef.current, instance);
                } else {
                    const annotation = annotationManager.getAnnotationByMouseEvent(e);
                    const field = fieldsRef.current?.find((f) => f.id === annotation?.Id);
                    if (field) {
                        drawHelpers(canvasRef.current, field, fieldsRef.current, false, instance)
                    } else {
                        setTimeout(() => clearCanvas(canvasRef.current), 1000);
                    }
                }
            });

            documentViewer.addEventListener(Events.MOUSE_LEFT_UP, () => {
                setTimeout(() => clearCanvas(canvasRef.current), 1000);
            });
        }
    }, [instance]);

    useEffect(() => {
        selectedFieldRef.current = selectedField;
        fieldsRef.current = fields;
        // drawRectangle(canvasRef.current, selectedFieldRef.current, instance);
    }, [fields, selectedField, instance]);

    useEffect(() => {
        snapRef.current = snap;
    }, [snap]);

    return <canvas id="snapCanvas" ref={canvasRef} />;
}
