import { GetParams } from "@kopjra/uikit";
import { connect } from "react-redux";
import { resetCurAudience } from "../actions/audiences";
import {doGetAudiences, doDeleteAudiences} from "../actions/thunks/audiences";
import { DispatchProps, StateProps, InnerProps, Audiences } from "../components/Audiences";
import { RootState, ThunkDispatch } from "../types";
import { routerTools } from "../utils/router";
import {resetNewAudience} from "../actions/audienceCreation";


function mapStateToProps({ audiences, i18n }: RootState): StateProps {
    return {
        locale: i18n.locale,
        audiences: audiences.list,
        total: audiences.total
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetAudiences: (query: GetParams) => dispatch(doGetAudiences(query)),
        onOpenAudience: (audienceId: string) => {
            dispatch(resetCurAudience());
            routerTools.push(`/audiences/${audienceId}`);
        },
        onCreateAudience: () => {
            dispatch(resetNewAudience());
            routerTools.push(`/createAudience`);
        },
        onDeleteAudiences: (audienceIds: string[], deleteAll: boolean, queryParams?: GetParams) => dispatch(doDeleteAudiences(audienceIds, deleteAll, queryParams))
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Audiences);
