import * as React from "react";
import {useEffect, useState} from "react";
import {confirmBox, KContainer, KGlobalSpinner} from "@kopjra/uikit";
import {Campaign, CampaignBaseInfo, CampaignType} from "../types/campaigns";
import {CampaignInfo} from "./CampaignInfo";
import {Audience} from "./Audience";
import {AudienceData, DocumentData} from "../types/campaignCreation";
import {I18n, Translate} from "react-redux-i18n";
import {CampaignPublishedMessage} from "./CampaignPublishedMessage";
import {sendAlert} from "../utils/commons";
import TemplateChoose from "../containers/TemplateChoose";


export interface StateProps {
    campaignInfo?: CampaignBaseInfo;
    documentData?: DocumentData;
    audienceData?: AudienceData;
    campaignCreated?: Campaign;
}

export interface DispatchProps {
    onGetAvailableTemplates: () => Promise<void>;
    onGetAvailableAudiences: () => Promise<void>;
    onSetCampaignInfo: (campaignInfo: CampaignBaseInfo) => void;
    onSetDocumentData: (documentData: DocumentData) => void;
    onSetAudienceData: (audienceData: AudienceData) => void;
    onCreateCampaign: () => Promise<void>;
    onReturnHome: () => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

enum STEPS { "campaignInfo", "audience", "template", "published" }

const backMap = {
    [STEPS.campaignInfo]: undefined,
    [STEPS.template]: STEPS.audience,
    [STEPS.audience]: STEPS.campaignInfo,
};

export const CampaignCreation: React.FC<Props> = (
    {
        campaignInfo, documentData, audienceData,
        campaignCreated, onGetAvailableTemplates, onGetAvailableAudiences,
        onSetCampaignInfo, onSetDocumentData, onSetAudienceData, onCreateCampaign, onReturnHome
    }) => {
    const [step, setStep] = useState(STEPS.campaignInfo);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        onGetAvailableTemplates().catch((e) => console.warn("Get available templates error", e));
        onGetAvailableAudiences().catch((e) => console.warn("Get available audiences error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const unloadListener = (ev: BeforeUnloadEvent) => {
            ev.preventDefault();
            return ev.returnValue = "";
        };
        window.addEventListener("beforeunload", unloadListener);
        return () => {
            window.removeEventListener("beforeunload", unloadListener);
        }
    }, []);

    function renderComponent(step: STEPS): JSX.Element {
        let component;
        switch (step) {
            case STEPS.campaignInfo:
                component = <CampaignInfo
                    campaignInfo={campaignInfo}
                    onSubmit={(campaignInfo) => {
                        onSetCampaignInfo(campaignInfo);
                        setStep(STEPS.audience);
                    }}
                    onBack={async () => {
                        if (await confirmBox({
                            message: I18n.t("campaigns.creationBack"),
                            yesText: I18n.t("generic.yes"),
                            noText: I18n.t("generic.no"),
                        })) {
                            window.history.back();
                        }
                    }}
                />;
                break;
            case STEPS.audience:
                component = <Audience
                    audienceData={audienceData} campaignType={campaignInfo?.type || CampaignType.OPEN}
                    requireOTP={campaignInfo?.requireOTP || false}
                    onSubmit={(audienceData: AudienceData) => {
                        if (campaignInfo?.type === CampaignType.MULTI) {
                            audienceData.signers?.forEach((signer, index) => {
                                signer.signerIndex = index + 1;
                                if (!signer.internalId) {
                                    signer.internalId = index + 1;
                                }
                            });
                        } else {
                            audienceData.signers?.forEach(signer => signer.signerIndex = undefined);
                        }
                        onSetAudienceData(audienceData);
                        setStep(STEPS.template);
                    }}
                    onBack={(audienceData: AudienceData) => {
                        onSetAudienceData(audienceData);
                        setStep(backMap[step]);
                    }}/>;
                break;
            case STEPS.template:
                component = <TemplateChoose
                    onSubmit={async (documentData: DocumentData) => {
                        const proceed = await confirmBox({
                            message: I18n.t("campaignCreation.published.proceed"), size: "large",
                            yesText: I18n.t("generic.yes"),
                            noText: I18n.t("generic.no"),
                        });
                        if (proceed) {
                            onSetDocumentData(documentData);
                            setSaving(true);
                            try {
                                await onCreateCampaign();
                                setStep(STEPS.published)
                            } catch (error) {
                                sendAlert(I18n.t("campaigns.createError"), "error");
                                console.error("Error creating campaign", error);
                            } finally {
                                setSaving(false);
                            }
                        }
                    }}
                    onBack={(documentData: DocumentData) => {onSetDocumentData(documentData); setStep(backMap[step])}} />;
                break;
            case STEPS.published:
                component = <CampaignPublishedMessage campaignName={campaignInfo?.name || ""} campaignPublicURL={campaignCreated?.publicUrl} onReturnHome={onReturnHome}/>
                break;
            default:
                component = <></>;
                break;
        }
        return component;
    }

    return (
        <KContainer screenMinWidth={900}>
            {renderComponent(step)}
            <KGlobalSpinner show={saving} text={<Translate value={"campaigns.creating"}/>}/>
        </KContainer>
    );
}
