import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import {
    KButton,
    KContainer, KInput, KSpace,
} from "@kopjra/uikit";


export type CampaignPublishedMessageProps = {
    campaignName: string;
    campaignPublicURL?: string;
    onReturnHome: () => void;
};

export const CampaignPublishedMessage: React.FC<CampaignPublishedMessageProps> = ({campaignName, campaignPublicURL, onReturnHome}) => {

    return (
        <>
            <KContainer>
                <Row>
                    <Col md={3}/>
                    <Col className={"text-end"} md={1}><span style={{color: "#2bd18f"}}><i className="fal fa-check-circle fa-4x"/></span></Col>
                    <Col className={"text-start"}><h1><Translate value={"campaignCreation.published.title"}/></h1></Col>
                    <Col md={3}/>
                </Row>
                <KSpace spaces={2} />
                <Row><Col><h4>{`${I18n.t("campaignCreation.published.message1")} "${campaignName}" ${I18n.t("campaignCreation.published.message2")}`}</h4></Col></Row>
                {campaignPublicURL ? (
                    <>
                        <Row><Col><h4><Translate value={"campaignCreation.published.publicURL"}/></h4></Col></Row>
                        <Row><Col><KInput readOnly={true} id={"publicLink"} defaultValue={campaignPublicURL} copyButton onClick={() => navigator.clipboard.writeText(campaignPublicURL)}/></Col></Row>
                        <KSpace spaces={2} />
                    </>
                ) : (
                    <></>
                )}
                <Row><Col><h4><Translate value={"campaignCreation.published.track"}/></h4></Col></Row>
                <KSpace spaces={2} />
                <Row><Col className={"text-center"}><KButton text={<Translate value={"campaignCreation.published.returnToCampaigns"}/>} onClick={onReturnHome}/></Col></Row>
            </KContainer>
        </>
    );
}
