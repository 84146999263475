import {extractGetParamsFromState, GetParams, KAlertType, setAlert} from "@kopjra/uikit";
import { store } from "../index";
import {I18n} from "react-redux-i18n";
import {TemplateFieldType} from "../types/pdfdoped";
import {useLayoutEffect, useState} from "react";


export function createURLSearchParams(obj: any): URLSearchParams {
    const usp = new URLSearchParams();
    for (const key of Object.keys(obj)) {
        if (obj[key] !== undefined && obj[key] !== null) {
            usp.append(key, obj[key]);
        }
    }
    return usp;
}

export const COLORS = new Map([
    [TemplateFieldType.SIGNATURE, "#2BD18F"],
    [TemplateFieldType.CONDITIONAL_SIGNATURE, "#B084CC"],
    [TemplateFieldType.CUSTOM, "#f5922f"],
    ["DEFAULT", "#5198f4"],
]);

export const ICONS: Record<TemplateFieldType, [string, string]> = {
    [TemplateFieldType.EMAIL]: ["\ue10c", "fa-circle-envelope"],
    [TemplateFieldType.FULLNAME]: ["\uf2bd", "fa-circle-user"],
    [TemplateFieldType.PHONE]: ["\ue11b", "fa-circle-phone"],
    [TemplateFieldType.DATE]: ["\ue102", "fa-circle-calendar"],
    [TemplateFieldType.SIGNATURE]: ["\uf5b7", "fa-signature"],
    [TemplateFieldType.CONDITIONAL_SIGNATURE]: ["\ue3ca", "fa-signature-lock"],
    [TemplateFieldType.CUSTOM]: ["\ue20e", "fa-pen-circle"],
};

export function translatedType(type: TemplateFieldType): string {
    return I18n.t(`pdfViewer.${type}`);
}

export function isNumeric(str: string | number) {
    return Number.isFinite(+str) && str;
}

export function getFieldColor(type: string): string {
    return COLORS.get(type !== TemplateFieldType.SIGNATURE && type !== TemplateFieldType.CUSTOM && type !== TemplateFieldType.CONDITIONAL_SIGNATURE ? "DEFAULT" : type) as string;
}

export function tableGetParams(id: string): GetParams {
    return extractGetParamsFromState(store.getState(), id);
}

export interface List<T> {
    list: T[];
    total: number;
}

export function createLinkToDownload(name: string, url: string) {
    const link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("download", `${name}`);
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export interface ApiResponse<T> {
    object: T | undefined;
    statusCode: number;
}

export function sendAlert(message: string, type: KAlertType) {
    store.dispatch(setAlert(message, type));
}

export function hexToRgb(hex: string): {r: number, g: number, b: number} | undefined {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : undefined;
}

export const emailRegex = "^(\\s*?)(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])(\\s*?)$";

export function scrollTop() {
    window.scrollTo(0, 0);
}

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
