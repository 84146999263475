import {ThunkResult} from "../../types";
import {Dispatch} from "redux";
import {RootAction} from "../index";
import {createdSign, gotPublicCampaign, gotSign} from "../campaignSign";
import {patchPublicSign, getPublicSign, createPublicSign} from "../../api/signatures";
import { Signer } from "../../types/campaigns";
import {getPublicCampaign} from "../../api/campaigns";
import {alertBox} from "@kopjra/uikit";
import {I18n} from "react-redux-i18n";


export const doGetSign = (campaignId: string, campaignCode: string, signId: string, signCode: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const sign = await getPublicSign(campaignId, campaignCode, signId, signCode);
    if (sign) {
        // lo setto così per evitare che mi arrivi un fUrl non valido che mi sminchia l'effect hook nella componente
        sign.fUrl = undefined;
        dispatch(gotSign(sign));
    }
};

export const doGetPublicCampaign = (campaignId: string, campaignCode: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const campaign = await getPublicCampaign(campaignId, campaignCode);
    if (campaign) {
        dispatch(gotPublicCampaign(campaign));
    }
};

export const doCreateSign = (campaignId: string, campaignCode: string, signer: Signer): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const apiRes = await createPublicSign(campaignId, campaignCode, signer);
    let errorPointer: string = "";
    switch (apiRes.statusCode) {
        case 200:
            if (apiRes?.object) {
                dispatch(createdSign(apiRes.object));
            }
            break;
        case 402:
            errorPointer = "alerts.errors.noCredits";
            break;
        case 403:
            errorPointer = "alerts.errors.signatureSameEmail";
            break;
        case 404:
            errorPointer = "alerts.errors.campaignNotFound";
            break;
        case 409:
            errorPointer = "alerts.errors.expiredCampaign";
            break;
        default:
            errorPointer = "alerts.errors.genericError";
            break;
    }
    if (apiRes.statusCode !== 200) {
        await alertBox({
            size: "large",
            message: I18n.t(errorPointer)
        });
        window.location.reload();
    }
}

export const doPatchSign = (campaignId: string, campaignCode: string, signId: string, signCode: string, signer: Signer): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const apiRes = await patchPublicSign(campaignId, campaignCode, signId, signCode, signer);
    let errorPointer: string = "";
    switch (apiRes.statusCode) {
        case 200:
            if (apiRes?.object?.fUrl) {
                dispatch(createdSign(apiRes.object));
            }
            break;
        case 402:
            errorPointer = "alerts.errors.noCredits";
            break;
        case 404:
            errorPointer = "alerts.errors.campaignNotFound";
            break;
        case 409:
            errorPointer = "alerts.errors.expiredCampaign";
            break;
        case 410:
            errorPointer = "alerts.errors.noSignatureClosedCampaign";
            break;
        default:
            errorPointer = "alerts.errors.genericError";
            break;
    }
    if (apiRes.statusCode !== 200) {
        await alertBox({
            size: "large",
            message: I18n.t(errorPointer)
        });
        window.location.reload();
    }
};
