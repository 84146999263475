import {GetParams, ParamFilter, SortingDirection} from "@kopjra/uikit";
import {Signer} from "../types/signers";
import {List} from "../utils/commons";
import {apiCall, HttpMethod} from "./index";


export function transformToSignerParams(query: GetParams): GetSignerParams {
    const result: GetSignerParams = {
        top: query.top,
        skip: query.skip,
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const fullnamePf = query.filter.find((pf: ParamFilter) => (pf.name === "fullName"));
        result.fullName = fullnamePf ? fullnamePf.value as string : undefined;
        const emailPf = query.filter.find((pf: ParamFilter) => (pf.name === "email"));
        result.email = emailPf ? emailPf.value as string : undefined;
        const phonePf = query.filter.find((pf: ParamFilter) => (pf.name === "phoneNumber"));
        result.phoneNumber = phonePf ? phonePf.value as string : undefined;
    }
    return result;
}

function transformToSigner(obj: any): Signer {
    const sign: Signer = {
        id: obj.id,
        createdAt: obj.createdAt,
        email: obj.email,
        fullName: obj.fullName,
        phoneNumber: obj.phoneNumber,
        signerIndex: obj.signerIndex
    };
    return sign;
}

export interface GetSignerParams extends GetParams {
    fullName?: string;
    phoneNumber?: string;
    email?: string;
}

export async function getSigners(audienceId: string, query: GetParams): Promise<List<Signer>> {
    const list: List<Signer> = {list: [], total: 0};
    const res = await apiCall({urlComponent: `/audiences/${audienceId}/signers`, query});
    if (res.status < 300) {
        const json = await res.json();
        list.list = await json.items.map((o: any) => transformToSigner(o));
        list.total = json.total;
    }
    return list;
}

export async function getAllSigners(audienceId: string): Promise<Signer[]> {
    const res = await apiCall({urlComponent: `/audiences/${audienceId}/signers/all`});
    if (res.status < 300) {
        const json = await res.json();
        return await json.map((o: any) => transformToSigner(o));
    }
    return [];
}

export async function saveSigners(audienceId: string, signers: Signer[]): Promise<boolean> {
    const fixedSigners = signers.map(s => {
        return {fullName: s.fullName, email: s.email, phoneNumber: s.phoneNumber || undefined};
    })
    const res = await apiCall({
        urlComponent: `/audiences/${audienceId}/signers`,
        method: HttpMethod.POST,
        body: fixedSigners
    });
    return res.status < 300;
}

export async function deleteSigners(audienceId: string, signerIds: string[], deleteAll: boolean, queryParams?: GetParams): Promise<boolean> {
    const convertedParams = queryParams?.filter ? transformToSignerParams(queryParams) : undefined;
    if (convertedParams) {
        delete convertedParams.top;
        delete convertedParams.skip;
    }
    const body = {ids: signerIds, deleteAll, filter: convertedParams};
    const res = await apiCall({urlComponent: `/audiences/${audienceId}/signers`, method: HttpMethod.DELETE, body});
    return res.status < 300;
}
