import {ThunkResult} from "../../types";
import {Dispatch} from "redux";
import {RootAction} from "../index";
import {saveAudience} from "../../api/audiences";
import {saveSigners} from "../../api/signers";
import {chunkArray} from "./signatures";


export const doCreateAudience = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>, getState) => {
    const audienceCreationData = getState().audienceCreation;
    const createdAudience = await saveAudience(audienceCreationData.audienceInfo || {name: "", signers: []});
    if (createdAudience) {
        const chunks = chunkArray(audienceCreationData.audienceInfo?.signers || [], 100);
        for (const signerChunk of chunks) {
            await saveSigners(createdAudience.id, signerChunk);
        }
    }
}
