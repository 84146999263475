import {RootState, ThunkDispatch} from "../types";
import {connect} from "react-redux";
import {Collaborators, DispatchProps, InnerProps, StateProps} from "../components/Collaborators";
import {doGetCollaborators, doInviteCollaborators, doRemoveCollaborator} from "../actions/thunks/collaborators";

function mapStateToProps({ collaborators }: RootState): StateProps {
    return {
        owner: collaborators.owner || undefined,
        collaborators: collaborators.results === null ? undefined : collaborators.results.list,
        totalCollaborators: collaborators.results === null ? 0 : collaborators.results.total,
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCollaborators: (q) => dispatch(doGetCollaborators(q)),
        onInviteCollaborators: (emails) => dispatch(doInviteCollaborators(emails)),
        onDeleteInvite: (id: string) => dispatch(doRemoveCollaborator(id)),
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Collaborators);
