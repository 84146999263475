import {Template} from "./templates";
import {TemplateField} from "./pdfdoped";


export enum CampaignStatus {
  "NEW" = "NEW",
  "ACTIVE" = "ACTIVE",
  "PAUSED" = "PAUSED",
  "FINISHED" = "FINISHED",
  "EXPIRED" = "EXPIRED"
}

export enum CampaignType {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  MULTI = "MULTI",
}

export interface CustomLogo {
  base64: string;
  mime: string;
}

export interface CampaignBaseInfo {
  name: string;
  fromName: string;
  fromEmail: string;
  type: string;
  expirationDate?: string;
  customLogo?: CustomLogo;
  requireOTP: boolean;
}

export interface Signer {
  fullName: string;
  email: string;
  phoneNumber?: string;
  signType?: string;
  signFont?: string;
  signImageBase64?: string;
  signerIndex?: number;
  internalId: number;
}

export interface Campaign {
  id: string
  code: string;
  name: string
  type: string
  companyName: string
  campaignEmail: string
  status: string
  template: Template
  require2fa: boolean
  expirationDate?: Date
  customLogoFileLocation?: any;
  signaturesSent: number
  signaturesSigned: number
  createdAt: Date;
  publicUrl: string;
  documents?: {id: string; name: string, fields: TemplateField[]}[];
}

export interface Campaigns {
  list?: Campaign[];
  total: number;
}
