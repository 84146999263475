import { createAction } from "typesafe-actions";
import { Campaign, Campaigns } from "../types/campaigns";
import {CampaignStats, Stats} from "../types/stats";

export const gotCampaign = createAction("campaigns/GOT_CAMPAIGN", (campaign: Campaign) => campaign)();
export const gotCampaigns = createAction("campaigns/GOT_CAMPAIGNS", (campaigns: Campaigns) => campaigns)();
export const doResetCampaign = createAction("campaigns/RESET_CAMPAIGN")();
export const cancelCampaign = createAction("campaign/DELETE", (campaignId: string) => campaignId)();
export const gotCampaignStats = createAction("campaigns/GOT_CAMPAIGN_STATS", (campaignStats: CampaignStats) => campaignStats)();
export const gotGeneralStats = createAction("campaigns/GOT_GENERAL_STATS", (stats: Stats) => stats)();
