import { GetParams, ParamFilter, SortingDirection } from "@kopjra/uikit";
import { CampaignCreation } from "../types/campaignCreation";
import {Campaign, CampaignBaseInfo, CampaignStatus, Signer} from "../types/campaigns";
import { NewTemplate } from "../types/templates";
import { List } from "../utils/commons";
import { BE_ENDPOINT } from "../utils/constants";
import { apiCall, HttpMethod } from "./index";
import {saveSigners} from "./signatures";
import { saveDocument, saveDocumentFile, saveTemplate, saveTemplateFile } from "./templates";
import {Audience} from "../types/audiences";

export function transformToCampaignParams(query: GetParams): GetCampaignParams {
    const result: GetCampaignParams = {
        top: query.top,
        skip: query.skip,
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const namePf = query.filter.find((pf: ParamFilter) => (pf.name === "name"));
        result.name = namePf ? namePf.value as string : undefined;
        const statusPf = query.filter.find((pf: ParamFilter) => (pf.name === "status"));
        result.status = statusPf ? statusPf.value as string : undefined;
        const typePf = query.filter.find((pf: ParamFilter) => (pf.name === "type"));
        result.type = typePf ? typePf.value as string : undefined;
    }
    return result;
}

function transformToCampaign(obj: any): Campaign {
    const campaign: Campaign = {
        id: obj.id,
        createdAt: new Date(obj.createdAt),
        name: obj.name,
        type: obj.type,
        companyName: obj.companyName,
        campaignEmail: obj.campaignEmail,
        status: obj.status,
        template: obj.template,
        require2fa: obj.require2FA,
        customLogoFileLocation: obj.customLogoFileLocation,
        expirationDate: obj.expirationDate ? new Date(obj.expirationDate) : undefined,
        signaturesSent: obj.signaturesSent,
        signaturesSigned: obj.signaturesSigned,
        code: obj.code,
        publicUrl: obj.publicUrl,
        documents: obj.documents,
    };
    return campaign;
}

export interface GetCampaignParams extends GetParams {
    name?: string;
    status?: string;
    type?: string;
}

export async function getCampaigns(query: GetParams): Promise<List<Campaign>> {
    const list: List<Campaign> = {list: [], total: 0};
    const res = await apiCall({ urlComponent: "/campaigns", query });
    const status = res.status;
    if (status < 300) {
        const json = await res.json();
        const campaigns = await json.items.map((o: any) => transformToCampaign(o));
        list.list = campaigns;
        list.total = json.total;
    }
    return list;
}

export async function getCampaign(id: string): Promise<Campaign | undefined> {
    const res = await apiCall({ urlComponent: `/campaigns/${id}` });
    if (res.status < 300) {
        const json = await res.json();

        const resDocs = await apiCall({urlComponent: `/campaigns/${id}/documents`, query: {top: 30}});
        let documents: {id: string, name: string}[] | undefined = undefined;
        if (resDocs.status < 300) {
            const jsonDocs = await resDocs.json();
            documents = jsonDocs.items.map((o: any) => ({id: o.id, name: o.name}));
        }
        return transformToCampaign({...json, documents});
    }
    return undefined;
}

export async function getPublicCampaign(id: string, code: string): Promise<Campaign | undefined> {
    const url = new URL(`${BE_ENDPOINT}/public/campaigns/${id}`);
    url.searchParams.set("code", code);
    const res = await fetch(url.toString(), {credentials: "include"});
    if (res.status < 300) {
        const json = await res.json();
        return transformToCampaign(json);
    }
    return undefined;
}

async function createBaseCampaign(campaignInfo: CampaignBaseInfo, selectedAudience?: Audience): Promise<Campaign | undefined> {
    const body = {
        name: campaignInfo.name,
        campaignEmail: campaignInfo.fromEmail,
        companyName: campaignInfo.fromName,
        require2FA: campaignInfo.requireOTP,
        customLogo: campaignInfo.customLogo,
        type: campaignInfo.type,
        expirationDate: campaignInfo.expirationDate || null,
        legalTerms: [],
        audienceId: selectedAudience?.id
    };
    const res = await apiCall({ urlComponent: `/campaigns`, method: HttpMethod.POST, body });
    if (res.status < 300) {
        const json = await res.json();
        return transformToCampaign(json);
    }
    return undefined;
}

export async function createCampaign(campaignCreationData: CampaignCreation): Promise<Campaign | undefined> {
    const createdCampaign = await createBaseCampaign(campaignCreationData.campaignInfo as CampaignBaseInfo, campaignCreationData.audienceData?.selectedAudience);
    if (createdCampaign) {
        let filename = campaignCreationData.documentData?.selectedTemplate ? campaignCreationData.documentData.selectedTemplate.pdfFilename : campaignCreationData.documentData!.file!.name;
        const newTemplate: NewTemplate = {
            name: campaignCreationData.documentData!.newTemplateName || campaignCreationData.documentData!.selectedTemplate?.name || filename,
            fields: campaignCreationData.documentData!.fields!,
            file: campaignCreationData.documentData!.file!,
            filename,
            pageMatrices: campaignCreationData.documentData!.pageMatrices!,
            isNew: false
        };
        if (campaignCreationData.documentData!.saveAsNew) {
            const savedTemplate = await saveTemplate(newTemplate);
            if (savedTemplate) {
                await saveTemplateFile(savedTemplate.id, newTemplate.file);
            }
        }
        const savedDocument = await saveDocument(createdCampaign.id, newTemplate);
        if (savedDocument) {
            await saveDocumentFile(createdCampaign.id, savedDocument.id, newTemplate.file);
        }
        if (campaignCreationData.audienceData!.signers!.length > 0) {
            await saveAudience(createdCampaign.id, campaignCreationData.audienceData!.signers!);
        }
        await changeCampaignStatus(createdCampaign.id, CampaignStatus.ACTIVE);
        return createdCampaign;
    }
    return undefined;
}

function chunkArray(arr: Array<Signer>, chunkSize: number): Array<Array<Signer>> {
    const chunks: Array<Array<Signer>> = [];
    const secureArr = Array.from(arr);
    while (secureArr.length) {
        chunks.push(secureArr.splice(0, chunkSize));
    }
    return chunks;
}

async function saveAudience(campaignId: string, signers: Signer[]): Promise<void> {
    const signerChunks = chunkArray(signers, 100);
    for (const signerChunk of signerChunks) {
        await saveSigners(campaignId, signerChunk);
    }
}
export async function deleteCampaigns(campaignIds: string[], deleteAll: boolean, queryParams?: GetParams): Promise<boolean> {
    const convertedParams = queryParams?.filter ? transformToCampaignParams(queryParams) : undefined;
    if (convertedParams) {
        delete convertedParams.top;
        delete convertedParams.skip;
    }
    const body = {ids: campaignIds, deleteAll, filter: convertedParams};
    const res = await apiCall({urlComponent: `/campaigns`, method: HttpMethod.DELETE, body});
    return res.status < 300;
}

export async function changeCampaignStatus(campaignId: string, newStatus: string): Promise<boolean> {
    const body = [{op:"replace", path: "/status", value: newStatus}];
    const res = await apiCall({urlComponent: `/campaigns/${campaignId}`, method: HttpMethod.PATCH, body});
    return res.status < 300;
}

export async function changeExpirationDate(campaignId: string, date: Date | undefined): Promise<boolean> {
    const body = [{op:"replace", path: "/expirationDate", value: date || null}];
    const res = await apiCall({urlComponent: `/campaigns/${campaignId}`, method: HttpMethod.PATCH, contentType: "application/json-patch+json", body});
    return res.status < 300;
}
