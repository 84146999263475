import {customAlphabet} from "nanoid";

const ALPHABET = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
const SIZE = 10

/**
 * Function to generate a GUID.
 */
export function guid(size = SIZE + 1) {
  const nanoid = customAlphabet(ALPHABET, SIZE);
  return nanoid();
}
