import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {KButton, KCard, KContainer, KSpace, KSpinner, KSpinnerSize} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";
import {useEffect} from "react";
import {Stats} from "../types/stats";
import {KCredit} from "./KCredit";
import Campaigns from "../containers/Campaigns";


export interface StateProps {
    stats?: Stats;
}

export interface DispatchProps {
    onGetStats: () => Promise<void>;
    onAddCampaign: () => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const CampaignsArea: React.FC<Props> = ({stats, onGetStats, onAddCampaign}) => {
    useEffect(() => {
        onGetStats().catch((e) => console.warn("Get stats error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <KContainer>
            <KCard header={<Translate value={"campaigns.title"}/>}>
                {stats && stats.available > -1 ? (
                    <>
                        <Row>
                            <Col><KCredit remaining={stats.available} total={stats.subscribed} infinite={false}
                                          labelText={<Translate value="credits.remaining"/>}/></Col>
                            <Col><KCredit remaining={stats.extra} infinite={false}
                                          labelText={<Translate value="credits.extra"/>}/></Col>
                            <Col><KCredit remaining={stats.activeCampaigns} infinite={false}
                                          labelText={<Translate value="stats.active"/>}/></Col>
                            <Col><KCredit remaining={Math.trunc((stats.totalSigned / stats.totalSigners) * 100)}
                                          infinite={false} colored percent labelText={<Translate value="stats.rate"/>}/></Col>
                        </Row>
                        <KSpace spaces={2}/>
                        <Row><Col>
                            {stats.isAllowed
                                ? <KButton text={<><i className="fal fa-plus"/>&nbsp;<Translate value={"campaigns.addCampaign"}/></>} onClick={() => onAddCampaign()}/>
                                : <KButton text={<><i className="fal fa-cart-plus"/>&nbsp;<Translate value={"credits.buy"}/></>} onClick={() => new Promise(() => {window.open("https://store.kopjra.com/", "_blank")})}/>
                            }
                        </Col></Row>
                    </>
                ) : (
                    <KSpinner size={KSpinnerSize.xl}/>
                )}
                <KSpace spaces={2}/>
                <Campaigns/>
            </KCard>
        </KContainer>
    );
};
