import {TemplateField, TemplateFieldType} from "../types/pdfdoped";
import {COLORS, ICONS} from "../utils/commons";
import {I18n, Translate} from "react-redux-i18n";
import {confirmBox, KCheck, KInput, KInputHintVariant, KSelect, KSELECT_SIZE, KSpace} from "@kopjra/uikit";
import {Col, Form, Row} from "react-bootstrap";
import {JSX, useEffect, useRef} from "react";
import {Signer} from "../types/signers";

export interface TemplateFieldEditorProps {
    field: TemplateField,
    signers?: Signer[],
    triedToClose: boolean,
    ignoreSigners?: boolean,
    campaignType?: string,
    onChange: (field: TemplateField) => void,
    onDelete: (field: TemplateField) => void,
    onSetIsValid: (isValid: boolean) => void,
    onSetLastSigner: (email?: string) => void,
    fields?: TemplateField[]
}

export const TemplateFieldEditor: React.FC<TemplateFieldEditorProps> = ({
                                                                            campaignType,
                                                                            ignoreSigners = true,
                                                                            onSetLastSigner,
                                                                            signers,
                                                                            onSetIsValid,
                                                                            triedToClose,
                                                                            field,
                                                                            onDelete,
                                                                            onChange,
                                                                            fields,
                                                                        }) => {
    const multipleSelection = fields && fields.length > 1;
    const iconClass = multipleSelection ? "fa-files" : ICONS[field.type][1];
    const iconColor = multipleSelection ? "#4e596b" : COLORS.get(field.type) || COLORS.get("DEFAULT") || "";
    const selectedSigner = signers?.find(s => s.email === field.signerEmail);
    const fieldTypes = Object.values(TemplateFieldType).map((type) => {
        if (type !== TemplateFieldType.PHONE) {
            return {value: type, label: <Translate value={`pdfViewer.${type}`}/>};
        } else {
            return {
                value: type,
                label: <Translate value={`pdfViewer.${type}`}/>,
                isDisabled: campaignType === "MULTI" && !selectedSigner?.phoneNumber
            };
        }
    });
    const formRef = useRef<HTMLFormElement>(null);
    useEffect(() => {
        if (formRef.current) {
            onSetIsValid(formRef.current.checkValidity());
        }
    }, [formRef, onSetIsValid]);
    return (<>
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
                <div className="mx-1 template-field-editor-icon"><i className={`fa ${iconClass}`}
                                                                    style={{color: iconColor}}/></div>
                <div className="mx-1 template-field-editor-title">{multipleSelection ? <Translate value={`pdfViewer.multipleSelection`}/> : <Translate value={`pdfViewer.${field.type}`}/>}</div>
            </div>
            <div>
                <i className="mx-2 fal fa-trash template-field-editor-action danger" onClick={async () => {
                    if (await confirmBox({
                        message: multipleSelection ? I18n.t("pdfSidebar.deleteFieldsConfirmation") : I18n.t("pdfSidebar.deleteFieldConfirmation"),
                        yesText: I18n.t("generic.yes"),
                        noText: I18n.t("generic.no"),
                    })) {
                        if (multipleSelection) {
                            fields?.forEach(f => onDelete(f));
                        } else {
                            onDelete(field);
                        }
                    }
                }}/>
            </div>
        </div>
        <KSpace spaces={1}/>
        <Form ref={formRef} validated={triedToClose} onChange={(e) => {
            onSetIsValid(e.currentTarget.checkValidity());
        }}>
            {field.type === TemplateFieldType.CUSTOM && !multipleSelection ? (
                <Row style={{paddingLeft: 25, paddingRight: 15}}>
                    <Col>
                        <KInput
                            id={`name-${field.id}`} label={<Translate value={"pdfSidebar.fieldName"}/>}
                            value={field.name} required={true}
                            onChange={(val) => onChange({...field, name: val.target.value})}
                            errorMessage={new Map<string, string | JSX.Element>(
                                [
                                    ["required", <Translate value={"pdfSidebar.requiredError"}/>],
                                    ["validate", <Translate value={"pdfSidebar.fieldNameValidateError"}/>]
                                ]
                            )}
                            forceErrorMessageBellow={new Map<string, boolean>([["required", false], ["validate", true]])}
                            validate={val => new RegExp("^[a-zA-Z0-9]{2,}[a-zA-Z0-9 \\-()]+$").test(val)}
                        />
                    </Col>
                </Row>
            ) : null}
            {field.type === TemplateFieldType.CONDITIONAL_SIGNATURE && !multipleSelection ? (
                <Row style={{paddingLeft: 25, paddingRight: 15}}>
                    <Col>
                        <KInput
                            id={`description-${field.id}`} label={<Translate value={"pdfSidebar.fieldDescription"}/>}
                            value={field.description} required={true}
                            onChange={(val) => onChange({...field, description: val.target.value})}
                            errorMessage={new Map<string, string | JSX.Element>(
                                [
                                    ["required", <Translate value={"pdfSidebar.requiredError"}/>],
                                    ["validate", <Translate value={"pdfSidebar.fieldDescriptionValidateError"}/>]
                                ]
                            )}
                            validate={val => new RegExp("^.{3,}$").test(val)}
                            forceErrorMessageBellow={new Map<string, boolean>([["required", false], ["validate", false]])}
                        />
                    </Col>
                </Row>
            ) : null}
            {!multipleSelection ? (
                <Row style={{paddingLeft: 25, paddingRight: 15}}>
                    <Col>
                        <KSelect<TemplateFieldType>
                            label={<Translate value={"pdfSidebar.fieldType"}/>}
                            id={`type-${field.id}`} options={fieldTypes} size={KSELECT_SIZE.lg}
                            value={field.type}
                            onChange={(val) => {
                                if (val !== field.type) {
                                    onChange({
                                        ...field,
                                        type: val as TemplateFieldType,
                                        name: undefined,
                                        description: undefined
                                    });
                                }
                            }}
                            hint={campaignType === "MULTI" ? <Translate value={`pdfSidebar.fieldTypeInfo`}/> : <></>}
                            hintVariant={KInputHintVariant.DEFAULT}
                        />
                    </Col>
                </Row>
            ): null}
            {signers && signers.length > 0 && !ignoreSigners ? (
                <Row style={{paddingLeft: 25, paddingRight: 15}}>
                    <Col>
                        <KSelect<Signer>
                            label={<Translate value={"pdfSidebar.signer"}/>}
                            id={`signer-${field.id}`} options={signers.map((s, i) => ({
                            value: s,
                            label: s.email,
                            isDisabled: (multipleSelection ? fields?.some((f) => f.type === TemplateFieldType.PHONE) : field.type === TemplateFieldType.PHONE) && !s.phoneNumber,
                        }))} size={KSELECT_SIZE.lg}
                            value={signers.find(s => s.email === field.signerEmail)} required={true}
                            hint={multipleSelection ? <Translate value={`pdfSidebar.signerFieldsInfo`}/> : <Translate value={`pdfSidebar.signerInfo`}/>}
                            hintVariant={KInputHintVariant.DEFAULT}
                            onChange={(val) => {
                                const signer = val as Signer;
                                if (multipleSelection) {
                                    fields?.forEach(f => onChange({...f, signerEmail: signer.email || undefined}));
                                } else {
                                    onChange({...field, signerEmail: signer.email || undefined});
                                }
                                onSetLastSigner(signer.email);
                            }}
                        />
                    </Col>
                </Row>
            ) : null}
            {((field.type === TemplateFieldType.CUSTOM || field.type === TemplateFieldType.CONDITIONAL_SIGNATURE) && !multipleSelection) ? (
                <Row style={{paddingLeft: 25, paddingRight: 15}}>
                    <Col>
                        <KCheck id={`required-${field.id}`} label={<Translate value={"pdfSidebar.fieldRequired"}/>}
                                checked={!!field.required}
                                onChange={(val) => onChange({...field, required: val.target.checked})}
                        />
                    </Col>
                </Row>
            ) : null}
        </Form>
    </>)
};