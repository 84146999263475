import {GetParams, ParamFilter, SortingDirection} from "@kopjra/uikit";
import {Audience, AudienceBaseInfo} from "../types/audiences";
import {List} from "../utils/commons";
import {apiCall, HttpMethod} from "./index";


export function transformToAudienceParams(query: GetParams): GetAudienceParams {
    const result: GetAudienceParams = {
        top: query.top,
        skip: query.skip,
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.DOWN) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const namePf = query.filter.find((pf: ParamFilter) => (pf.name === "name"));
        result.name = namePf ? namePf.value as string : undefined;
    }
    return result;
}

function transformToAudience(obj: any): Audience {
    const audience: Audience = {
        id: obj.id,
        name: obj.name,
        nSigners: obj.nSigners,
        allSignersHavePhone: obj.allSignersHavePhone,
        createdAt: new Date(obj.createdAt),
        updatedAt: new Date(obj.updatedAt),
    };
    return audience;
}

export interface GetAudienceParams extends GetParams {
    name?: string;
}

export async function getAudiences(query: GetParams): Promise<List<Audience>> {
    const list: List<Audience> = {list: [], total: 0};
    const res = await apiCall({urlComponent: "/audiences", query});
    if (res.status < 300) {
        const json = await res.json();
        const audiences = await json.items.map((o: any) => transformToAudience(o));
        list.list = audiences;
        list.total = json.total;
    }
    return list;
}

export async function getAudience(id: string): Promise<Audience | undefined> {
    const res = await apiCall({urlComponent: `/audiences/${id}`});
    if (res.status < 300) {
        const json = await res.json();
        return transformToAudience(json);
    }
    return undefined;
}

export async function deleteAudiences(audienceIds: string[], deleteAll: boolean, queryParams?: GetParams): Promise<boolean> {
    const convertedParams = queryParams?.filter ? transformToAudienceParams(queryParams) : undefined;
    if (convertedParams) {
        delete convertedParams.top;
        delete convertedParams.skip;
    }
    const body = {ids: audienceIds, deleteAll, filter: convertedParams};
    const res = await apiCall({urlComponent: `/audiences`, method: HttpMethod.DELETE, body});
    return res.status < 300;
}

export async function saveAudience(audienceInfo: AudienceBaseInfo): Promise<Audience | undefined> {
    const res = await apiCall({
        urlComponent: `/audiences`,
        method: HttpMethod.POST,
        body: {
            name: audienceInfo.name
        }
    });
    if (res.status < 300) {
        const json = await res.json();
        return transformToAudience(json);
    }
    return undefined;
}
