import * as React from "react";
import {KButton, KBUTTON_VARIANT, KCard, KContainer, KSpace, KSpinner, KSpinnerSize} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";
import {useEffect, useState} from "react";
import Signs from "../containers/Signatures";
import {CampaignDetails} from "./CampaignDetails";
import {Campaign, CampaignStatus} from "../types/campaigns";
import {useParams} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import {CampaignStats, Stats} from "../types/stats";
import {Poller} from "../utils/poller";


export interface StateProps {
    campaign?: Campaign;
    campaignStats?: CampaignStats;
    generalStats?: Stats;
    locale: string;
}

export interface DispatchProps {
    onGetCampaign: (campaignId: string) => Promise<void>;
    onGetCampaignStats: (campaignId: string) => Promise<void>;
    onGetGeneralStats: () => Promise<void>;
    onCloseCampaign: () => void;
    onPlayPauseCampaign: (campaignId: string, newStatus: string) => Promise<void>;
    onUpdateExpirationDate: (campaignId: string, date: Date | undefined) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const CampaignPublishedArea: React.FC<Props> = ({
                                                           campaign,
                                                           generalStats,
                                                           campaignStats,
                                                           onGetCampaign,
                                                           onGetGeneralStats,
                                                           onGetCampaignStats,
                                                           onCloseCampaign,
                                                           onPlayPauseCampaign,
                                                           onUpdateExpirationDate
                                                       }) => {
    // @ts-ignore
    const {campaignId} = useParams();
    const [changingState, setChangingState] = useState<boolean>(false);

    useEffect(() => {
        onGetCampaign(campaignId).catch((e) => console.warn("Get campaign error", e));
        onGetCampaignStats(campaignId).catch((e) => console.warn("Get campaign stats error", e));
        onGetGeneralStats().catch((e) => console.warn("Get general stats error", e));
        const poller = new Poller(() => {
            onGetCampaignStats(campaignId).catch((e) => console.warn("Get campaign stats error", e));
            onGetGeneralStats().catch((e) => console.warn("Get general stats error", e));
        }, 10000);
        poller.start();
        return () => {poller.stop()}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getPlayPauseButton(campaignStatus: string, isAllowed: boolean): JSX.Element {
        if (isAllowed) {
            if (campaignStatus === CampaignStatus.ACTIVE) {
                return <KButton variant={KBUTTON_VARIANT.danger}
                                text={<>{changingState ? <KSpinner/> : <i className="fal fa-pause"/>}&nbsp;<Translate
                                    value={"signs.pause"}/></>} disabled={changingState}
                                onClick={() => {
                                    setChangingState(true);
                                    onPlayPauseCampaign(campaignId, CampaignStatus.PAUSED).then(() => setChangingState(false)).catch(console.log)
                                }
                                }
                />;
            } else if (campaignStatus === CampaignStatus.PAUSED || campaignStatus === CampaignStatus.NEW) {
                return <KButton variant={KBUTTON_VARIANT.success}
                                text={<>{changingState ? <KSpinner/> :
                                    <i className="fal fa-play"/>}&nbsp;<Translate
                                    value={"signs.resume"}/></>} disabled={changingState}
                                onClick={() => {
                                    setChangingState(true);
                                    onPlayPauseCampaign(campaignId, CampaignStatus.ACTIVE).then(() => setChangingState(false)).catch(console.log)
                                    }
                                }
                />;
            } else {
                return <></>;
            }
        } else {
            return <KButton text={<><i className="fal fa-cart-plus"/>&nbsp;<Translate value={"credits.buy"}/></>}
                            onClick={() => new Promise(() => {
                                window.open("https://store.kopjra.com/", "_blank")
                            })}/>
        }
    }

    return (
        <KContainer>
            <KCard header={<Translate value={"campaigns.campaign"}/>}>
                {campaign && campaignStats && generalStats ? (
                    <>
                        <CampaignDetails campaign={campaign} campaignStats={campaignStats} onUpdateExpirationDate={onUpdateExpirationDate}/>
                        <KSpace spaces={2}/>
                        <Signs campaign={campaign}/>
                        <KSpace spaces={2}/>
                        <Row>
                            <Col className={"text-start"} md={6}>
                                <KButton variant={KBUTTON_VARIANT.secondary}
                                         text={<><i className="fal fa-times"/>&nbsp;
                                             <Translate value={"signs.close"}/></>}
                                         onClick={() => onCloseCampaign()}/>
                            </Col>
                            <Col className={"text-end"} md={6}>
                                {getPlayPauseButton(campaign.status, generalStats.isAllowed)}
                            </Col>
                        </Row>
                    </>
                ) : (
                    <KSpinner size={KSpinnerSize.xxl}/>
                )}
            </KCard>
        </KContainer>
    );
};
