import * as React from "react";
import {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {
    ActionType,
    Column,
    confirmBox,
    DataType,
    FreeTextFilter,
    GetParams,
    GlobalActionType,
    KCard,
    KSpinner,
    KSpinnerSize,
    KTableLoader,
    Table,
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {tableGetParams} from "../utils/commons";
import { Template } from "../types/templates";


export interface StateProps {
    templates?: Template[];
    total: number;
    locale: string,
}

export interface DispatchProps {
    onGetTemplates: (query: GetParams) => Promise<void>;
    onOpenTemplate: (templateId: string) => void;
    onCreateTemplate: () => void;
    onDeleteTemplates: (templateIds: string[], deleteAll: boolean, queryParams?: GetParams) => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Templates: React.FC<Props> = ({templates, total, onGetTemplates, onOpenTemplate, onCreateTemplate, onDeleteTemplates}) => {

    const templatesRetriever = async (innerQuery: GetParams, fixedQuery?: GetParams) => {
        await onGetTemplates(fixedQuery ? fixedQuery : innerQuery).catch((e) => console.warn("Get templates error", e));
    };

    useEffect(() => {
        templatesRetriever(tableGetParams("templates/list"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rowAction = async (datum: Template) => {
        onOpenTemplate(datum.id);
    };

    const globalActions: GlobalActionType[] = [
        {
            name: <><div className="fal fa-trash" />{" "}<Translate value="templates.table.delete" /></>,
            handler: async (data: object[], config) => {
                const confirmBoxConf = {
                    noText: I18n.t("generic.no"),
                    yesText: I18n.t("generic.yes"),
                    dark: false,
                    message: I18n.t("templates.table.deleteMessage")
                };
                const deleteTemplates = await confirmBox(confirmBoxConf);
                if (deleteTemplates) {
                    const templateIds = (data as Template[]).map(t => t.id);
                    await onDeleteTemplates(templateIds, config.globalCheckedAll, config.queryParams);
                }
            },
            bulk: true
        },
        {
            name: <><div className="fal fa-plus" />{" "}<Translate value="templates.table.add" /></>,
            handler: async () => onCreateTemplate()
        }
    ];

    const actions: ActionType<Template>[] = [
        {
            name: <><i className="fal fa-folder-open fa-2x" /><Translate value="templates.table.open" /></>,
            handler: rowAction
        },
    ];

    return (
        <KCard header={<Translate value={`templates.title`} />}>
            {templates ? (
                <Row>
                    <Col>
                        <Table
                            checkboxes={true}
                            filterDefinition={[
                                new FreeTextFilter("name", I18n.t("templates.table.labels.name")),
                                new FreeTextFilter("pdf", I18n.t("templates.table.labels.pdf")),
                            ]}
                            globalActions={globalActions}
                            globalWaiter={<KSpinner size={KSpinnerSize.xxl} />}
                            waiter={<KSpinner />}
                            id={`templates/list`}
                            total_count={total}
                            loaderFunc={(q: GetParams) => templatesRetriever(q)}
                            data={templates}
                            hideColumnSelector={true}
                            hideFilters={false}
                            keyField={"id"}
                            hidePager={false}
                            rowAction={rowAction}
                        >
                            <Column colid="name" classes="text-start" name="templates.table.labels.name" type={DataType.GENERIC} sort={"name"} colspan={5}/>
                            <Column colid="pdfFilename" classes="text-end" name="templates.table.labels.pdf" type={DataType.GENERIC} sort={"pdfFilename"} colspan={3}/>
                            <Column colid="createdAt" classes="text-start" name="templates.table.labels.created" type={DataType.DATE} sort={"createdAt"} colspan={2} />
                            <Column colid="actions" classes="text-end" name="" type={DataType.ACTIONS} actions={actions} colspan={2}/>
                        </Table>
                    </Col>
                </Row>
            ) : (
                <KTableLoader/>
            )}
        </KCard>
    );
};
