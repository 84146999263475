import React, {useEffect} from "react";
import {KApp, KErrorPage} from "@kopjra/uikit";
import Toolbar from "../containers/Toolbar";
import {Route, Switch} from "react-router-dom";
import {ProtectedRoute, Routes} from "../utils/router";
import {Translate} from "react-redux-i18n";
import UserProfile from "../containers/UserProfile";
import {useAuth0} from "@auth0/auth0-react";
import {initGetTokenFunction} from "../api";
import {BigWaiter} from "./BigWaiter";
import CampaignsArea from "../containers/CampaignsArea";
import { ManageTemplates } from "./ManageTemplates";
import { ManageAudiences } from "./ManageAudiences";
import CampaignPublishedArea from "../containers/CampaignPublishedArea";
import TemplateArea from "../containers/TemplateArea";
import CampaignCreation from "../containers/CampaignCreation";
import CampaignSign from "../containers/CampaignSign";
import Collaborators from "../containers/Collaborators";
import AcceptCollaborator from "../containers/AcceptCollaborator";
import AudienceArea from "../containers/AudienceArea";
import AudienceCreation from "../containers/AudienceCreation";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const App: React.FC<Props> = () => {
    const {getAccessTokenSilently, isLoading} = useAuth0();
    useEffect(() => {
        initGetTokenFunction(getAccessTokenSilently);
    }, [getAccessTokenSilently]);
    return isLoading ? (
        <BigWaiter/>
    ) : (
        <KApp>
            <Toolbar/>
            <Switch>
                <Route exact={true} path={Routes.SIGN} component={CampaignSign}/>
                <ProtectedRoute exact={true} path={Routes.HOME} component={CampaignsArea}/>
                <ProtectedRoute exact={true} path={Routes.CAMPAIGN} component={CampaignPublishedArea}/>
                <ProtectedRoute exact={true} path={Routes.TEMPLATES} component={ManageTemplates}/>
                <ProtectedRoute exact={true} path={Routes.AUDIENCES} component={ManageAudiences}/>
                <ProtectedRoute exact={true} path={Routes.TEMPLATE} component={TemplateArea}/>
                <ProtectedRoute exact={true} path={Routes.AUDIENCE} component={AudienceArea}/>
                <ProtectedRoute exact={true} path={Routes.NEW_TEMPLATE} component={TemplateArea}/>
                <ProtectedRoute exact={true} path={Routes.CREATE_CAMPAIGN} component={CampaignCreation}/>
                <ProtectedRoute exact={true} path={Routes.CREATE_AUDIENCE} component={AudienceCreation}/>
                <ProtectedRoute exact={true} path={Routes.USER_ACCOUNT} component={UserProfile}/>
                <ProtectedRoute exact={true} path={Routes.COLLABORATORS} component={Collaborators}/>
                <ProtectedRoute exact={true} path={Routes.ACCEPT_COLLABORATOR} component={AcceptCollaborator}/>
                <Route path={Routes.ALL} render={() => <KErrorPage title={<Translate value="error.page"/>} code={"404"} message={<Translate value="error.notFound"/>}/>}/>
            </Switch>
        </KApp>
    );
}
