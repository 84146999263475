import {GetParams} from "@kopjra/uikit";
import {connect} from "react-redux";
import {doResetCampaign} from "../actions/campaigns";
import {doResetSigners} from "../actions/signatures";
import {doDeleteCampaigns, doGetCampaigns} from "../actions/thunks/campaigns";
import {Campaigns, DispatchProps, InnerProps, StateProps} from "../components/Campaigns";
import {RootState, SortingConversion, ThunkDispatch} from "../types";
import {routerTools} from "../utils/router";
import {doResetFilters, doSortColumn} from "@kopjra/uikit/dist/src/lib/kptable/actions/thunks";
import {sortColumn} from "@kopjra/uikit/dist/src/lib/kptable/actions/actions";


function mapStateToProps({ campaigns, i18n }: RootState): StateProps {
    return {
        locale: i18n.locale,
        campaigns: campaigns.list,
        total: campaigns.total
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCampaigns: (query: GetParams) => dispatch(doGetCampaigns(query)),
        onOpenCampaign: (campaignId: string) => {
            dispatch(doResetCampaign());
            dispatch(doResetSigners());
            routerTools.push(`/campaigns/${campaignId}`);
        },
        onDeleteCampaigns: (campaignIds: string[], deleteAll: boolean, queryParams: GetParams) => dispatch(doDeleteCampaigns(campaignIds, deleteAll, queryParams)),
        onResetTable: (id, loaderFunc) => dispatch(doResetFilters(id, loaderFunc)),
        onInitializeSort: async (loader: (q: GetParams) => Promise<void>) => {
            dispatch(sortColumn("campaigns/list", "createdAt", SortingConversion.DESC));
            await dispatch(doSortColumn("campaigns/list", "createdAt", loader))
        },
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Campaigns);
