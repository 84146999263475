import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {Stats} from "../types/stats";
import {
    gotStats,
    resetStats
} from "../actions/stats";


const initialState: Stats = {
    available: -1, extra: 0, subscribed: -1,
    activeCampaigns: -1, totalSigned: -1, totalSigners: -1, isAllowed: false
};

export const statsReducer = createReducer(initialState)
    .handleAction(gotStats, (state, action) => update(state, {
        $set: action.payload
    }))
    .handleAction(resetStats, (state) => update(state, {
        activeCampaigns: {$set: -1},
        totalSigned: {$set: -1},
        totalSigners: {$set: -1},
        available: {$set: -1},
        extra: {$set: 0},
        subscribed: {$set: -1},
    }));
