import * as React from "react";
import {Col, Row} from "react-bootstrap";
import Audiences from "../containers/Audiences";
import {KContainer} from "@kopjra/uikit";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const ManageAudiences: React.FC<Props> = () => {
    return (
        <KContainer>
            <Row>
                <Col><Audiences /></Col>
            </Row>
        </KContainer>
    );
};
