import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { I18n, Translate } from "react-redux-i18n";
import {
    KButton, KBUTTON_TYPE, KBUTTON_VARIANT,
    KCard, KContainer, KForm, KInput, KSpace,
} from "@kopjra/uikit";
import {AudienceBaseInfo} from "../types/audiences";
import {AudienceTableHook, useAudienceTable} from "./AudienceTable";
import {useState} from "react";


export type AudienceBaseInfoProps = {
    audienceInfo?: AudienceBaseInfo;
    onSubmit: (audienceInfo: AudienceBaseInfo) => void;
    onBack: () => void;
};

export const AudienceInfo: React.FC<AudienceBaseInfoProps> = ({ audienceInfo, onSubmit, onBack }) => {
    const audienceTableManager: AudienceTableHook = useAudienceTable({phoneRequired: false});
    const [submitting, setSubmitting] = useState(false);

    return (
        <>
            <KContainer>
                <Row><Col lg={12} md={12}>
                    <KCard header={<Translate value={"audiences.table.add"} />}>
                        <KForm onSubmit={values => {
                            const data = audienceTableManager.getData();
                            if (data) {
                                const audienceInfo: AudienceBaseInfo = {
                                    name: values.name as string,
                                    signers: data
                                };
                                setSubmitting(true);
                                onSubmit(audienceInfo);
                            }
                        }}>
                            <Row>
                                <Col lg={4} md={6} className={"text-start"}>
                                    <KInput label={<Translate value="audiences.table.labels.name" />} defaultValue={audienceInfo?.name} required={true} id={"name"} errorMessage={<Translate value="audiences.info.errors.name" />}
                                             placeholder={I18n.t("audiences.info.placeholders.name")} />
                                </Col>
                                <Col lg={4} md={3}/>
                                <Col lg={4} md={3}/>
                            </Row>
                            <KSpace />
                            {audienceTableManager.audienceTable}
                            <KSpace spaces={2}/>
                            <Row>
                                <Col lg={3} md={4} className={"text-start"}><KButton variant={KBUTTON_VARIANT.secondary} fill={true} disabled={submitting}
                                                                                     text={<><i className="fal fa-times" />&nbsp;<Translate value={`campaignCreation.close`} /></>}
                                                                                     onClick={() => onBack()} />
                                </Col>
                                <Col lg={6} md={4}/>
                                <Col lg={3} md={4} className={"text-end"}><KButton type={KBUTTON_TYPE.submit} fill={true} waiting={submitting}
                                                                                   text={<><i className="fal fa-save"/>&nbsp;<Translate value={`campaignCreation.signers.save`}/></>} /></Col>
                            </Row>
                        </KForm>
                    </KCard>
                </Col></Row>
            </KContainer>
        </>
    );
}
