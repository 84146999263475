import {createReducer} from "typesafe-actions";
import {produce} from "immer";
import {CampaignCreation} from "../types/campaignCreation";
import {
    gotAvailableAudiences,
    gotAvailableTemplates,
    gotCurFile,
    resetNewCampaign,
    setAudienceData,
    setCampaignCreated,
    setCampaignInfo,
    setDocumentData, setSignersOrder
} from "../actions/campaignCreation";


const initialState: CampaignCreation = {
};

export const campaignCreationReducer = createReducer(initialState)
    .handleAction(setCampaignInfo, (state, action) => produce(state, (draft) => {
        draft.campaignInfo = action.payload;
    }))
    .handleAction(setDocumentData, (state, action) => produce(state, (draft) => {
        draft.documentData = action.payload;
    }))
    .handleAction(setAudienceData, (state, action) => produce(state, (draft) => {
        draft.audienceData = action.payload;
    }))
    .handleAction(gotAvailableTemplates, (state, action) => produce(state, (draft) => {
        draft.documentData = { availableTemplates: action.payload, saveAsNew: false, newChoose: false };
    }))
    .handleAction(gotAvailableAudiences, (state, action) => produce(state, (draft) => {
       draft.audienceData = { availableAudiences: action.payload, newChoose: false };
    }))
    .handleAction(resetNewCampaign, (state) => produce(state, (draft) => {
        draft.campaignInfo = undefined;
        draft.documentData = undefined;
        draft.audienceData = undefined;
        draft.campaignCreated = undefined;
    }))
    .handleAction(gotCurFile, (state, action) => produce(state, (draft) => {
        if (draft.documentData) {
            draft.documentData.file = action.payload;
        } else {
            draft.documentData = { availableTemplates: [], file: action.payload, newChoose: false, saveAsNew: false };
        }
    }))
    .handleAction(setCampaignCreated, (state, action) => produce(state, (draft) => {
       draft.campaignCreated = action.payload;
    }))
    .handleAction(setSignersOrder, (state, action) => produce(state, (draft) => {
        if (draft.audienceData) {
            for (const signerOrder of action.payload) {
                const signer = draft.audienceData.signers?.find(s => s.email === signerOrder.email);
                if (signer) {
                    signer.signerIndex = signerOrder.idx;
                }
            }
        }
    }))
;
