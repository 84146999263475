import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {
    cancelTemplate,
    gotTemplates
} from "../actions/templates";
import { Templates } from "../types/templates";


const initialState: Templates = {
    total: 0
};

export const templatesReducer = createReducer(initialState)
    .handleAction(gotTemplates, (state, action) => update(state, {
        list: {$set: action.payload.list},
        total: {$set: action.payload.total}
    }))
    .handleAction(cancelTemplate, (state, action) => {
        if (state.list) {
            const templateId = action.payload;
            const newState = state.list.filter( template => template.id !== templateId);
            return update(state, {
                list: {$set: newState},
                total: {$set: (state.total-=1)}
            })
        } else {
            return state;
        }
    });
