import { connect } from "react-redux";
import {doGetSign, doPatchSign, doGetPublicCampaign, doCreateSign} from "../actions/thunks/campaignSign";
import { DispatchProps, StateProps, InnerProps, CampaignSign } from "../components/CampaignSign";
import { RootState, ThunkDispatch } from "../types";
import { Signer } from "../types/campaigns";


function mapStateToProps({ campaignSign }: RootState): StateProps {
    return {
        campaign: campaignSign.campaign,
        sign: campaignSign.sign
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCampaign: (campaignId: string, campaignCode: string) => dispatch(doGetPublicCampaign(campaignId, campaignCode)),
        onGetSign: (campaignId: string, campaignCode: string, signId: string, signCode: string) => dispatch(doGetSign(campaignId, campaignCode, signId, signCode)),
        onPatchSign: (campaignId: string, campaignCode: string, signId: string, signCode: string, signer: Signer) => dispatch(doPatchSign(campaignId, campaignCode, signId, signCode, signer)),
        onCreateSign: (campaignId: string, campaignCode: string, signer: Signer) => dispatch(doCreateSign(campaignId, campaignCode, signer))
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(CampaignSign);
