import { createReducer } from "typesafe-actions";
import update from "immutability-helper";
import {AudienceCreation} from "../types/audienceCreation";
import {resetNewAudience, setAudienceInfo} from "../actions/audienceCreation";


const initialState: AudienceCreation = {
};

export const audienceCreationReducer = createReducer(initialState)
    .handleAction(setAudienceInfo, (state, action) => update(state, { audienceInfo: { $set: action.payload } }))
    .handleAction(resetNewAudience, (state) => update(state, {
        audienceInfo: { $set: undefined }
     }));
