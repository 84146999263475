import { ThunkResult } from "../../types";
import { Dispatch } from "redux";
import { RootAction } from "../index";
import {cancelAudience, gotAudience, gotAudiences} from "../audiences";
import { GetParams, setAlert } from "@kopjra/uikit";
import {getAudiences, transformToAudienceParams, deleteAudiences, getAudience} from "../../api/audiences";
import { I18n } from "react-redux-i18n";


export const doGetAudience = (audienceId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const audience = await getAudience(audienceId);
    if (audience) {
        dispatch(gotAudience(audience));
    }
};
export const doGetAudiences = (query: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    dispatch(gotAudiences(await getAudiences(transformToAudienceParams(query))));
};
export const doDeleteAudiences = (audienceIds: string[], deleteAll: boolean, queryParams?: GetParams): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => { 
    const deleted = await deleteAudiences(audienceIds, deleteAll, queryParams);
    if (deleted) {
        dispatch(setAlert(I18n.t("alerts.infos.deleteAudiences"), "info"));
        for (const id of audienceIds) {
            dispatch(cancelAudience(id));
        }
    } else {
        dispatch(setAlert(I18n.t("alerts.errors.deleteAudiences"), "error"));
    }
}
