import * as React from "react";
import { Col, Row } from "react-bootstrap";
import {Localize, Translate} from "react-redux-i18n";
import {KLabel, KSpace} from "@kopjra/uikit";
import {Audience} from "../types/audiences";


export type AudienceDetailsProps = {
    audience: Audience;
};

export const AudienceDetails: React.FC<AudienceDetailsProps> = ({ audience }) => {

    return <>
        <Row>
            <Col className="text-center">
                <span style={{ fontSize: 22, fontWeight: "bold" }}>{`"${audience.name}"`}</span>
            </Col>
        </Row>
        <KSpace spaces={2} />
        <Row>
            <Col className="text-start" md={12}>
                <Row>
                    <Col md={2}><KLabel text={<Translate value="audiences.table.labels.nSigners" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}>{audience.nSigners}</span></Col>
                </Row>
                <Row>
                    <Col md={2}><KLabel text={<Translate value="audiences.table.labels.createdAt" />} /></Col>
                    <Col><span style={{ fontSize: 12 }}><Localize value={audience.createdAt} dateFormat={"kptable.date.short"}/></span></Col>
                </Row>
            </Col>
        </Row>
    </>
};
