import { connect } from "react-redux";
import {
    doGetCampaign,
    doGetCampaignStats,
    doGetGeneralStats,
    doPlayPauseCampaign,
    doUpdateExpirationDate
} from "../actions/thunks/campaigns";
import { DispatchProps, StateProps, InnerProps, CampaignPublishedArea } from "../components/CampaignPublishedArea";
import { RootState, ThunkDispatch } from "../types";
import { routerTools, Routes } from "../utils/router";


function mapStateToProps({ campaign, i18n }: RootState): StateProps {
    return {
        campaign: campaign.campaign,
        campaignStats: campaign.campaignStats,
        generalStats: campaign.generalStats,
        locale: i18n.locale
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCampaign: (campaignId: string) => dispatch(doGetCampaign(campaignId)),
        onGetCampaignStats: (campaignId: string) => dispatch(doGetCampaignStats(campaignId)),
        onGetGeneralStats: () => dispatch(doGetGeneralStats()),
        onCloseCampaign: () => {routerTools.push(Routes.HOME)},
        onPlayPauseCampaign: (campaignId: string, newStatus: string) => dispatch(doPlayPauseCampaign(campaignId, newStatus)),
        onUpdateExpirationDate: (campaignId: string, date: Date | undefined) => dispatch(doUpdateExpirationDate(campaignId, date))
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(CampaignPublishedArea);
