import {GetParams, setAlert} from "@kopjra/uikit";
import {ThunkResult} from "../../types";
import {acceptInvite, addCollaborators, getCollaborators, removeCollaborator, transformToGetCollaboratorParams} from "../../api/collaborators";
import {gotCollaborators} from "../collaborators";
import {I18n} from "react-redux-i18n";

export const doGetCollaborators = (query: GetParams): ThunkResult<Promise<void>> => async (dispatch) => {
    dispatch(gotCollaborators(await getCollaborators(transformToGetCollaboratorParams(query))));
};

export const doInviteCollaborators = (emails: string[]): ThunkResult<Promise<boolean>> => async (dispatch) => {
    try {
        await addCollaborators(emails);
        dispatch(setAlert(I18n.t("alerts.infos.addedCollaborator"), "info"));
        return true;
    } catch (err) {
        console.log(err);
        dispatch(setAlert(I18n.t("alerts.errors.addingCollaborator"), "error"));
        return false;
    }
};

export const doRemoveCollaborator = (id: string): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
        await removeCollaborator(id);
        dispatch(setAlert(I18n.t("alerts.infos.removedCollaborator"), "info"));
    } catch (err) {
        console.log(err);
        dispatch(setAlert(I18n.t("alerts.errors.removingCollaborator"), "error"));
    }
}

export const doAcceptInvite = (inviteId: string, code: string): ThunkResult<Promise<void>> => async () => {
    await acceptInvite(inviteId, code);
}