import {Core, WebViewerInstance} from "@pdftron/pdfjs-express";
import {COLORS, ICONS, translatedType} from "./commons";
import {guid} from "./guid";
import {store} from "../index";
import {TemplateField, TemplateFieldType} from "../types/pdfdoped";

let TemplateFieldAnnotation: Core.Annotations.CustomAnnotation;

export function getTemplateFieldAnnotation(): Core.Annotations.CustomAnnotation {
    return TemplateFieldAnnotation;
}

export function createTemplateFieldAnnotation(templateField?: TemplateField): Core.Annotations.CustomAnnotation | undefined {
    if (TemplateFieldAnnotation) {
        return new (TemplateFieldAnnotation as any)(templateField);
    }
    return undefined;
}

export function createTemplateFieldAnnotationClass(instance: WebViewerInstance) {
    const {CustomAnnotation} = instance.Core.Annotations;
    instance.UI.Fonts.setAnnotationFonts(["Lato", "Sourcesanspro", "Font Awesome 6 Pro"])
    class _TemplateFieldAnnotation extends CustomAnnotation {
        private email?: string;
        private fieldType: TemplateFieldType;
        private name?: string;
        private description?: string;
        private required?: boolean;

        constructor(templateField?: TemplateField) {
            super("templateField");
            this.Subject = "TemplateField";
            if (templateField) {
                this.Id = templateField.id;
                this.email = templateField.signerEmail;
                this.fieldType = templateField.type;
                this.name = templateField.name;
                this.X = templateField.rect.x;
                this.Y = templateField.rect.y;
                this.Width = templateField.rect.width;
                this.Height = templateField.rect.height;
                this.PageNumber = templateField.page;
                this.description = templateField.description;
                this.required = templateField.required;
            } else {
                this.Id = guid();
                this.fieldType = TemplateFieldType.SIGNATURE;
                this.email = store.getState().pdfdoped.lastSigner;
            }
            this.saveCustomData();
        }

        // Verify if the annotation is consistent with the stored data. This is used to avoid the creation of fields
        // in the global state with automatically created ids.
        public isConsistent() {
            const storedTemplateField = this.getCustomData("data");
            if (!storedTemplateField) {
                return false;
            }
            const {id} = JSON.parse(storedTemplateField);
            return this.Id === id;
        }

        public update(templateField: TemplateField): boolean {
            if (this.Id !== templateField.id) {
                return false;
            }
            let updated = false;
            if (this.email !== templateField.signerEmail) {
                this.email = templateField.signerEmail;
                updated = true;
            }
            if (this.fieldType !== templateField.type) {
                this.fieldType = templateField.type;
                updated = true;
            }
            if (this.name !== templateField.name) {
                this.name = templateField.name;
                updated = true;
            }
            if (this.description !== templateField.description) {
                this.description = templateField.description;
                updated = true;
            }
            if (this.required !== templateField.required) {
                this.required = templateField.required;
                updated = true;
            }
            this.saveCustomData();
            return updated;
        }

        private modifyTextToFit(ctx: CanvasRenderingContext2D, text: string, maxWidth: number): [string, number] {
            let str = text;
            let strProps = ctx.measureText(str);
            let strWidth = strProps.width;
            let diff = strWidth - maxWidth;
            while (diff > 0 && str.length > 0) {
                str = str.substring(0, str.length - 1);
                strProps = ctx.measureText(str + "...");
                strWidth = strProps.width;
                diff = strWidth - maxWidth;
            }
            return [str, strProps.width];
        }

        draw(ctx: CanvasRenderingContext2D, pageMatrix: any) {
            ctx.getContextAttributes().willReadFrequently = true;
            this.setStyles(ctx, pageMatrix);
            const {X, Y, Width, Height} = this;
            const stripeHeight = 10;
            if (this.email) {
                ctx.font = `6px Sourcesanspro, sans-serif`;
                (ctx as any).letterSpacing = "0px";
                // Measuring the text to see if it fits in the stripe
                const [strEmail, strWidth] = this.modifyTextToFit(ctx, this.email, Width - 6);
                let emailStripeWidth = strWidth + 6;
                //Drawing the email stripe
                ctx.fillStyle = COLORS.get(this.fieldType) || COLORS.get("DEFAULT") || "";
                ctx.strokeStyle = COLORS.get(this.fieldType) || COLORS.get("DEFAULT") || "";
                ctx.lineWidth = 2;
                ctx.fillRect(X - 1, Y - stripeHeight, Math.min(emailStripeWidth, Width), stripeHeight);
                // Drawing the email
                ctx.fillStyle = "#FFF";
                if (strEmail.length > 0) {
                    ctx.fillText(strEmail === this.email ? strEmail : strEmail + "...", X + 2, Y - 3);
                }
            }
            // Drawing the field rectangle
            ctx.fillStyle = COLORS.get(this.fieldType) || COLORS.get("DEFAULT") || "";
            ctx.strokeStyle = COLORS.get(this.fieldType) || COLORS.get("DEFAULT") || "";
            ctx.lineWidth = 2;
            ctx.strokeRect(X, Y, Width, Height);
            ctx.fillStyle = "#FFFFFF05";
            ctx.fillRect(X, Y, Width, Height);
            ctx.font = "bold 9px Lato";
            (ctx as any).letterSpacing = "1.2px";
            // Measuring the text to see if it fits in the field rectangle
            const fieldText = (this.fieldType === TemplateFieldType.CUSTOM && this.name) ?
                this.name.toUpperCase() :
                translatedType(this.fieldType).toUpperCase();
            const [strField] = this.modifyTextToFit(ctx, fieldText, Width - 30);
            ctx.fillStyle = COLORS.get(this.fieldType) || COLORS.get("DEFAULT") || "";
            if (strField && Height >= 25) {
                ctx.fillText(strField === fieldText ? strField : strField + "...", X + 30, Y + 17);
            }
            // Drawing the icon
            if (Width >= 25 && Height >= 25) {
                ctx.font = "13px 'Font Awesome 6 Pro'";
                ctx.fillText(ICONS[this.fieldType][0], X + 7, Y + 19);
            }
        }

        private saveCustomData() {
            this.setCustomData("data", JSON.stringify({
                email: this.email, fieldType: this.fieldType, name: this.name,
                description: this.description, required: this.required,
                id: this.Id,
            }));
        }

        serialize(element: Element, pageMatrix: any) {
            // save our custom property into the custom data
            this.saveCustomData();
            // perform regular serialization on other properties
            return super.serialize(element, pageMatrix);
        }

        deserialize(element: Element, pageMatrix: any) {
            // perform regular deserialization for other properties
            super.deserialize(element, pageMatrix);
            // read our custom property out from custom data
            const storedTemplateField = this.getCustomData("data");
            // set the property after initializing the data as points
            const {email, fieldType, name, description, required, id} = JSON.parse(storedTemplateField);
            this.email = email;
            this.fieldType = fieldType;
            this.name = name;
            this.description = description;
            this.required = required;
            this.Id = id;
        }
    }
    _TemplateFieldAnnotation.prototype.elementName = "templateField";
    _TemplateFieldAnnotation.SerializationType = CustomAnnotation.SerializationTypes.CUSTOM;
    TemplateFieldAnnotation = _TemplateFieldAnnotation as any;

    // register the annotation type so that it can be saved to XFDF files
    instance.Core.annotationManager.registerAnnotationType(
        _TemplateFieldAnnotation.prototype.elementName,
        _TemplateFieldAnnotation,
    );

    return _TemplateFieldAnnotation;
}

