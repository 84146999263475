import {connect} from "react-redux";
import {DispatchProps, InnerProps, PDFDopedViewer, StateProps} from "../components/PDFDopedViewer";
import {RootState, ThunkDispatch} from "../types";
import {deleteFields, setLastSigner, setPageMatrices, updateFields} from "../actions/pdfdoped";
import {setSignersOrder} from "../actions/campaignCreation";

function mapStateToProps({pdfdoped, campaignCreation}: RootState, ): StateProps {
    return {
        lastSigner: pdfdoped.lastSigner,
        file: pdfdoped.file,
        fields: pdfdoped.fields,
        signers: campaignCreation.audienceData?.signers,
        campaignType: campaignCreation.campaignInfo?.type,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onSetLastSigner: (signer) => dispatch(setLastSigner(signer)),
        onSetPageMatrices: (pageMatrices) => dispatch(setPageMatrices(pageMatrices)),
        onDeleteFields: (ids) => dispatch(deleteFields(ids)),
        onUpdateFields: (fields) => dispatch(updateFields(fields)),
        onSetSignersOrder: (signersOrder) => dispatch(setSignersOrder(signersOrder)),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(PDFDopedViewer);