export enum SignStatus {
  "NEW" = "NEW",
  "SENT" = "SENT",
  "NOT_SENT" = "NOT_SENT",
  "SIGNED" = "SIGNED",
}

export type SignCode = {
  code: string;
  signatureId?: string;
  signatureCode?: string;
}

export interface Sign {
  id: string;
  status: string;
  campaignId: string;
  email: string;
  fullName: string;
  phoneNumber: string;
  code: string;
  webtrustId?: number;
  fUrl?: string;
  created: string;
  updated: string;
  emailLastOpenedAt?: string;
  signerIndex?: number;
}

export interface Signatures {
  list?: Sign[],
  total: number;
}
