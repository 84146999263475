import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {
    gotAudience,
    resetCurAudience
} from "../actions/audiences";
import { AudienceArea } from "../types/audienceArea";

const initialState: AudienceArea = {
};

export const audienceAreaReducer = createReducer(initialState)
    .handleAction(gotAudience, (state, action) => update(state, {
        audience: {$set: action.payload},
    }))
    .handleAction(resetCurAudience, (state) => update(state, {
        audience: { $set: undefined }
     }));
