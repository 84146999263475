import { connect } from "react-redux";
import {
    DispatchProps,
    StateProps,
    InnerProps,
    AudienceCreation
} from "../components/AudienceCreation";
import { RootState, ThunkDispatch } from "../types";
import {AudienceBaseInfo} from "../types/audiences";
import {setAudienceInfo} from "../actions/audienceCreation";
import {doCreateAudience} from "../actions/thunks/audienceCreation";
import {routerTools, Routes} from "../utils/router";


function mapStateToProps({audienceCreation}: RootState): StateProps {
    return {
        audienceInfo: audienceCreation.audienceInfo,
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onSetAudienceInfo: (audienceInfo: AudienceBaseInfo) => dispatch(setAudienceInfo(audienceInfo)),
        onCreateAudience: async () => {
            await dispatch(doCreateAudience())
            routerTools.push(Routes.AUDIENCES);
        },
        onBack: () => {routerTools.push(Routes.AUDIENCES)}
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(AudienceCreation);
