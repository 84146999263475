import { GetParams } from "@kopjra/uikit";
import { connect } from "react-redux";
import { resetCurTemplate } from "../actions/templates";
import { doGetTemplates, doDeleteTemplates } from "../actions/thunks/templates";
import { DispatchProps, StateProps, InnerProps, Templates } from "../components/Templates";
import { RootState, ThunkDispatch } from "../types";
import { routerTools, Routes } from "../utils/router";


function mapStateToProps({ templates, i18n }: RootState): StateProps {
    return {
        locale: i18n.locale,
        templates: templates.list,
        total: templates.total
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetTemplates: (query: GetParams) => dispatch(doGetTemplates(query)),
        onOpenTemplate: (templateId: string) => {
            dispatch(resetCurTemplate());
            routerTools.push(`/templates/${templateId}/edit`);
        },
        onCreateTemplate: () => {
            dispatch(resetCurTemplate());
            routerTools.push(Routes.NEW_TEMPLATE);
        },
        onDeleteTemplates: (templateIds: string[], deleteAll: boolean, queryParams?: GetParams) => dispatch(doDeleteTemplates(templateIds, deleteAll, queryParams))
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Templates);
