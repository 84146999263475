import { connect } from "react-redux";
import { resetNewCampaign } from "../actions/campaignCreation";
import { doGetStats } from "../actions/thunks/stats";
import { DispatchProps, StateProps, InnerProps, CampaignsArea } from "../components/CampaignsArea";
import { RootState, ThunkDispatch } from "../types";
import { routerTools } from "../utils/router";


function mapStateToProps({ stats }: RootState): StateProps {
    return {
        stats: stats
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetStats: () => dispatch(doGetStats()),
        onAddCampaign: () => {
            dispatch(resetNewCampaign());
            routerTools.push("/createCampaign");
        }
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(CampaignsArea);
