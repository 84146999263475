import { connect } from "react-redux";
import { doGetAudience } from "../actions/thunks/audiences";
import { DispatchProps, StateProps, InnerProps, AudienceArea } from "../components/AudienceArea";
import { RootState, ThunkDispatch } from "../types";


function mapStateToProps({ audience, i18n }: RootState): StateProps {
    return {
        audience: audience.audience
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetAudience: (audienceId: string) => dispatch(doGetAudience(audienceId))
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(AudienceArea);
