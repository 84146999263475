import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {
    cancelAudience,
    gotAudiences
} from "../actions/audiences";
import { Audiences } from "../types/audiences";


const initialState: Audiences = {
    total: 0
};

export const audiencesReducer = createReducer(initialState)
    .handleAction(gotAudiences, (state, action) => update(state, {
        list: {$set: action.payload.list},
        total: {$set: action.payload.total}
    }))
    .handleAction(cancelAudience, (state, action) => {
        if (state.list) {
            const audienceId = action.payload;
            const newState = state.list.filter( audience => audience.id !== audienceId);
            return update(state, {
                list: {$set: newState},
                total: {$set: (state.total-=1)}
            })
        } else {
            return state;
        }
    });
