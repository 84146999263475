import { GetParams } from "@kopjra/uikit";
import { connect } from "react-redux";
import { DispatchProps, StateProps, InnerProps, Signers } from "../components/Signers";
import { RootState, ThunkDispatch } from "../types";
import {Signer} from "../types/signers";
import {doAddSigners, doDeleteSigners, doGetSigners} from "../actions/thunks/signers";


function mapStateToProps({ signers, i18n }: RootState): StateProps {
    return {
        signers: signers.list,
        total: signers.total,
        locale: i18n.locale,
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetSigners: (audienceId: string, query: GetParams) => dispatch(doGetSigners(audienceId, query)),
        onAddSigners: (audienceId: string, signers: Signer[], query: GetParams) => dispatch(doAddSigners(audienceId, signers, query)),
        onDeleteSigners: (audienceId: string, signerIds: string[], deleteAll: boolean, queryParams?: GetParams) => dispatch(doDeleteSigners(audienceId, signerIds, deleteAll, queryParams)),
    }
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Signers);
