import { createAction } from "typesafe-actions";
import {AudienceData, DocumentData} from "../types/campaignCreation";
import {Campaign, CampaignBaseInfo} from "../types/campaigns";
import { Template } from "../types/templates";
import {Audience} from "../types/audiences";


export const gotCurFile = createAction("templates/GOT_TEMPLATE_FILE", (file: File) => file)();
export const gotAvailableTemplates = createAction("templates/GOT_AVAILABLE_TEMPLATES", (availabletemplates: Template[]) => availabletemplates)();
export const gotAvailableAudiences = createAction("audiences/GOT_AVAILABLE_AUDIENCES", (availableAudiences: Audience[]) => availableAudiences)();
export const setCampaignInfo = createAction("campaigns/SET_CAMPAIGN_INFO", (campaignInfo: CampaignBaseInfo) => campaignInfo)();
export const setDocumentData = createAction("templates/SET_DOCUMENT_DATA", (documentData: DocumentData) => documentData)();
export const setAudienceData = createAction("audiences/SET_AUDIENCE_DATA", (audienceData: AudienceData) => audienceData)();
export const resetNewCampaign = createAction("campaigns/RESET_NEW_CAMPAIGN", () => {})();
export const setCampaignCreated = createAction("campaigns/SET_CAMPAIGN_CREATED", (campaignCreated: Campaign) => campaignCreated)();
export const setSignersOrder = createAction("campaigns/SET_SIGNERS_ORDER", (signersOrder: {email: string, idx: number}[]) => signersOrder)();