import {apiCall, HttpMethod} from "./index";
import {CHG_ENDPOINT} from "../utils/constants";
import {UserProfileData} from "../types";

export async function resendEmail() {
    await apiCall({
        urlComponent: `${CHG_ENDPOINT}/users/current:sendVerificationEmail`,
        method: HttpMethod.POST,
    });
}

export async function resetPassword() {
    await apiCall({
        urlComponent: `${CHG_ENDPOINT}/users/current:resetPassword`,
        method: HttpMethod.POST,
    });
}

export async function updateUserProfile(up: UserProfileData) {
    await apiCall({
        urlComponent: `${CHG_ENDPOINT}/users/current`,
        method: HttpMethod.PUT,
        body: up,
    });
}