export class Poller {
    private readonly fn: () => (void | Promise<void>);
    private readonly interval: number;
    private currentTimer?: NodeJS.Timeout;
    private started: boolean;
    private label: string | undefined;

    constructor(fn: () => void | Promise<void>, interval: number, label?: string) {
        this.fn = fn;
        this.interval = interval;
        this.started = false;
        this.label = label;
    }

    private poll() {
        if (this.started) {
            // console.info(`Browser polling (${this.label ? this.label : "generic"})...`);
            this.currentTimer = setTimeout(async () => {
                try {
                    await this.fn();
                } catch(error) {
                    console.error("Polling error", {error});
                }
                this.poll();
            }, this.interval);
        }
    }

    public start() {
        this.started = true;
        this.poll();
    }

    public stop() {
        this.started = false;
        if (this.currentTimer) {
            clearTimeout(this.currentTimer);
        }
    }
}
