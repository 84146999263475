import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {
    cancelCampaign,
    gotCampaigns
} from "../actions/campaigns";
import { Campaigns } from "../types/campaigns";


const initialState: Campaigns = {
    total: 0
};

export const campaignsReducer = createReducer(initialState)
    .handleAction(gotCampaigns, (state, action) => update(state, {
        list: {$set: action.payload.list},
        total: {$set: action.payload.total}
    }))
    .handleAction(cancelCampaign, (state, action) => {
        if (state.list) {
            const campaignId = action.payload;
            const newState = state.list.filter( campaign => campaign.id !== campaignId);
            return update(state, {
                list: {$set: newState},
                total: {$set: (state.total-=1)}
            })
        } else {
            return state;
        }
    });
