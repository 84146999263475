import {ThunkResult} from "../../types";
import {Dispatch} from "redux";
import {RootAction} from "../index";
import {gotCurFile, gotAvailableTemplates, setCampaignCreated, gotAvailableAudiences} from "../campaignCreation";
import { getTemplateFile, getTemplates } from "../../api/templates";
import { createCampaign } from "../../api/campaigns";
import {routerTools} from "../../utils/router";
import { setAlert } from "@kopjra/uikit";
import { I18n } from "react-redux-i18n";
import {CampaignType} from "../../types/campaigns";
import {getAudiences} from "../../api/audiences";

export const doGetCurFile = (templateId: string): ThunkResult<Promise<File | undefined>> => async (dispatch: Dispatch<RootAction>) => {
    const templateFile = await getTemplateFile(templateId);
    if (templateFile) {
        dispatch(gotCurFile(templateFile));
        return templateFile;
    }
}

export const doGetAvailableTemplates = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const templates = await getTemplates({top: 100});
    if (templates) {
        dispatch(gotAvailableTemplates(templates.list));
    }
}

export const doGetAvailableAudiences = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const audiences = await getAudiences({top: 100});
    if (audiences) {
        dispatch(gotAvailableAudiences(audiences.list));
    }
}

export const doCreateCampaign = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>, getState) => {
    const campaignCreationData = getState().campaignCreation;
    const campaign = await createCampaign(campaignCreationData);
    if (!campaign) {
        routerTools.push(`/`);
        dispatch(setAlert(I18n.t("alerts.errors.newCampaign"), "error"));
    } else {
        if (campaign.type === CampaignType.OPEN) {
            dispatch(setCampaignCreated(campaign));
        }
    }
}
